<template lang="pug">
  app-dialog( v-model="$_show" :header="$t('section.classification.attribute.addModelAttribute')" :confirm-text="$t('button.add')"
              @confirm="createAttributeModel" :confirmDisabled="!attributeModelName.trim()" width="700")
    app-section
      div.note.mb-2 {{ $t('section.classification.attribute.enterAttributeName') }}
      app-text-field( :value="attributeModelName"
                      @input="handleInput($event)"
                      :label="$t('section.classification.attribute.nameModelAttribute')"
                      outlined )

      div.note.mb-2 {{ $t('section.elementTree.selectingGroupAttr') }}
      v-form
        .mh350.edge
          app-treeview-with-search.__project_view_tree.base-scroll-body(
            v-if="!isLoading"
            ref="attributeTree"
            dense
            light
            hoverable
            open-on-click
            :itemSearchFor="(item) => item.title"
            :itemHighlighted="(item) => item.title"
            item-key="uuid"
            :items="conditionTreeCache.tree.items"
            :search="attributeModelName"
            return-object )
            template(v-slot:label="{ item, highlighted }")
              div.text-truncate( @click="selectedAttribute(item)" v-html="highlighted")

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable.vue'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'
import { projectService } from '@/_services'
import _ from "lodash";

export default {
  name: 'add-attribute-model-dialog',

  mixins: [DialogToggable],

  data(){
    return{
      attributeModelName: '',
      conditionTreeCache: new ConditionTreeCache(),
      isLoading: true,
      expand: false,
      handleInput: _.debounce((val) => {
        this.attributeModelName = val 
      }, 400),
    }
  },

  watch: {
    $_show(){
      this.isLoading = true
      this.attributeModelName = this.copyMatchingAttributes || ''
      projectService.loadParamForCondition(this.projectUuid, 'ATTRIBUTE', '').then((data)=> {
        this.conditionTreeCache.setItems(data)
      }).finally(()=>{
        this.isLoading = false
      })
    },

    attributeModelName(val) {
      this.expand = !!val
      ; this.$refs.attributeTree.updateAll(!!val)
    }
  },

  computed: {
    ...mapState('projectDict', ['copyMatchingAttributes']),
    ...mapGetters('project', ['projectUuid']),

  },  

  methods: {
    createAttributeModel(){
      this.$emit('createAttributeModel', this.attributeModelName)
    },

    selectedAttribute(attribute){
      if(attribute.children.length == 0){
        this.attributeModelName = attribute.parent.name + '/' + attribute.name
      }
    },
    
  },

}
</script>

<style lang="scss" scoped>
.note {
  font: normal 11px/12px $roboto;
  color: grey;
}

._tree-item {
  font: normal 12px/16px "Roboto", sans-serif;
  color: black;
  transition: .1s;
  white-space: nowrap;

  &:hover {
    background: #f0f0f0;
  }
}

.item__toggle {
  font-size: 16px;

  &--open {
    transform: none;
  }

  &--close {
    transform: rotate(-90deg);
  }
}

.item-level {
  width: 8px
}

.edge{
  border: solid 1px #9e9e9e;
  border-radius: 2px;
}

.mh350{
  height: calc(75vh - 400px)
}

.text-truncate{
  white-space: wrap !important;
}
</style>