<template lang="pug">
.d-flex.align-center.wrap
  v-btn(icon @click='page=1')
    v-icon mdi-page-first
  v-btn(icon @click='page > 1 && page--')
    v-icon mdi-chevron-left
  v-text-field.centered-input( style="max-width: 50px;" type="number" @keydown.enter="searchPage" v-model.number="pages")
  v-btn(icon @click='page < totalPages && page++')
    v-icon mdi-chevron-right
  v-btn(icon @click='page = totalPages')
    v-icon mdi-page-last
  .d-flex.align-center(style="height: 20px")
    div {{ page }} из {{ totalPages }}
</template>

<script>
export default {
  name: 'Paginator',

  props: {
    value: Number,
    totalPages: Number,
  },

  data() {
    return {
      pages: null,
    };
  },

  methods: {
    searchPage(){
       if (this.pages % 1 || this.pages > this.totalPages || this.pages <= 0) {
        return;
      }

      this.page = this.pages;
      this.pages = null;
    },
  },

  computed: {
    page: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit('input', val);
      },
    },
  }
}
</script>

<style scoped>
.wrap{
  flex-wrap: wrap;
  justify-content: center;
}
.centered-input >>> input {
  text-align: center
}
.centered-input >>> input::-webkit-outer-spin-button,
.centered-input >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>