<template lang="pug">
app-panel.ml-2.px-1.py-2.sizeW
    app-panel-header.ml-1( :title="`${$t('section.structure.model.header')}: ${$t(selectedModel.title)}`" hideClose)
    app-floating-panels-section( style="height: calc(100% - 29px) !important; overflow-y: hidden; overflow-x: hidden;" )
      .base-scroll-body( style="overflow-x: hidden" )
        app-floating-panel( :title="$t('section.structure.model.form.file')" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('PROJECT_MODEL_FILE')")
          template( v-slot:headerButton )
            app-toolbar( horizontal gap="0px" )
              app-toolbar-button( v-if="getRevision" header size="small" icon="$viewer-fit-icon" @click.stop="intimacy" :title="$t('viewer.tools.zoomModel')" )
              app-toolbar-button( v-if="getRevision && hasModelCompareRevision && meOfSelectedModel" header size="small" icon="$compare-icon" @click.stop="compareRevisionsIFC" :title="$t('section.structure.model.compareRevisions')" )
              app-toolbar-button( v-if="getRevision && hasModelDownloadRevision && meOfSelectedModel" header size="small" icon="$download-icon" @click.stop="downloadRevis" :title="$t('section.structure.model.downloadRevision')" )
              app-toolbar-button( v-if="selectedModel.pointCloud" header size="small" icon="$download-icon" @click.stop="openDownloadLas" :title="$t('section.structure.model.downloadLasRevision')" )
              //- app-toolbar-button( v-if="getRevision && showDownloadIFCBimitAttr" header size="small" icon="$download-icon" @click.stop="downloadRevisionIFCWA" :title="$t('section.structure.model.downloadRevision')+' (с BIMIT атрибутами)'" )
              app-toolbar-button( v-if="hasModelLoadRevision && meOfSelectedModel" header size="small" icon="$add-box-icon" @click.stop="showVarietyAddRevision" test-id="structure-add-revision" :title="$t('section.structure.model.addRevision')" )
          app-section.d-flex.base-scroll-body.flex-column( style="overflow: auto; overflow-x: hidden; padding: 0; height:100%;" )

            .d-flex.flex-column( v-if="selectedModel.pointCloud" )
              app-text.mx-2
                .mt-2 {{ nameLasFile }}
                .mt-2 {{ sizeLasFile }}

            //- TODO: показать прогресс загрузок
            .d-flex.flex-column( v-if="getRevision" )
              app-text
                .mx-2.mt-2( v-html="generatedRevInfo" test-id="revision-info")

              .caption.mx-2.mt-1.d-flex( v-if='passportOffset' )
                b {{$t('section.structure.model.revisionMoving.displacement')}}
                div
                  div.pl-1 X: {{passportOffset.offsetx}}
                  div.pl-1 Y: {{passportOffset.offsety}}
                  div.pl-1 Z: {{passportOffset.offsetz}}
                
              revision-passport( :buttonHeader="$t('section.structure.model.details.header')" :passportData="currentRevisionPassport" )
              
              v-btn.dict-button.mt-1( v-if="meOfSelectedModel && hasModelEditAnnotation" color="accent" @click="showDialogAnnotation()" test-id="structure-revision-annotation" dense text x-small) {{ $t('section.structure.model.annotation') }}
              v-btn.dict-button.mt-2( color="accent" dense text x-small test-id="structutre-change-revision" @click="showRevisionsDialog()" ) {{ $t('section.structure.model.selectRevision') }}
              v-btn.dict-button.mt-2( v-if="meOfSelectedModel && hasModelCompareRevision" color="accent" dense text x-small @click="compareRevisionsIFC" )  {{ $t('section.structure.model.compareRevisions') }}
              v-btn.dict-button.mt-2( color="accent" dense text x-small @click="exchangeFiles" ) {{ textChangeFileModelButton }}
              //- coord-menu
              model-coords( v-if="meOfSelectedModel && hasModelControlOffset" )

              suspect-elements()

              .d-flex.align-center.my-2.mx-2
                app-toolbar( horizontal )
                  app-toolbar-button( bright icon="$model-visible-view-icon" :active="unselectedModelsViewMethod.view"
                                      :title="$t('section.structure.model.showAllModels')" @click.stop="showUnselectedModels()" )

                  app-toolbar-button( bright icon="$model-visible-hide-view-icon" :active="unselectedModelsViewMethod.hide"
                                      :title="$t('section.structure.model.hideOtherModels')" @click.stop="hideUnselectedModels()" )

                  app-toolbar-button( bright icon="$model-visible-xray-view-icon" :active="unselectedModelsViewMethod.xray"
                                      :title="$t('section.structure.model.makeModelsTranslucent')" @click.stop="xrayUnselectedModels()" )                    

            app-text( v-else) 
              div( v-if="hasModelLoadRevision && meOfSelectedModel" )
                //- .mx-2.mt-2 У данной модели еще нет ревизий
                //- coord-menu()
                //- model-coords
                app-button-add.mt-3.ml-1.mr-1( v-if="!selectedModel.pointCloud" width="100%" :text="$t('section.structure.model.addRevision')" @click="showVarietyAddRevision")                 
                app-button-add.mt-3.ml-1.mr-1( v-if="!selectedModel.pointCloud" width="100%" :text="$t('section.structure.model.addPointCloud')" @click="showVarietyAddPointCloud")
              model-coords( v-if="meOwner || hasModelControlOffset" )
              div( v-if="selectedModel.pointCloud" )  
                app-text
                  .mx-2.mt-2( v-html="$t('section.structure.model.currentPointCloud')")
                .caption.mx-2.mt-1.d-flex
                  b {{$t('section.structure.model.revisionMoving.displacement')}}
                  div
                    div.pl-1 X: {{selectedModel.offsetX}}
                    div.pl-1 Y: {{selectedModel.offsetY}}
                    div.pl-1 Z: {{selectedModel.offsetZ}}
                pointCloud-passport( :buttonHeader="$t('section.structure.model.details.header.pointCloud')" :passportData="selectedModel.pointCloud" )  
                model-coords( v-if="hasModelControlOffset" )
                .d-flex.align-end.my-2.mx-2
                  app-toolbar( horizontal )
                    app-toolbar-button( bright icon="$model-visible-view-icon" :active="unselectedModelsViewMethod.view"
                                        :title="$t('section.structure.model.showAllModels')" @click.stop="showUnselectedModels()" )
      
                    app-toolbar-button( bright icon="$model-visible-hide-view-icon" :active="unselectedModelsViewMethod.hide"
                                        :title="$t('section.structure.model.hideOtherModels')" @click.stop="hideUnselectedModels()" )
      
                    app-toolbar-button( bright icon="$model-visible-xray-view-icon" :active="unselectedModelsViewMethod.xray"
                                        :title="$t('section.structure.model.makeModelsTranslucent')" @click.stop="xrayUnselectedModels()" ) 

                    app-toolbar-button( v-if="hasModelLoadRevision" bright icon="mdi-delete" :active="unselectedModelsViewMethod.delete"
                                        :title="$t('section.structure.model.deletePointCloud')" @click.stop="deletePointCloud()" ) 
        //- Вывод сведений о подозрительном элеx`менте - пока не нужно.
        //- app-floating-panel( v-if="chosenSuspectElement" :title="$t('section.element', {name: chosenSuspectElement.name})" bodyMinHeight="150" overflowBody="hidden" 
        //-   :settings="floatingPanels('SUSPECT_ELEMENT_DETAILS')")
        //-   element-details( :element="chosenSuspectElement" style="flex: 1;" )

        app-floating-panel( :title="$t('section.structure.model.status')" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('PROJECT_MODEL_STATUS')")
          app-section.mt-1( v-if="isPro"  style="overflow: auto; overflow-x: hidden; padding: 0; height:100%;" )
            app-section-header
            v-menu(offset-x right)
              template(v-slot:activator="{ on }")
                v-btn.dict-button(v-if="selectedModel && selectedModel.stage" color="accent" dense text x-small v-on="on" ) {{ selectedModel.stage.title }}
              v-list.p-0(v-if="meOfSelectedModel && hasModelCheck" light dense)
                v-list-item(v-for="item in dict.stage" :key="item.name" ripple @click="setStage({ modelUuid: selectedModel.uuid, item })") {{ item.title }}

            v-menu(offset-x right)
              template(v-slot:activator="{ on }")
                v-btn.dict-button(v-if="selectedModel && selectedModel.quality" color="accent" dense text x-small v-on="on" ) {{ selectedModel.quality.title }}
              v-list.p-0(v-if="meOfSelectedModel && hasModelQuality" light dense)
                v-list-item(v-for="item in dict.quality" :key="item.name" ripple @click="setQualtiy({ modelUuid: selectedModel.uuid, item })") {{ item.title }}

            v-menu(offset-x right)
              template(v-slot:activator="{ on }")
                v-btn.dict-button( color="accent" dense text x-small v-on="on" ) {{ selectedModel.accessibleStatusType.title }}
              v-list.p-0(v-if="meOfSelectedModel && hasModelBlocking" light dense)
                v-list-item(v-for="item in dict.accessible" :key="item.name" ripple @click="setAccessible({ modelUuid: selectedModel.uuid, item })") {{ item.title }}

            v-menu(offset-x right)
              template(v-slot:activator="{ on }")
                v-btn.dict-button( color="accent" dense text x-small v-on="on" ) {{ rootLevelTitle }}
              v-list.p-0(v-if="meOfSelectedModel && hasModelBlocking" light dense)
                v-list-item(ripple @click="setRoot({ modelUuid: selectedModel.uuid, root: true })") {{$t('section.structure.model.on')}}
                v-list-item(ripple @click="setRoot({ modelUuid: selectedModel.uuid, root: false })") {{$t('section.structure.model.off')}}

        app-floating-panel( :title="$t('section.structure.model.team')" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('PROJECT_MODEL_TEAM')")
          template( v-slot:headerButton )
            app-toolbar(v-if="meOwner || isAddTeam" horizontal ) 
              app-toolbar-button( v-if="showButtonLinkInviteToProject()" header size="small" iconSize="18" icon="$link-plus-icon" @click.stop="showListRoles" :title="$t('section.structure.model.teamLink')"  )
              app-toolbar-button(v-if="meOwner && showButtonLinkInviteToProject()" header size="small" icon="$copy-team-icon" @click.stop="showDialogCopyTeam" )
              app-toolbar-button( header size="small" icon="$add-box-icon" @click.stop="showDialogAddMember"  )
              //- app-toolbar-button( v-if="hasTeamAdd && memberNotWay" header size="small" icon="$add-box-icon" @click.stop="showDialogAddMember"  )
          v-progress-linear( v-if="dialog.copyTeamProcessing" indeterminate color="accent")

          app-section.mt-1.base-scroll-body( style="overflow-x: hidden; padding: 0; height:100%;" )
            .roles(v-for="r in roles")
              v-btn.dicts-button( v-if="showRoles(r)" color="accent" dense text x-small @click="showUserWithThisRole(r)") {{r.title}}
                v-icon(v-if="!r.show" small).pl-1 mdi-menu-down
                v-icon(v-if="r.show" small).pl-1 mdi-menu-up
              div(v-for="t in membersWithUnicRoles" v-if="r.show")
                .teammate(v-for="role in t.roles" @contextmenu.stop="showTeammateMenu(t, r)") 
                  div.mb-3(v-if="r.title == role.title" @click="showTeammateDialog(t)")
                    app-avatar(:url="filePath(t)" :colorObj="getBgColor(t.profile.uuid)" :text="memberName(t)")
                    div.u-info
                      span {{memberName(t)}}
                      span.p-info ({{$t('profile.action.items.from')}} {{$t(role.model.title.replace(/{|}/g, ''))}})
                    v-btn.user-menu(fab icon x-small @click.stop="showTeammateMenu(t, r)" )
                      v-icon(small color="var(--v-primary-darken4)") mdi-dots-vertical

            .roles(v-for="r in orgRoles")
              v-btn.dicts-button( v-if="showOrgRoles(r)" color="accent" dense text x-small @click="showUserWithThisRole(r)") 
                v-icon.mr-1(size="14") mdi-account-group-outline
                span {{r.title}}
                v-icon(v-if="!r.show" small).pl-1 mdi-menu-down
                v-icon(v-if="r.show" small).pl-1 mdi-menu-up
              div(v-for="t in membersWithUnicRoles" v-if="r.show")
                .teammate(v-for="role in t.orgRoles" @contextmenu.stop="showTeammateMenu(t, r)") 
                  div.mb-3(v-if="r.title == role.title" @click="showTeammateDialog(t)")
                    app-avatar(:url="filePath(t)" :colorObj="getBgColor(t.profile.uuid)" :text="memberName(t)")
                    div.u-info
                      span {{memberName(t)}}
                      span.p-info ({{$t('profile.action.items.from')}} {{$t(role.model.title.replace(/{|}/g, ''))}})
                    v-btn.user-menu(fab icon x-small @click.stop="showTeammateMenu(t, r)" )
                      v-icon(small color="var(--v-primary-darken4)") mdi-dots-vertical

            app-menu( ref="teammateMenu" :menu="teammateMenu" )
            div(v-if="meOfSelectedModel && membersWithUnicRoles.length == 0 && hasTeamAdd")
              app-button-add.mt-3.mr-1.ml-1.mb-3( width="100%" :text="$t('section.structure.model.addTeam')" @click="showDialogAddMember")

          //TODO master add file
        app-floating-panel( :title="$t('section.structure.model.materials')" bodyMinHeight="150" overflowBody="hidden" 
          :settings="floatingPanels('PROJECT_MODEL_ATTACHMENTS')")
          template( v-slot:headerButton )
            app-toolbar-button( v-if="hasModelDownloadAttachment && meOfSelectedModel" header size="small" icon="$add-box-icon" @click.stop="showDialogAddMaterials" )
          app-section.mt-1( style="overflow: auto; overflow-x: hidden; padding: 0; height:100%;" )
            //- .d-flex( style="justify-content: flex-end" )
            //-   app-header-action( icon="add_box.png" @click="showDialogAddMaterials" )
            div(v-if="selectedModelMaterials.length == 0 && hasModelDownloadAttachment && meOfSelectedModel")
              app-button-add.mt-3.ml-1.mr-1.mb-3( width="100%" :text="$t('section.structure.model.addMaterial')" @click="showDialogAddMaterials")
            
            div.material( v-for='material in selectedModelMaterials' )
              v-hover( v-slot="{ hover }" )
                div.d-flex( style="width:100%; height:18px;" )
                  div.material-data( @click="downloadMaterial(material)" :class="{ 'pointer': hasModelLoadAttachment }")
                    v-tooltip( bottom v-if='material.discribe.length > 0' )
                      template( v-slot:activator="{ on, attrs }" )
                        div.material-title.mr-1( v-bind="attrs" v-on="on" ) {{ material.title }}
                      div.material-discribe
                        span {{ material.discribe }}
                    div.material-title( v-if='!material.discribe.length > 0' )
                      span {{ material.title }}
                  v-btn.ml-auto( v-if="hover && (hasModelLoadAttachment && meOfSelectedModel)" icon x-small color='#757575' @click='downloadMaterial(material)' )
                    v-icon( small ) file_download
                  v-btn.ml-auto( v-if="hover && (hasModelDeleteAttachment && meOfSelectedModel)" icon x-small color='#757575' @click='confirmDeleteMaterial(material)' )
                    v-icon( small ) delete
            app-dialog-confirm( v-model="dialog.deleteMaterial" ref="delMaterial" @confirm="deleteMaterial(dialog.materialToDelete)" )
              app-text {{ $t('section.structure.model.deleteFileConfirm') }} "{{ dialog.materialToDelete.title }}"?

    app-dialog( v-model="dialog.varietyAddRevision" :header="$t('section.structure.model.addRevision')" :items="varietyRevision" )
      
    next-cloud-tree(v-model="dialog.nextCloud")
    //-yandex-disk-tree(v-model="dialog.yandexDisk")
    //- google-drive-tree(v-model="dialog.googleDrive")
    
    app-dialog( v-model="dialog.materials" :header="$t('section.structure.model.materials')" @confirm="prepareMaterial" @close="clearMaterialForms" :confirmText="$t('button.add')" :confirmDisabled='!modelMaterial.title.trim()')
      app-section 
        app-text-field( v-model="modelMaterial.title" :label="$t('section.structure.model.titlematerials')" )
        app-textarea( v-model="modelMaterial.discribe" :label="$t('section.structure.model.materialDescription')" height=100 outlined no-resize)
        app-file-input( v-model="modelMaterial.file" :max-size="104857600" :formats="[]" )

    app-dialog( v-model="dialog.addRevision" :header="$t('section.structure.model.addRevision')" @confirm="prepareRevision" :confirm-disabled="!newRevision.file" :confirmText="$t('button.add')" )
      app-section 
        app-file-input( v-model="newRevision.file" :max-size="10737418240" :formats="['.ifc','.ifczip']" test-id="structure-file-input")
        div( v-if="$app.dev.isVisibleRevisionCloudLink")
          .text-caption.primary--text {{ $t('section.structure.model.form.or') }}
          app-text-field( v-model="newRevision.link" :label="$t('section.structure.model.form.linkToFile')" )
          .text-caption.primary--text {{ $t('section.structure.model.form.fileWarning') }}
        app-textarea( v-model="newRevision.annotation" :label="$t('section.structure.model.annotation')" outlined hideDetails )
        v-checkbox( v-model="newRevision.expparser" color="accent" dense hide-details light)
          template(v-slot:label)
            app-text.mx-n1 {{ $t('section.structure.model.experimentalParser') }} 
            //- (возможно некорректное отображение модели и расчет коллизий)

    app-dialog( v-model="dialog.addPointCloud" :header="$t('section.structure.model.addPointCloud')" @confirm="prepareProjectCloud" :confirmText="$t('button.add')" )
      app-section 
        app-file-input( v-model="newPointCloud.file" :max-size="10737418240" :formats="['.laz', '.e57']" test-id="structure-file-input")

    app-dialog( v-model="dialog.annotation" :header="$t('section.structure.model.annotation')" @confirm="confirmRevisionAnnotation" :confirmText="$t('button.add')" ) 
      app-section 
        //- app-text {{ selectedRevision && selectedRevision.annotation }}
        app-textarea( v-if="selectedRevision" v-model="selectedRevisionAnnotation" noResize rows='5' :label="$t('section.structure.model.annotation')")

    app-dialog( v-model="dialog.team" :header="$t('section.structure.model.team')" )
      app-section.base-scroll-body(style="height:60vh;")

        app-section-header-tabs.mb-1( :items="teamTabItems" :value="teamTab" @select="teamTabSelect" light )

        template(v-if="teamTab == 0")
          app-text-field( v-model="search" :label="allUsers ? $t('section.structure.model.listAllUsers') : $t('section.structure.model.searchTeamCorp')" prepend-icon="search" clearable )
          v-checkbox.allUsers(v-if="project.organization" v-model="allUsers" :label="$t('section.structure.model.listAllUsers')" hide-details  light @change="foundedUsersList(search)")
          div( v-if="!!search")
            div( v-if="foundedUsers && foundedUsers.length > 0" )
              .caption.mb-2 {{ $tc('section.structure.model.searchTeam', 2, {n: foundedUsers.length }) }}
              .m-founded-users
                project-teammate( v-for="t in foundedUsers" :key="t.uuid" :teammate="t" :withContext='false')
                  app-button.white--text(v-if="t.profile.uuid !== user.uuid" dense x-small action @click="selectProfile(t)") {{ $t('button.invite') }}
            
            .title.text-center.mb-4( v-else-if="nowSearching" ) {{ $tc('section.structure.model.searchTeam', 1) }}
            .title.text-center.mb-4( v-else-if="searchEmailSplit.length == 0" ) {{ $t('section.structure.model.emptySearch', {query: search}) }}
          
            .m-founded-users.text-left.mt-2(v-if="searchEmailSplit.length > 0") 
                .caption {{$t('section.structure.model.corpMemberNotFound')}}
                .caption.mb-2 {{$t('section.structure.model.inviteMembers')}}
                project-team-invite( v-for="mail in searchEmailSplit" :key="mail" :teammate="mail" )
                  app-button( action dense x-small @click="selectInvite(mail)" :disabled="alreadyInvite(mail)") {{ $t('button.invite') }}
          .m-founded-users( v-else )
            project-teammate( v-for="t in availableTeamCorp" :key="t.uuid" :teammate="t" :withContext='false' @click.native="selectProfile(t)" )
              app-button.white--text(v-if="t.profile.uuid !== user.uuid" action dense x-small @click="selectProfile(t)" ) {{ $t('button.invite') }}

        template(v-else)
          v-row( v-for="group in availableOrgGroups" :key="group.uuid" dense)
            v-col.pl-2
              app-text.pt-1 {{group.title}}
            v-col.pr-2(cols="auto")
              app-button.white--text( action dense x-small @click="selectGroup(group)" :disabled="alreadyInvite(group.uuid)") {{ $t('button.invite') }}
          div(v-if="!availableOrgGroups.length")
            app-text(v-if="project.organization").text-center.mt-2 {{ $t('section.organization.noGroup') }}
            app-text(v-if="!project.organization").text-center.mt-2 {{ $t('section.structure.model.team.noOrganization') }}
        
    app-dialog( v-model="dialog.member" :header="selFullname" :items="memberItems" ) 

    app-dialog( v-model="dialog.copyTeam" :header="$t('section.structure.model.copy') + ` ${$t(selectedModel.title)}`" :items="memberItems" 
    :confirm-disabled="!choosedInSelect" @confirm="save" :confirmText="$t('button.save')")
      app-section
        app-select( v-model="choosedInSelect" :items="preparedModels" item-value="uuid" :label="$t('section.structure.model.header')" clearable hideDetails test-id="structure-select-model" )
        v-checkbox( v-model="decouplingModel" :label="$t('section.structure.model.untie')" color="accent" light )

    project-teammate-roles( v-model="dialog.roles" ref="roles" :teammate="selMember" @invite="afterInvite")
    project-teammate-roles( v-model="dialog.rolesInvite" ref="rolesInvite" inviteByRole @invite="afterInvite")
    project-teammate-roles( v-model="dialog.groupRoles" ref="groupRoles" :teammate="selGroup" @invite="afterInvite" isGroup)

    app-dialog( v-model="dialog.revisions" :header="$tc('section.structure.model.revision', 1)" )
      app-section
        .base-scroll-body.revisions-list
          div( v-if="revisionsLoading" )
            v-skeleton-loader( type="list-item-three-line" light )
            v-skeleton-loader( type="list-item-three-line" light )
            v-skeleton-loader( type="list-item-three-line" light )
          .primary--text.text--lighten-3( v-else-if="revisions.length == 0" ) 
            div {{ $t('section.structure.model.noRevision') }}
            app-button-add.mt-3.ml-1.mr-1.mb-3( v-if="hasModelLoadRevision" width="100%" :text="$t('section.structure.model.addRevision')" @click="showVarietyAddRevision") 
          .loaded-revisions( v-else )
            .revision.pa-2( v-for="rev in revisions" :key="rev.uuid"  v-bind:class="[passportElementClass(rev) ? 'app-cursor--pointer' : '', selectedRevision && selectedRevision.uuid == rev.uuid ? 'current-revision' : '']" test-id="revision")
              div( @click="chooseRevision(rev)" )
                .d-flex.align-center.justify-space-between
                  .model-revision_title {{ $t('section.structure.model.revisionFrom', { version: rev.version || '?', date: rev.revisionDate }) }}
                  app-icon-button( v-if="hasModelDeleteRevision && meOfSelectedModel" icon="$delete-icon-outlined" buttonSize="24" iconSize="20" @click.stop="preDeleteRevision(rev.uuid)" )
                .model-revision__status_text( v-if="!existPassportElements(rev)" style="color: #D32F2F" ) {{ `(${ $t('error.default') })` }}
                .model-revision__status_text( v-else :style="{ color: rev.state.name === 'FAILED' ? '#D32F2F' : rev.state.name === 'PROGRESS' ? '#0288D1' : '#009688' }" ) ({{ $t(rev.state.message) | stateTitle }})
                app-text.mt-1 {{ existPassportCadShema(rev) }}, {{ existPassportCountElement(rev) }} {{ $t('section.structure.model.elements') }}
                app-text {{$t('module.process.headers.author')}}: {{ rev.author.name }}
                app-text( v-if="rev.rebuildDate != null" ) {{$t('section.structure.model.recalculated')}}: {{ rev.rebuildDate }}
                app-text.mt-4( v-if="rev.annotation" ) {{ $t('section.structure.model.annotation') }}: {{ rev.annotation }}
                app-text.mt-4( v-if="rev.error" ) {{ $t('info.short') }}: {{ rev.error }}
              
              .d-flex.align-center.justify-space-between
                .d-flex.align-center( v-if="meOwner" @click="regeometryRevision(rev.uuid)" )
                  app-icon-button.mr-1( icon="mdi-play-circle-outline" iconColor="var(--v-accent-base)" buttonSize="24" :disabled="rev.fixed" )
                  app-text( :class="{ 'model-revision-color-disabled' : rev.fixed }" ) {{$t("section.structure.model.revisionSettings.regeometry")}} 
                app-button.text-uppercase( v-if="!rev.fixed && rev.state.name != 'FAILED' && existPassportElements(rev) && meOwner" outlined height="32" color="var(--v-accent-base)" @click="fixRevision(rev.uuid)" ) {{$t("section.structure.model.revisionSettings.fix")}}
                app-button.text-uppercase( v-if="rev.fixed && rev.state.name != 'FAILED' && existPassportElements(rev) && meOwner" outlined height="32" color="#B5B5B5" @click="unfixRevision(rev.uuid)" )
                  span( style="color: #303030;" ) {{$t("section.structure.model.revisionSettings.unfix")}}

    app-dialog( v-model="dialog.task" :header="$t('module.task.toMember')" @confirm="createTask" :confirm-text="$t('button.create')" )
      app-section
        v-textarea.mt-2( v-model="newTask.title" color="#6B6B6B" :label="$t('module.task.form.title')" row-height="12" rows="1" auto-grow light )
        v-textarea.mt-2( v-model="newTask.desc" color="#6B6B6B" :label="$t('module.task.form.description')" rows="1" auto-grow light )

    app-dialog-confirm( v-model="dialog.deleteRevision" :header="$t('section.structure.model.deleteRevision')" @confirm="realDeleteRevision")
      app-section
        app-text {{ $t('section.structure.model.confirmDeleteRevision')  }}

    app-dialog( v-model="dialog.regeometryRevision" :header="$t('section.structure.model.recalculateRevision')"
                :confirmText="$t('button.rebuild')" @confirm="realGeometryRevision")
      app-section
        app-text {{ $t('section.structure.model.startRecalcRevision') }}
        v-checkbox( v-model="expparser" color="accent" dense hide-details light)
          template(v-slot:label)
            app-text.mx-n1 {{ $t('section.structure.model.experimentalParser') }} 

    app-dialog( v-model="dialog.startCalculateConvexHull" :header="$t('section.structure.model.calculateConvexHull')")
      app-section
        app-text {{ $t('section.structure.model.processCalculateCH') }}
        div(style="height:23px")
          v-btn.mt-2.float-right(color="#003333" dense text x-small outlined @click="hideStartCalculateConvexHull()") OK

    profile-form( v-if="selMember" v-model="dialog.profile" :user="selMember.profile" disabled @click="confirmEditUser()")


    //- Сравнение IFC ревизий
    app-dialog( v-model="dialog.compareRevisions" :header="$t('section.structure.model.compareRevisions')" :confirm-text="$t('button.show')" @confirm="showCompareRevisionsIFC" :confirm-disabled="falseShowCompareRevisionsIFC")
      app-section
        //- app-color-picker(v-model="compareRevisions.firstColor" )
        app-select(
            v-model="compareRevisions.first" 
            :items="revisionWithExtTitle" 
            item-text="extTitle" 
            :label="$tc('section.structure.model.revision', 1) + ' 1'"
            return-object
          )

        //- app-color-picker(v-model="compareRevisions.secondColor" )
        app-select.mt-2(
            v-model="compareRevisions.second"    
            :items="revisionWithExtTitle" 
            item-text="extTitle" 
            :label="$tc('section.structure.model.revision', 1) + ' 2'" 
            return-object
          )

      app-section.mt-1
        v-checkbox( v-model="compareRevisions.checked.new"  :color="compareRevisions.firstColor"  :label="$t('section.structure.model.showNewItems')" dense hide-details light )
        v-checkbox( v-model="compareRevisions.checked.edit" :color="compareRevisions.movedColor"  :label="$t('section.structure.model.showChangedItems')" dense hide-details light )
        v-checkbox( v-model="compareRevisions.checked.del"  :color="compareRevisions.secondColor" :label="$t('section.structure.model.showDeletedItems')" dense hide-details light )
      
      app-section.mt-1
        v-checkbox( v-model="compareRevisions.checked.hideOther" :label="$t('section.structure.model.hideElements')" dense hide-details light )
    
    app-dialog-loading(v-model='compareRevisionsLoading')

    app-dialog-confirm(v-model="dialog.deletePerson" @confirm="deletePerson")
      app-text(v-if='selMember && selMember.profile.name') {{ $t('profile.action.items.confirmDeleteUser', {name: selMember && selMember.profile.name}) }} 
      app-text(v-else) {{ $t("profile.action.items.confirmDeleteGroup", {name: selMember && selMember.orgGroup.title} ) }}

    app-dialog-confirm(v-model="dialog.downloadRevisionIFCDialog" :header="$t('section.structure.model.downloadRevision')" :disabled="radios == null" @confirm="downloadRev" )
      v-radio-group(v-model="radios" )
        v-radio(v-if="hasModelDownloadRevision" :label="$t('section.structure.model.downloadRevision')" light checked)
        v-radio(v-if="showDownloadIFCBimitAttr" :label="$t('section.structure.model.downloadRevision')+' (с BIMIT атрибутами)'" light)
        v-radio(v-if="showDownloadIFCBimitAttr && hasModelDownloadRevision" :label="$t('Скачать оба IFC-файла')" light)

    app-dialog( v-model="dialog.downloadLasDialog" :header="$t('section.structure.model.downloadLasRevision')" :items="varietyDownloadPointCloud" )

    rule-dialog( v-model="dialog.showRule" :formRole.sync = "formRole" )
    project-teammate-org-group( v-model="dialog.showOrgGroup" :teammate.sync = "selMember" )

</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import {api} from '@/api'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

import { projectService } from '@/_services'
import { AlertService } from '@/assets/app/AlertService'

import ProjectTeammate from '@/components/project/common/Teammate.vue'
import ProjectTeammateRoles from '@/components/project/common/TeammateRoles.vue'
import ProfileForm from '@/components/admin/users/ProfileForm'
import ProjectTeamInvite from '@/components/project/common/TeamInvite'
import SuspectElements from '@/components/project/panel/left/suspectElements/SuspectElements'
import ElementDetails from './components/element/Details'

import nextCloudTree from './components/cloud/nextCloudTree'
// import YandexDiskTree from './components/cloud/YandexDiskTree'
// import GoogleDriveTree from './components/cloud/GoogleDriveTree'

import { SourcePath } from '@app/SourcePath'

import _ from 'lodash'
import { $_validate_rules } from '@/common/ValidateRules'
import RevisionPassport from './RevisionPassport.vue'
import PointCloudPassport from './PointCloudPassport.vue'
import ModelCoords from './components/ModelCoords.vue'
import uniqolor from 'uniqolor'
import { RevisionLoader } from '@/plugins/xeokit/RevisionLoader'
import { hexToRgb } from '@/utils'
import RuleDialog from '@/pages/admin/components/RuleDialog.vue'
import ProjectTeammateOrgGroup from '@/components/project/common/TeammateOrgGroup.vue'

import { onClickedDelete } from '@/plugins/xeokit/LasLoaderPlugin/LASDataSource'

export default {
  name: 'ProjectPanelModel',
  mixins: [$_validate_rules],
  // components: { ProjectTeammate, ProjectTeammateRoles, ProfileForm, nextCloudTree, CoordMenu, GoogleDriveTree, CimLogicGroupDialog, ProjectTeamInvite},
  components: { 
    ProjectTeammate, 
    ProjectTeammateRoles, 
    ProfileForm, 
    nextCloudTree, 
    ProjectTeamInvite, 
    RevisionPassport, 
    PointCloudPassport,
    ModelCoords,
    SuspectElements,
    ElementDetails,
    RuleDialog,
    ProjectTeammateOrgGroup
  },

  data () {
    const defaultForm = Object.freeze({
      uuid: null,
      title: null,
      rolefunction: [],
      roles: [],
      orgRoles: [],
    })
    return {
      compareRevisionsLoading: false,
      
      radios: null,

      unselectedModelsViewMethod: {
        view: true,
        hide: false,
        xray: false,
        delete: false
      },

      teamTab: 0,
      toggleOffsetModel: false,

      toggleDetailsModel: false,

      existConvexHull: false,
      activeConvexHull: false,

      dialog: {
        deletePerson: false,
        varietyAddRevision: false,
        varietyAddPointCloud: false,
        nextCloud: false,
        yandexDisk: false,
        googleDrive: false,
        addRevision: false,
        addPointCloud: false,
        annotation: false,
        team: null,
        materials: false,
        member: false,
        roles: false,
        groupRoles: false,
        progress: false,
        revisions: false,
        profile: false,
        task: false,
        setCoords: false, 
        editingCim: false,
        deleteRevision: false,
        regeometryRevision: false,
        startCalculateConvexHull: false,
        deleteMaterial: false,
        compareRevisions: false,
        downloadRevisionIFCDialog: false,
        downloadLasDialog: false,
        materialToDelete: {
          title: "",
          model: null,
          file: null,
          discribe: '',
          type: 0
        },
        showRule: false,
        rolesInvite: false,
        copyTeam: false,
        copyTeamProcessing: false,
        showOrgGroup: false,
      },
      modelMaterial: {
        model: null,
        file: null,
        title: '',
        discribe: '',
        type: 0
      },
      newRevision: {
        expparser: false,
        file: null,
        link: null,
        annotation: ''/*,
        eulerX: "0",
        eulerY: "0",
        eulerZ: "0",
        offsetX: "0",
        offsetY: "0",
        offsetZ: "0"*/
      },
      newPointCloud: {
        file: null,
        modelUuid: null
      },
      newTask: {
        title: '',
        desc: '',
      },
      
      search: '',
      searchId: null,
      nowSearching: false,
      corpUsers: [],
      allUsers: false,
      foundedUsers: null,
      selectedUser: null,
      selectedWorkRevison: null,
      selMember: null,
      selGroup: null,
      selectedModelMaterials: [],
      inviteList: [],
      compareRevisions: {
        first: null,
        firstColor: "#00FF4C",
        second: null,
        secondColor: "#FF2400",
        movedColor: "#FFEC00",
        checked:{
          new: true,
          edit: true,
          del: true,
          hideOther: true
        }
      },
      expparser: false,
      showedPointCloud: false,
      pointCloudLoadInProgress: false,
      decouplingModel: false,
      choosedInSelect: null,
      formRole: Object.assign({}, defaultForm),
      defaultForm,

      selectedRevisionAnnotation: '',
    }
  },

  watch: {
    selectedModel() {

      this.compareRevisions.first = null;
      this.compareRevisions.second = null;

      this.loadRevisions(this.selectedModel.uuid)
      this.restoreModels()
      api.materials.getModelMaterials(this.selectedModel.uuid).then((data)=>{
        this.selectedModelMaterials = data
      })

      let revisionUuid = this.selectedModel.revision?.uuid
      if(revisionUuid) {
        this.loadSuspectElements([revisionUuid])
      }
    },

    selectedRevision() {
      XeokitMediator.ModelsManager.setModelsVisible(XeokitMediator.viewer.scene.modelIds, true)
      XeokitMediator.ModelsManager.setModelsXrayed(XeokitMediator.viewer.scene.modelIds, false)
      this.clearUnselectedModelsViewMethod()
      this.unselectedModelsViewMethod.view = true

      console.log('selectedRevisionAnnotation')
    },

    search (val) {
     this.foundedUsersList(val)
    }


  },

  mounted () {
    this.$store.dispatch('roles/INIT')
    this.loadRevisions(this.selectedModel.uuid)
    api.materials.getModelMaterials(this.selectedModel.uuid).then((data)=>{
      this.selectedModelMaterials = data
    })
        
    this.setSelectedPatch(null)

    if (this.selectedRevision?.convexHullFile) {
      this.existConvexHull = true

      if (this.projectSettings.workModel[this.selectedModel.uuid].convexHull)
        this.activeConvexHull = true
      else
        this.activeConvexHull = false
    } else 
      this.existConvexHull = false
  },

  beforeMount(){
    api.materials.getModelMaterials(this.selectedModel.uuid).then((data)=>{
      this.selectedModelMaterials = data
    })
    
  },

  updated() {
    this.dialog.copyTeamProcessing = this.copyProcess
  },

  beforeDestroy(){
    this.$store.dispatch('roles/INIT')
    this.restoreModels()
  },

  computed: {
    ...mapState('project', ['selectedModel', 'selectedRevision', 'selectedRevisionPassport', 'project', 'dict', 'colleagues', 'projectSettings', 'copyProcess']),
    ...mapGetters('project', ['revisions', 'meOfSelectedModel', 'membership', 'revisionsLoading', 'membersOfSelectedModel', 'openedRoles', 'flatlist', 'iCanManageRolesOnModelEdit', 'iCanManageRolesOnModelDelete', 'iCanManageRolesOnModelAdd', 'listPointCloudUuid', 'projectUuid']),
    ...mapGetters('roles', ['roles']),
    ...mapGetters('corp', {orgRoles:'roles', orgGroups:'groups'}),
    ...mapGetters('corp', ['employees']),
    ...mapGetters('process', { processes: 'monitoredProcesses', uploads: 'uploadsForModel' }),
    ...mapGetters('process', ['areMonitoredProcessesRunning']),
    ...mapState('authUser', ['user']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters('authUser', ['isProjectOwner']),
    ...mapGetters('projectPermissions', ['meOwner', 'hasTaskCreate', 'hasTeamAdd', 'hasTeamEdit', 'hasTeamDelete', "meOwner","hasModelCompareRevision", 
                                         "hasModelDownloadRevision", "hasModelLoadRevision", "hasModelDeleteRevision", "hasModelEditAnnotation", "hasModelDownloadAttachment", 
                                         "hasModelLoadAttachment", "hasModelDeleteAttachment", "hasModelControlOffset", "hasModelCheck", "hasModelQuality", "hasModelBlocking"]),
    ...mapGetters('roles', ['fnTeam']),

    isAddTeam () {
      return this.meOfSelectedModel && this.hasTeamAdd && this.iCanManageRolesOnModelAdd().length
    },

    isDialogVisible: {
      get: 'areMonitoredProcessesRunning',
      set(val) { 
        if (val === false) this.resetMonitoredProcesses() 
      }
    },

    preparedModels() {
      return this.flatlist.map(item => item.title)
    },

    textChangeFileModelButton () {
      if (this.existConvexHull) {
        if (this.activeConvexHull) 
          return this.$t('section.structure.model.xktFileConvexHull')
        else 
          return this.$t('section.structure.model.xktFileOriginalModel')
      } else {
        return this.$t('section.structure.model.xktFileCreateConvexHull')
      }
    },

    membersWithUnicRoles () {
      let list = this.membersOfSelectedModel.map(m => {
        m.roles = _.uniqBy(m.roles, 'uuid')
        m.orgRoles = _.uniqBy(m.orgRoles, 'uuid')
        return m
      })
      return list
    },


    isControlModel () {
       return this.membersOfSelectedModel.find( teammates => teammates.profile.uuid == this.user.uuid)?.isControlModel
    },

    availableTeamCorp() {
      let list = []
      let orgTitle = this.project.organization ? this.project.organization.title : null
      
      if (!this.allUsers && orgTitle) {
        this.employees.forEach(em => {
          this.corpUsers.push(em.profile.uuid)
          list.push(em)
        })
        list.forEach(p => p.profile.org = orgTitle )
      } else {
        list = this.colleagues.map(p => ({ profile: p }))
      }
      return list
    },

    availableOrgGroups () {
      const uuids = this.selectedModel 
      ? this.project.membership
        .filter(m => m.memberReference.find(mr => mr.model.uuid === this.selectedModel.uuid) )
        .map(m => m.profile?.uuid || m.orgGroup?.uuid)
      : []
      return this.orgGroups.filter(p => !uuids.includes(p.uuid))
    },

    selFullname () {
      return this.selMember && `${this.selMember.profile.name}` || ''
    },

    showDownloadIFCBimitAttr() {
      return window.settings.showDownloadIFCBimitAttr ?? false
    },

    getRevision() {
      return this.selectedModel.revision
    },

    lastRevisionAdded(){
      let lastRevision = this.revisions[0]
      return lastRevision?.version
    },

    generatedRevInfo () {
      let current = this.selectedModel.revision
      this.checkExistConvexHull(current)
      // return this.$t('section.structure.model.currentRevision', {version: current.version, date: current.revisionDate, shemaIFC: current?.modelRevisionPassport?.cadShema, count: current?.modelRevisionPassport?.countElements, lastVersion: last.version, lastDate: last.revisionDate})
      // @Макс вырезал из модели current & last revsion теперь только приходит revision
      return this.$t('section.structure.model.currentRevision', {version: current.version, 
          date: current.revisionDate, shemaIFC: current?.modelRevisionPassport?.cadShema, count: current?.modelRevisionPassport?.countElements, 
          lastVersion: this.lastRevisionAdded, lastDate: current?.revisionDate })
    },
    
    nameLasFile () {
      return this.$t( 'section.structure.model.lasTitle', { title: this.selectedModel.pointCloud.filename })
    },

    sizeLasFile () {
      let filesize = this.selectedModel.pointCloud.filesize * 1024
      filesize =  filesize.toFixed(2)
      return  this.$t( 'section.structure.model.lasSize', { filesize: filesize })
    },

    // generatedPassport() {
    //   let { current, last } = this.selectedModel.revisions
    //   current = current || last
    //   let curPassport = current?.modelRevisionPassport
    //   let passport = {
    //     ifcName: curPassport?.ifcFileName,
    //     ifc: Math.round((curPassport?.ifcFileSize + Number.EPSILON) * 100) / 100,
    //     xkt: Math.round((curPassport?.xktFileSize + Number.EPSILON) * 100) / 100,
    //     convex: Math.round((curPassport?.convexHullFileSize + Number.EPSILON) * 100) / 100,
    //     countAtr: curPassport?.countAttributes,
    //     countElements: curPassport?.countElements,
    //     countTrianglesOrig: curPassport?.countTrianglesOriginal,
    //     countTrianglesConvex: curPassport?.countTrianglesConvexHull,
    //     cadProgram: curPassport?.cadProgram,
    //     cadAuthor: curPassport?.cadAuthor,
    //     cadShema: curPassport?.cadShema,
    //     cadCreateDate: curPassport?.cadCreateDate,
    //   }
    //   if (passport.ifc || passport.xkt || passport.convex || passport.countAtr || passport.countTrianglesOrig || passport.countTrianglesConvex ||
    //     passport.cadProgram || passport.cadAuthor || passport.cadShema || passport.cadCreateDate || passport.countElements || passport.ifcName) {
    //     return passport
    //   }
    //   return null
    // },

    currentRevisionPassport() {
      let current = this.selectedModel.revision
      let pasp = current?.modelRevisionPassport
      if(pasp) pasp.expparser = current?.expparser
      return pasp
    },

    passportOffset() {
      let current = this.selectedModel.revision
      let offset = {
        offsetx:  current.tmpOffsetX,
        offsety:  current.tmpOffsetY,
        offsetz:  current.tmpOffsetZ,
      }
      if (current.tmpOffset) {
        return offset;
      }
      return offset
    },

    softInfo () {
      let current = this.selectedModel.revision
      let info = {
        program: current.cadProgram,
        author: current.cadAuthor,
        createDate: current.cadCreateDate
      }
      if(info.program || info.createDate || info.author) {
        return info
      }
      return null
    },

    teammateReady () {
      return this.selectedUser
    },

    

    teammateMenu () {
      if ( !this.selMember ) return null

      let { currentRole, profile, orgGroup } = this.selMember

      let list = [ { title: this.$t('rights.header.name'),  action: () =>  this.dialog.showRule = true } ]

      if (this.isPro && this.hasTaskCreate) {
        list.push( { title: this.$t('module.task.create'), action: this.showDialogAddTask, disabled: profile && profile.archive } )
      }

      let memberRole = false
      let memberDel = false
      let currentModel = false

      if (profile?.uuid && profile.uuid !== this.user.uuid) {
        memberRole = true
        memberDel = true
        // if (roles?.find(r => currentRole === r.uuid && r.model.uuid === this.selectedModel.uuid))
      }

      if (orgGroup?.employees ) {
        if (!orgGroup.employees.find(empl => empl.profile.uuid === this.user.uuid) ) {
          memberRole = true
          memberDel = true
          // if (orgRoles?.find(r => currentRole === r.uuid && r.model.uuid === this.selectedModel.uuid)) 
        } 
      } 

      let role = this.selMember.roles?.find(role => role.uuid == this.selMember.currentRole)
      role = role ? role : this.selMember.orgRoles?.find(role => role.uuid == this.selMember.currentRole)
      if(role) {
        if(role.model.uuid == this.selectedModel.uuid){
          currentModel = true
        }
      }

      if (this.meOwner || (memberRole && this.hasTeamEdit && this.iCanManageRolesOnModelEdit()?.length) ) {
        list.push( { title: this.$t('section.structure.model.memberRole'), action: this.showRolesDialog, disabled: profile && profile.archive } )
      }
      
      if ((this.meOwner || (memberDel && this.hasTeamDelete && this.iCanManageRolesOnModelDelete().includes(currentRole) ) ) && currentModel) {
        list.push( { title: this.$t('button.delete'), action: this.prepareForRemove } ) 
      }

      return list
    },

    teamTabItems () {
      return [
        { title: this.$t('drawer.users'), value: 0 },
        { title: this.$t('corp.group'), value: 1 },
      ]
    },

    memberItems () {
      let menu = []
      if (this.hasTaskCreate) menu.push({ title: this.$t('module.task.create') })

      return [
        { title: this.$t('profile.default') },
        ...menu,
        { title: this.$t('section.structure.model.memberRole'), action: this.showRolesDialog },
        { title: this.$t('button.delete'), action: this.prepareForRemove },
        { title: this.$t('section.structure.model.reassignMember') },
      ]
    },

    varietyRevision () {
      return [
        { icon: 'file_download', title: this.$t('section.structure.model.form.file'), action: this.showNewRevisionDialog },
        { icon:'cloud', title: 'Nextcloud', action: this.showNextCloud },
        // { icon:'cloud', title: 'Yandex Disk', action: this.showYandexDisk },
        // { icon:'cloud', title: 'Google Drive', action: this.showGoogleDrive }
      ]
    },

    varietyDownloadPointCloud() {
      return [
        { icon: 'file_download', title: this.$t('section.structure.model.form.LAZfile'), action: this.downloadLazFile },
        { icon: 'file_download', title: this.$t('section.structure.model.form.E57file'), action: this.downloadE57File },
      ]
    },

    searchEmailSplit () {
      let mails = []
      this.search.toLowerCase().split(' ').forEach(mail => {
       if (this.rules.email(mail) == true && (!this.foundedUsers || !this.foundedUsers.some(u => u.profile.email == mail)))
         mails.push(mail)
      })
      return mails
    },
    
    addMaterialConfirmDisabled(){
      return this.modelMaterial.file
    },

    rootLevelTitle() {
      let title = this.$t('section.structure.model.parentLevel')
      return this.selectedModel.root == true ? title + this.$t('section.structure.model.on') : title + this.$t('section.structure.model.off')
    },

    revisionWithExtTitle(){
      return this.revisions.map(rev => {
        rev.extTitle = this.$t('section.structure.model.currentRevision', {version: rev.version, date: rev.revisionDate, shemaIFC: rev?.modelRevisionPassport?.cadShema, count: rev?.modelRevisionPassport?.countElements, lastVersion: '', lastDate: ''})
        return rev
      })
    },

    falseShowCompareRevisionsIFC() {
      return !this.compareRevisions.first || !this.compareRevisions.second || (this.compareRevisions.first.uuid === this.compareRevisions.second.uuid)
    },
  },

  methods: {
    ...mapMutations('project', ['setSelectedRevision', 'setTopUnit']),
    ...mapMutations('process', ['resetMonitoredProcesses']),
    ...mapMutations('patches', ['setSelectedPatch']),
    
    ...mapActions('project', ['addMember', 'removeTeammate', 'setStage', 'setQualtiy', 'setAccessible', 'makeRevisionActive', 'loadRevisions', 
      'makeRevisionCurrent','loadColleagues', 'deleteRevision', 'fixRevision', 'unfixRevision', 'setRoot', 'setOpenedRoles', 'updateSettings', 
      'getCompareRevisions', 'updateProject', 'loadSuspectElements', 'patchCopyTeam', 'loadProject', 'removeTeammateRole']),
    ...mapActions('process', ['runRevisionUpload', 'runMaterialUpload', 'runPointCloudUpload']),
    ...mapActions('task', ['addTaskOpenPanel']),
    ...mapActions('roles', ['setRoles']),
    ...mapActions('corp', ['LOAD_ORG_EMPLOYEES']),

    showDialogAnnotation () {
      this.dialog.annotation = true
      this.selectedRevisionAnnotation = this.selectedRevision.annotation
    },

    hideStartCalculateConvexHull() {
      this.dialog.startCalculateConvexHull = false
    },
    
    foundedUsersList(el) {
       if (this) {
        let vm = this
        vm.foundedUsers = []
        if (vm.searchId) clearTimeout(vm.searchId)
        if (el && el.length >= 3 ) {
          vm.nowSearching = true
          vm.searchId = setTimeout(() => {
            projectService.searchProfile(el, vm.selectedModel.uuid).then(data => {
              data.forEach(p => {
                if (this.allUsers || !vm.project.organization || this.corpUsers.includes(p.uuid) && !p.archive) vm.foundedUsers.push({ profile: p })
              })
              vm.nowSearching = false
            })
          }, 300)
        }
      }
    },

    passportElementClass(rev){
      return rev?.modelRevisionPassport?.countElements
    },

    existPassportElements(rev){
      if (rev?.modelRevisionPassport?.countElements)
        return true
      return false
    },

    existPassportCadShema(rev){
      return rev?.modelRevisionPassport?.cadShema || 'IFC Unknown'
    },

    existPassportCountElement(rev){
      return rev?.modelRevisionPassport?.countElements || 0
    },
   
    createTask () {
      if(this.selMember.profile.uuid){
        this.addTaskOpenPanel({project: this.project.uuid, title: this.newTask.title, description: this.newTask.desc, name: this.project.name, responder: { uuid: this.selMember.profile.uuid }, models: [this.selectedModel.uuid] })
      }

      if(this.selMember.orgGroup.uuid){
        this.addTaskOpenPanel({project: this.project.uuid, title: this.newTask.title, description: this.newTask.desc, name: this.project.name, groupResponder: { uuid: this.selMember.orgGroup.uuid }, models: [this.selectedModel.uuid] })
      }
      this.newTask.title = ''
      this.newTask.desc = ''
    },

    showRoles(r){
      if(!this.membersWithUnicRoles.find(t => !!t.roles.find(s => s.uuid === r.uuid))){
        return false
      }
      return true
    },

    showOrgRoles(r){
      if(!this.membersWithUnicRoles.find(t => !!t.orgRoles.find(s => s.uuid === r.uuid))){
        return false
      }
      return true
    },

    isRevisionOwner (curRevision) {
      if (curRevision.author.uuid == this.user.uuid) return true
      return false
    },

    chooseRevision (rev) {
      // console.log('REV update', rev.state)
      if (rev.state.name === 'READY' &&  (rev.countElements > 0 || this.existPassportCountElement(rev) > 0)) {
        this.makeRevisionActive({ modelUuid: this.selectedModel.uuid, rev: rev })
        // this.makeRevisionCurrent({ modelUuid: this.selectedModel.uuid, revisionUuid: rev.uuid })
        this.loadRevisions(this.selectedModel.uuid)
        this.checkExistConvexHull(rev)
        this.dialog.revisions = false
      }
    },

    prepareMaterial () {
      this.runMaterialUpload({ ...this.modelMaterial, modelUuid: this.selectedModel.uuid }).then(() => {
        this.modelMaterial = {
          model: null,
          file: null,
          title: '',
          discribe: '',
          type: 0
        }
        api.materials.getModelMaterials(this.selectedModel.uuid).then((data)=>{
          this.selectedModelMaterials = data
        })
      })
    },

    clearMaterialForms(){
      this.modelMaterial = {
        model: null,
        file: null,
        title: '',
        discribe: '',
        type: 0
      }
    },

    deleteMaterial(material){
      api.materials.deleteMaterial( this.selectedModel.uuid, material.uuid ).then(()=>{
        api.materials.getModelMaterials(this.selectedModel.uuid).then((data)=>{
          this.selectedModelMaterials = data
        })
      })
    },

    confirmDeleteMaterial(material){
      this.dialog.materialToDelete = material
      this.dialog.deleteMaterial = true
    },

    downloadMaterial (file) {
      if(this.hasModelLoadAttachment && this.meOfSelectedModel) {
        window.open(SourcePath.material(file.uuid + "/model/" + this.selectedModel.uuid) )
      }
    },

    // showMaterial (file) {
    //   window.open(SourcePath.material(file.uuid), '_blank')
    // },

    prepareRevision () {
      this.setTopUnit('process')
      
      let params = {}
      if (this.model) 
        params = {model: this.model.title}
      this.$metrika.reachGoal('NEW_REVISION', params)

      this.runRevisionUpload({ ...this.newRevision, modelUuid: this.selectedModel.uuid }).then(() => {
      })
    },

    prepareProjectCloud () {
      this.setTopUnit('process')
      // TODO: переделать счетчик облаков точек
      // XeokitMediator.lasLoader.fire("uploadPointCloud")
      // let params = {}
      // if (this.model) 
      //   params = {model: this.model.uuid}
      // this.$metrika.reachGoal('NEW_REVISION', params)
      this.newPointCloud.modelUuid = this.selectedModel.uuid
      this.runPointCloudUpload(this.newPointCloud).then(() => {
      })
    },

    selectInvite (t) {
      this.selMember = { invite: true, profile: { name : t} }
      this.$refs.roles.show(t)
    },

    showListRoles () {
      this.$refs.rolesInvite.show()
    },

    selectProfile (t) {
      if (t.profile.uuid !== this.user.uuid) {
        this.selMember = { profile: t.profile }
        this.$refs.roles.show( {teammate: t, actionAdd: true } )
      }
    },

    selectGroup (g) {
      this.selGroup = { group: g }
      this.$refs.groupRoles.show({teammate: g, actionAdd: true })
    },

    showDialogAddTask () {
      this.dialog.task = true
    },

    showDialogAddMember () {
      if( this.project.org ) this.LOAD_ORG_EMPLOYEES(this.project.org)
      this.loadColleagues()
      this.dialog.team = true
      this.search = ''
      this.selectedUser = null
    },

    showDialogCopyTeam () {
      this.dialog.copyTeam = true
    },

    save() {
      let targetModel

      const item = this.flatlist.find(item => item.title === this.choosedInSelect);
      if (item) {
        targetModel = item.uuid;
      }

      this.$store.dispatch('project/patchCopyTeam', {currentModelUuid: this.selectedModel.uuid, targetModelUuid: targetModel, deleteTeamFlag: this.decouplingModel})
      this.dialog.copyTeam = false
    },

    showDialogAddMaterials () {
      if(this.hasModelDownloadAttachment && this.meOfSelectedModel){
        this.dialog.materials = true
      }
    },

    showTeammateDialog (t) {
      if(t.profile?.uuid) {
        this.selMember = t
        this.dialog.profile = true
      }
      else if (t.orgGroup?.uuid) {
        this.selMember = t
        this.dialog.showOrgGroup = true
      }
    },

    filePath (t) {
      return SourcePath.avatar(t.profile.avatar)
    },

    avatar (t) {
      if (t?.profile?.name) {
        return t.profile.name[0].toUpperCase()
      } else if(t?.orgGroup?.title) {
        return t.orgGroup.title[0].toUpperCase()
      } else 
        return ''
    },

    showUserWithThisRole(r) {
      r.show = !r.show
      let openedRoles=[]
      openedRoles.push(...this.roles.filter(role => role.show).map(role => role.uuid))
      openedRoles.push(...this.orgRoles.filter(role => role.show).map(role => role.uuid))
      this.setOpenedRoles(openedRoles) 
    },


    showTeammateMenu (t, r) {
      this.selMember = Object.assign({}, t)
      this.selMember.currentRole = r.uuid
      this.$refs.teammateMenu.show(event)
      this.formRole = JSON.parse(JSON.stringify(r))
      this.formRole.roles = JSON.parse(JSON.stringify(t.roles))
      this.formRole.orgRoles = JSON.parse(JSON.stringify(t.orgRoles))
    },

    showRolesDialog () {
      this.$refs.roles.show(this.selMember)
    },

    showRevisionsDialog () {
      this.dialog.revisions = true
      this.loadRevisions(this.selectedModel.uuid)
    },

    deletePerson() {
      this.removeTeammateRole({ teammate: this.selMember, modelUuid: this.selectedModel.uuid}).then(() => {
        this.dialog.deletePerson = false
      })
    },

    // Original удаление участника с модели
    // deletePerson() {
    //   this.dialog.deletePerson = false
    //   this.removeTeammate({ memberUuid: this.selMember.uuid, modelUuid: this.selectedModel.uuid})
    //   .then(() => {
    //     let ind = this.inviteList.indexOf(this.selMember.profile.uuid)
    //     if( ind >= 0) this.inviteList.splice(ind, 1)
    //     this.selMember = null
    //     this.dialog.deletePerson = false
  
    //   })
    // },
    
    prepareForRemove () {
      this.dialog.deletePerson = true
    },

    confirmRevisionAnnotation () {
      this.selectedRevision.annotation = this.selectedRevisionAnnotation
      projectService.addRevisionAnnotation(this.selectedRevision.uuid, this.selectedRevisionAnnotation).then(data => console.log(data))
    },

    showVarietyAddRevision () {
      if (this.dialog.revisions) this.dialog.revisions = false
      this.dialog.varietyAddRevision = true
    },

    showVarietyAddPointCloud () {
      if (this.dialog.addPointCloud) this.dialog.addPointCloud = false
      this.dialog.addPointCloud = true
    },

    showNextCloud () {
      this.dialog.varietyAddRevision = false
      this.dialog.nextCloud = true
    },
    showYandexDisk() {
      this.dialog.varietyAddRevision = false
      this.dialog.yandexDisk = true
    },
    showGoogleDrive() {
      this.dialog.varietyAddRevision = false
      this.dialog.googleDrive = true
    },

    downloadLazFile() {
      window.open(SourcePath.downloadLAZ(this.selectedModel.pointCloud.uuid), '_blank')
    },

    downloadE57File() {
      fetch( SourcePath.downloadE57( this.selectedModel.pointCloud.uuid ), { method: 'HEAD' } ).then( response => {
        response.ok ? window.open(SourcePath.downloadE57(this.selectedModel.pointCloud.uuid), '_blank') : AlertService.info({ info: this.$t('section.structure.model.notFoundE57') })
      })
    },

    showNewRevisionDialog () {
      this.dialog.addRevision = true
      this.newRevision.file = null
      this.newRevision.link = null
      this.newRevision.annotation = ""
      this.newRevision.expparser = false

      if (this.selectedRevision) {
        this.newRevision.offsetX = this.selectedRevision.offsetX;
        this.newRevision.offsetY = this.selectedRevision.offsetY;
        this.newRevision.offsetZ = this.selectedRevision.offsetZ;
        this.newRevision.eulerX = this.selectedRevision.eulerX;
        this.newRevision.eulerY = this.selectedRevision.eulerY;
        this.newRevision.eulerZ = this.selectedRevision.eulerZ;
      } else {
        this.newRevision.offsetX = 0;
        this.newRevision.offsetY = 0;
        this.newRevision.offsetZ = 0;
        this.newRevision.eulerX = 0;
        this.newRevision.eulerY = 0;
        this.newRevision.eulerZ = 0;
      }
    },

    downloadRevisionIFCWA () {
      window.open(SourcePath.ifcWithAttr(this.selectedRevision.uuid), '_blank')
    },

    downloadRevisionIFC () {
      window.open(SourcePath.file(this.selectedRevision.file), '_blank')
      this.dialog.downloadRevisionIFCDialog = false
    },

    downloadRevis  () {
      this.dialog.downloadRevisionIFCDialog = true
    },

    openDownloadLas () {
      this.dialog.downloadLasDialog = true
    },

    downloadRev() {
      // return console.log(this.radios)
      if(this.radios == 0) return this.downloadRevisionIFC()
      if(this.radios == 1) return this.downloadRevisionIFCWA()
      if(this.radios == 2) return this.downloadRevisionIFC(), this.downloadRevisionIFCWA()
    },

    downloadLas () {
      return this.downloadLasModel()
    },

    compareRevisionsIFC () {
      let rev = this.getRevision
      rev.extTitle = this.$t('section.structure.model.currentRevision', {version: rev.version, date: rev.revisionDate, shemaIFC: rev?.modelRevisionPassport?.cadShema, count: rev?.modelRevisionPassport?.countElements, lastVersion: '', lastDate: ''})
      this.compareRevisions.first = rev
      
      this.dialog.compareRevisions = true
      this.loadRevisions(this.selectedModel.uuid)
    },

    showCompareRevisionsIFC () {
      this.compareRevisionsLoading = true

      this.getCompareRevisions(this.compareRevisions).then(() => {
        // Прячем все модели
        XeokitMediator.Models.setModelsVisible(XeokitMediator.Models.modelIds, false)
        XeokitMediator.Models.setModelsVisible([this.selectedRevision.uuid], true)

        RevisionLoader.loadRevision(this.compareRevisions.second).then(() => {
          this.compareRevisionsLoading = false
          
          let revFirst = this.compareRevisions.details[0].slice
          let revSecond = this.compareRevisions.details[1].slice

          // Получаем список уникальных объектов из 1-ого списка, которых нет во 2-ом  по ifcGlobalId
          let uniqGlobalIdsFirst  = this.compareRevisions.checked.new ? _.differenceBy(revFirst, revSecond, "ifcGlobalId").map(el => el.globalId) : []
          let uniqGlobalIdsSecond = this.compareRevisions.checked.del ? _.differenceBy(revSecond, revFirst, "ifcGlobalId").map(el => el.globalId) : []

          XeokitMediator.ElementsSettings.setElementsColorized(uniqGlobalIdsFirst.concat(this.compareRevisions.movedColor), hexToRgb(this.compareRevisions.firstColor))
          XeokitMediator.ElementsSettings.setElementsColorized(uniqGlobalIdsSecond.concat(this.compareRevisions.movedColor), hexToRgb(this.compareRevisions.secondColor))  
        })
        
      })
      this.dialog.compareRevisions = false
    },

    intimacy() {
      let model = Object.values(this.projectSettings.workModel).find(model => model.revisionUuid == this.selectedRevision.uuid)
      if (model) {
        if ( model.switchon ) {
          const xktModel = XeokitMediator.viewer.scene.models[this.selectedRevision.uuid]
          XeokitMediator.CameraFlight.cameraFlyToModel(xktModel)
        } 
        else {
          AlertService.warning({
            info: this.$t("viewer.tools.zoomWarn")
          })
        }
      }
    },

    clearUnselectedModelsViewMethod() {
      this.unselectedModelsViewMethod.view = false
      this.unselectedModelsViewMethod.xray = false
      this.unselectedModelsViewMethod.hide = false
      this.unselectedModelsViewMethod.delete = false
    },

    makeRevisions(visible, xrayed) {
      let excluded = ""
      if (this.selectedModel?.pointCloud) {
        excluded = this.selectedModel.pointCloud.uuid
      } else {
        excluded = this.selectedRevision.uuid
      }

      const modelIds = XeokitMediator.Models.modelIds.filter(modelId => modelId !== excluded)
      if (xrayed) XeokitMediator.Models.setModelsPickable(modelIds, false)
      else XeokitMediator.Models.setModelsPickable(modelIds, true)
      XeokitMediator.Models.setModelsXrayed(modelIds, xrayed)
      XeokitMediator.Models.setModelsVisible(modelIds, visible)
      
      // const scene = XeokitMediator.viewer.scene

      // scene.modelIds.forEach(modelId => {
      //   if (modelId === this.selectedRevision.uuid) return

      //   scene.models[modelId].visible = visible
      //   scene.models[modelId].xrayed = xrayed
      //   scene.models[modelId].pickable = !xrayed

      //   scene.models[modelId].xrayMaterial.edgeAlpha = 0.1
      //   scene.models[modelId].xrayMaterial.fillAlpha = 0.1
      // })
    },

    hideUnselectedModels() {
      this.makeRevisions(false, false)
      // this.$root.$emit('showOnlySelectedRevision')
      this.clearUnselectedModelsViewMethod()
      this.unselectedModelsViewMethod.hide = true
    },

    showUnselectedModels() {
      this.makeRevisions(true, false)
      // this.$root.$emit('reloadModel')
      this.clearUnselectedModelsViewMethod()
      this.unselectedModelsViewMethod.view = true
    },

    xrayUnselectedModels() {
      this.makeRevisions(true, true)
      // this.$root.$emit('showOnlySelectedRevision', { visible: true, xrayed: true, pickable: false })
      this.clearUnselectedModelsViewMethod()
      this.unselectedModelsViewMethod.xray = true
    },
    
    deletePointCloud() {
      onClickedDelete()
      XeokitMediator.lasLoader.fire('deletePointCloud') 
      api.pointCloud.deletePointCloud(this.selectedModel.pointCloud.uuid).then( this.loadProject( this.projectUuid ) )
      this.clearUnselectedModelsViewMethod()
      this.unselectedModelsViewMethod.delete = true
    },

    lighten3() {
      if (localStorage.getItem("themeName") === "grey") return this.$vuetify.theme.themes.dark.surface.base
      return this.$vuetify.theme.themes.dark.surface.lighten3
    }, 
    showCimDialog() {
      this.dialog.editingCim = true
    }, 
    onEditingCim() {
      this.dialog.editingCim = false
    },

    alreadyInvite (uuid) {
      return this.inviteList.indexOf(uuid) >= 0
    },

    afterInvite (event, t) {
      this.inviteList.push(t)
    },

    realDeleteRevision(){
      this.deleteRevision(this.selectedWorkRevison);
      this.dialog.deleteRevision = false;
    },

    preDeleteRevision(revUuid) {
      this.selectedWorkRevison = revUuid;
      this.dialog.deleteRevision = true;
    },

    realGeometryRevision(){
      projectService.regeometryRevision(this.selectedWorkRevison, this.expparser)
      this.dialog.regeometryRevision = false;
      this.dialog.revisions = false
    },

    regeometryRevision(revUuid){
      let rev = this.revisions.find(r => r.uuid == revUuid)
      this.expparser = rev?.expparser || false
      this.selectedWorkRevison = revUuid;
      if (!rev.fixed) this.dialog.regeometryRevision = true;
    },

    exchangeFiles() {
      if (this.existConvexHull) {
        let model = this.projectSettings.workModel[this.selectedModel.uuid]
        if (model) {
          model.convexHull = !this.activeConvexHull
          this.activeConvexHull = !this.activeConvexHull
          
          XeokitMediator.viewer.scene.models[this.selectedRevision.uuid]?.destroy()
          this.$store.dispatch('project/updateSettings', this.projectSettings)

          // XeokitMediator.reload(this.selectedRevision, this.projectSettings)
          // projectService.updateSettings(this.projectSettings)
        } else 
          this.existConvexHull = false
      } else {
        projectService.generateConvexHull(this.selectedRevision.uuid).then(() =>
          this.dialog.startCalculateConvexHull = true)
      }
    },

    checkExistConvexHull(rev) {
      let boolExist = false
      let boolActive = false

      if (rev?.convexHullFile) {
        boolExist = true

        let activeModel = this.projectSettings.workModel[this.selectedModel.uuid]?.convexHull
        switch (activeModel) {
          case true: 
            boolActive = true;
            break;
          case false:
            break;
          default:
            boolExist = false;
            break; 
        }
      }

      this.existConvexHull = boolExist
      this.activeConvexHull = boolActive
    }, 

    teamTabSelect(t) {
      this.teamTab = t.value
    }, 

    memberName(t) {
      return t.profile?.name || t.orgGroup?.title || ''
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    restoreModels() {
      XeokitMediator.Models.restoreModels()
      XeokitMediator.destroyUnnecessaryModels()
    },

    showPointCloud() {
      this.pointCloudLoadInProgress = true
    
      let pointCloud = XeokitMediator.loadPointCloud(this.selectedModel)
      
      pointCloud.then(cloud => {
        
        cloud.on("loaded", () => {
          this.showedPointCloud = true
          this.pointCloudLoadInProgress = false
          // XeokitMediator.lasLoader.fire("pointCloudLoadingDone")
        })
      })
    },

    hidePointCloud() {
      XeokitMediator.destroyPointCloud(this.selectedModel.pointCloud.filename)
      this.showedPointCloud = false
    },

    showButtonLinkInviteToProject() {
      return window.settings.showButtonLinkInviteToProject ?? false
    },
  },

  filters: {
    stateTitle: (state) => state.title || state
  }
}
</script>

<style lang="scss" scoped>
.allUsers {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}
.revision {
  border: 1px solid #C4C4C4;
  border-radius: 5px;
}
.pb-btn {
  padding-bottom: 7px;
}
.revisions-list {
  max-height: 370px;
  padding-right: 8px;
}
.loaded-revisions {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
}
.team {
  background-color: #FFFFFF;
  border-radius: 5px;
  padding: 8px;
}

.dict-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none;
}
.dicts-button {
  display: block;
  font: normal 12px/24px $roboto;
  text-transform: none;
  // margin-top: 4px;
}
.role {
  margin: 0px 16px 0px 32px;
  font: normal 10px/24px $roboto;
  color: #505254;
}
.roles {
  cursor: pointer;
  font: normal 14px/24px $roboto;
  color: #3B93AF;
}
.u-info {
  display: inline-block;
  margin-left: 8px;
  vertical-align: top;
  width: calc(100% - 64px);
}
.p-info {
  // display: flex;
  // color: #3B93AF;
  margin-left: 2px;
  color: #505254;
  font: normal 10px/24px $roboto;
  vertical-align: top;
  // justify-content: flex-end;
}
.teammate {
  max-width: calc(100% - 10px);
  font: normal 12px/24px $roboto;
  color: #505254;
  margin-left: 8px;
  margin-top: 8px;
  vertical-align: top;
}
.m-founded-users::v-deep textarea {
  max-height: calc(50vh - 116px);
  overflow-x: hidden !important;
}

.current-revision {
  background-color: rgb(223, 246, 253);
}

.model-revision_title {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #303030;
}

.model-revision__status_text {
  font-weight: 400;
  font-size: 12px; 
  line-height: 18px;
  text-transform: lowercase;
}

.model-revision-color-disabled {
  color: #DBDBDB !important;
}

.user-menu {
  // position: absolute;
  padding-bottom: 15px;
}
.pointer {
  cursor: pointer;
}
</style>

<style>
.dict-button.v-btn {
  justify-content: flex-start;
}
.v-messages {
  min-height: 0px;
}
.role > .v-label {
  font: normal 10px/24px 'Roboto', sans-serif;
  color: #505254 !important;
}
.material{
  display: flex;
  padding: 5px 8px 0px 8px;
  font-size: 13px;
  font-weight: 300;
  color: black;
}
.material-title{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.material-data{
  width: 80%;
}
.material-discribe{
  white-space: pre-line;
  width: 250px;
}

.model-select-view-tab{
  border-radius: 5px;
}
.model-select-view-tab .v-tab {
  min-width: 50px; 
  border-radius: 5px;
}
.model-select-view-tab .v-slide-group__wrapper{
  border-radius: 5px;
}
.sizeW {
  width: 320px !important;
}
</style>
