<template lang="pug">
  app-panel(ident="smetapannel" left v-bind="$attrs")

    .d-flex.align-center
      app-menu(:menu="classsificatorMenu" basic color="var(--v-primary-darken2)")
      v-tabs(v-model="tab" height="36px" background-color="transparent" )
        v-tabs-slider( color="var(--v-primary-darken2)" )
        v-tab {{$t("section.classification.menuClassification.classifier")}}
        v-tab {{$t("section.classification.menuClassification.unallocatedElements")}}
        v-tab(v-if="hasClassificatorViewUniversalAttr || hasClassificatorControlUniversalAttr") {{ $t('section.classification.attribute.universalAttributes') }}
      app-icon-button( icon="mdi-close" @click="handleClose" )

    v-row.column-height(no-gutters)
      v-col.h100p(cols=6)
        app-panel( padding="0px")
          app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow-x: hidden" )
            app-floating-panel( :title="tab==2 ? $t('section.classification.attribute.universalAttributes') : classificatorTranslate" bodyMinHeight="150" overflowBody="hidden" 
              :settings="floatingPanels('CLASSIFICATION_CLASSIFICATION')")
              .base-scroll-body
                v-tabs-items(v-model="tab" light style="height: 100%")
                  v-tab-item.py-1(eager style="height: 100%")
                    v-progress-linear(v-if="projectClassificatorsLoading || isClassificatorUpdating" indeterminate)

                    v-row(v-if="!projectClassificatorsLoading && projectClassificators.length == 0 ")
                      v-col.mx-1(v-if="hasClassificatorCreate")
                        app-button-add(width="100%" :text="$t('section.classification.menuClassification.create')" @click="showClassificatorControlAdd()")
                      v-col.mx-1(v-if="hasClassificatorImportExport")
                        app-button-add(width="100%" :text="$t('section.classification.menuClassification.import')" test-id="classifier-ctrl-import" @click="showClassificatorControlImport()")
                    .classificator-controls-groupping.px-2(v-if="projectClassificators.length > 0")

                      app-select.dash-border-select.dash-size.mr-1.mt-1(
                        v-model="projectClassificatorSelected"
                        :items="projectClassificators"
                        item-text="title"
                        item-value="uuid"
                        :label="$t('section.classification.menuClassification.selectClassifier')"
                        color="var(--v-accent-base)"
                        solo
                        clearable
                        test-id="select-classifier"
                        :disabled="isTreeInPreparation"
                        :menu-props="{ light: true, maxWidth:'fit-content', contentClass: 'menu-scroll' }" )

                      app-text-field.suggest-input.ml-1.mt-1( v-if="!isSmetaUpdatePanel" v-model="serarchTextTrimed" :label="$t('section.classification.menuClassification.codeSearch')" hide-details outlined dense clearable test-id="smeta-code-input" )
                      app-text-field.suggest-input.ml-1.mt-1( v-else v-model="searchByCode" :label="$t('section.classification.menuClassification.codeSearch')" hide-details outlined dense clearable test-id="smeta-merge-code-input" )

                    div(v-if="isTreeInPreparation")
                      v-skeleton-loader(type="list-item-two-line" light)
                      v-progress-linear(indeterminate color="accent")
                    div(v-else style="height: 100%")
                      div(v-if="this.treePrepareError") {{ this.treePrepareError }}
                      smeta-tree( v-if="projectClassificatorSelected && !isSmetaUpdatePanel" :scroll-to="scrollToItemUuid" @editItem="editItem" )
                      smeta-merged-tree( v-if="isSmetaUpdatePanel" :classificatorUuid="projectClassificatorSelected" @closeMerge="closeMerge" )

                  v-tab-item(eager style="height: 100%;")
                    v-progress-linear(v-if="nonDistributedElementsLoading" indeterminate color="accent")
                    axis-block(ref="axisBlock" @oneditdialogclose="switchToNonDistinguished")

                  v-tab-item( eager style="height: 100%;" )
                    project-dict-classification

            app-floating-panel( v-if="element" :title="$t('section.element', {name: element.name})" bodyMinHeight="150" overflowBody="hidden" 
              :settings="floatingPanels('CLASSIFICATION_ELEMENT_DETAILS')")
              element-details.py-0( :element="element")

      v-col.h100p( v-if="tab!==2" cols=6)
        rule-edit-form
      v-col.h100p( v-if="tab==2" cols=6)
        attribute-setting-form(@scrollTo="scrollTo($event)")

    classificator-controll(ref="classificatorControll" v-model="dialog.classificatorControl")

    grand-smeta(v-model="dialog.grandSmeta")

    share-smeta-to-org-dialog( v-model="dialog.shareToOrg" ) 

    app-dialog-confirm( v-if="selectedProjectDict" v-model="dialog.deleteDict" delete @confirm="onDeletingDict") 
      | {{ $t('section.classification.attribute.deleteDict') }} "{{ projectDicts.find(dict => dict.uuid === selectedProjectDict).title }}"?         

    add-project-dict-dialog( v-model="dialog.addProjectDict" @createDict="createDict($event)")       
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { api } from "@/api"
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import AxisBlock from '@/components/project/panel/left/components/axisSection/AxisBlock.vue'
import { AxisDataType } from '@models/axis'
import ElementDetails from '../components/element/Details.vue'
import SmetaTree from './components/tree/SmetaTree.vue'
import RuleEditForm from './components/rule/RuleEditForm.vue'
import ClassificatorControll from './components/ClassificatorControll/ClassificatorControll.vue'
import GrandSmeta from './components/GrandSmeta/GrandSmeta.vue'
import AttributeSettingForm from './components/attribute/AttributeSettingForm.vue'
import ProjectDictClassification from './components/attribute/ProjectDictClassification.vue'
import ShareSmetaToOrgDialog from './components/OrgShare/ShareSmetaToOrgDialog.vue'
import AddProjectDictDialog from './components/attribute/AddProjectDictDialog.vue'
import SmetaMergedTree from './components/tree/SmetaMergedTree.vue'

export default {
  name:'smeta-pannel',
  
  components: {
    AxisBlock,
    ElementDetails,
    SmetaTree,
    RuleEditForm,
    ClassificatorControll,
    GrandSmeta,
    ShareSmetaToOrgDialog,
    AttributeSettingForm,
    ProjectDictClassification,
    AddProjectDictDialog,
    SmetaMergedTree
  },
  
  props: {
  },

  data() {
    return {
      tab: 0,
      treePrepareError: null,

      nonDistributedElementsLoading: false,
      nonDistributedElements: [],
      isSmetaUpdatePanel: false,

      dialog:{
        classificatorControl: false,
        grandSmeta:false,
        shareToOrg: false,
        deleteDict: false,
        addProjectDict: false,
      },

      timer: null,
      smetaMergeTimer: null,
      scrollToItemUuid: null,
       
    }
  },

  mounted() {
    this.init()
    this.LOAD_PLUGINS(this.project.uuid)
    this.scrollToItemUuid = this.scrollToItem
  },

  beforeDestroy() {
    this.clearColorize()
  },

  computed: {
    ...mapState('project', ['project', 'activeGlobalTab', 'activeIotAdmin','projectSettings']),
    ...mapState('axis', ['selectedAxisGroup']),


    ...mapState('smeta', ['projectClassificators', 'organizationProjects', 'projectClassificatorsLoading', 'projectClassificatorUuid', 'isTreeInPreparation','nodeTree','openedNodes','copiedRule','searchText', 'scrollToItem']),
    ...mapGetters('smeta', ['getTopItemCount','treeAllVisibleNodes','getProjectClassificator']),

    ...mapGetters('axis', ['fixedAxisGroup', 'customAxisGroup', 'selectedAxis']),
    ...mapGetters('axis/tree', ['element']),

    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    ...mapGetters('project', ['flatlist', 'projectUuid']),
    ...mapState('projectDict', ['selectedProjectDict', 'projectDicts']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate', 'hasClassificatorImportExport', 'hasClassificatorCreateRule',  'hasClassificatorShare', 'hasClassificatorViewUniversalAttr', 'hasClassificatorControlUniversalAttr']),

    ...mapState('smetaMerge', ['isClassificatorUpdating', 'smetaMergeSearchText']),
    canBeShared(){
      if (this.projectClassificatorSelected) {
        let projClass = this.getProjectClassificator(this.projectClassificatorSelected)
        let orgjUuid = projClass?.projectUuid
        return orgjUuid == this.project.uuid ? true : false
      }
      return  false
    },

    classificatorTranslate() {
      if(this.isSmetaUpdatePanel) return "Обновление классификатора"
      return this.$t('section.classification.menuClassification.classifier')
    },

    classsificatorMenu () {
      let act = []

      act.push({ title: this.$t("section.classification.menuClassification.management"), action: this.showClassificatorControl })
      act.push({ divider: true })

      if (this.projectClassificatorUuid && this.hasClassificatorShare && this.canBeShared && this.project.organization) {
        act.push({ title: this.$t('section.classification.menuClassification.shareOrganization') + this.project.organization?.title, action: this.showShareToOrg })
        act.push({ divider: true })
      }

      act.push({ title: this.$t("section.classification.menuClassification.addGrouping"), action: this.showGrouping })
      
      if (this.hasClassificatorControlUniversalAttr) {
        act.push({ title: 'section.classification.menuClassification.addAttributeReference', action: this.addDict })
        
        if (this.projectClassificatorUuid) {
          act.push({ divider: true })
          // act.push({ title: this.$t('section.classification.menuClassification.showSmeta'), action: this.showSmeta })
          act.push({ title: 'section.classification.menuClassification.classifyModel', action: () => api.projectDict.classificate({ projectUuid: this.projectUuid, classificatorNodeUuid: null, projectClassificatorUuid: this.projectClassificatorSelected }) })
  
        }
        
        
        if(this.tab == 2 && this.selectedProjectDict) {
          act.push({ title: 'section.classification.menuClassification.deleteAttributeDirectory', action: this.showDeleteDictDialog })
        }
      }
      
      if (this.projectClassificatorUuid && this.hasClassificatorCreateRule) {
        act.push({ divider: true })
        act.push({ title: this.$t('section.classification.menuClassification.checkrules'), action: this.checkRules })
      }

      if (this.projectClassificatorUuid && !this.isSmetaUpdatePanel) {
        act.push({ divider: true })
        act.push({ title: "Обновление классификатора", action: () => this.isSmetaUpdatePanel = !this.isSmetaUpdatePanel })
      }

      if (this.projectClassificatorUuid && this.isSmetaUpdatePanel) {
        act.push({ divider: true })
        act.push({ title: "Вернуться к классификатору", action: () => this.isSmetaUpdatePanel = !this.isSmetaUpdatePanel })
      }

      return act
    },

    projectClassificatorSelected: {
      get () {
        let projectClassificator = this.projectClassificators.filter(item => this.$store.state.project.projectSettings.projectClassificator == item.uuid )
        return projectClassificator.length ? this.$store.state.project.projectSettings.projectClassificator : null
      },
      set (value) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.projectClassificator = value
        this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
      }
    },

    serarchTextTrimed:{
      get(){
        return this.searchText
      },
      set(value){
        clearTimeout(this.timer)
        if (value) {
          this.timer = setTimeout(() => this.setSearchText(value.trim()), 300)
        } else {
          this.setSearchText(null)
        }
        
      }
    },

    searchByCode: {
      get() {
        return this.smetaMergeSearchText
      },
      set(value) {
        clearTimeout(this.smetaMergeTimer)
        if (value) {
          this.smetaMergeTimer = setTimeout(() => this.setSmetaMergeSearchText(value.trim()), 300)
        } else {
          this.setSmetaMergeSearchText(null)
        }
      }
    }
  },

  watch: {
    tab(val){
      if (val == 1) this.loadNonDistributedElements(this.selectedAxis)
      this.selectAxisGroup(null)
    },

    'projectSettings.projectClassificator'(val){
      this.setEditRuleItem(null)
      if (val) {
        this.loadClassificatorNodes()
        this.setProjectClassificatorUuid(this.projectSettings.projectClassificator)
        if (this.project?.organization) {
          this.getOrganizationProjects(this.project.organization.uuid).then(() => {
            this.updateOrganizationProjects()
          })
        }
      } else {
        this.commitSmetaTree([])
        this.nonDistributedElements = []
        this.setProjectClassificatorUuid(null)
      }
    },

    selectedAxisGroup() {
      if(this.selectedAxisGroup != null)
        this.loadNonDistributedElements(this.selectedAxisGroup)
    },

    selectedAxis() {
      if (this.selectedAxis.value !== 5) 
        this.loadNonDistributedElements(this.selectedAxis)
    },

    scrollToItem() {
      this.scrollToItemUuid = this.scrollToItem
    },
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId','prepareForDataType','commitTree']),
    ...mapActions('axis', ['selectAxisGroup']),
    ...mapActions('plugins', ['LOAD_PLUGINS']),
    ...mapMutations('smeta', ['setProjectClassificators','setProjectClassificatorUuid','setNodeTree','setOpenedNodes','setCopiedRule','setEditRuleItem','setSearchText', 'setExpandAfterLoadUuid']),
    ...mapActions('smeta', ['LOAD_PROJECT_CLASSIFICATORS', 'LOAD_TREE', 'commitSmetaTree', 'expandTo', 'getOrganizationProjects', 'updateOrganizationProjects']),
    ...mapActions('projectDict', ['deleteProjectDict', 'createProjectDict']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    ...mapMutations('smetaMerge', ['setSmetaMergeSearchText']),

    init() {
      this.$store.dispatch('axis/initShort')
      this.$store.dispatch('smeta/INIT')
      if (this.project?.organization) {
        this.getOrganizationProjects(this.project.organization.uuid).then(() => {
          this.updateOrganizationProjects()
        })
      }
      this.listProjectClassofocators().then(() => {
        let projClass = this.getProjectClassificator(this.projectClassificatorSelected)
        if (!this.projectSettings.projectClassificator) this.commitSmetaTree([])
  
        if (this.projectClassificatorUuid != this.projectSettings.projectClassificator) {
          this.commitSmetaTree([])
          this.setProjectClassificatorUuid(this.projectSettings.projectClassificator)
        }
        if (this.projectSettings.projectClassificator && (this.getTopItemCount == 0 || !projClass.actual)) {
          this.loadClassificatorNodes()
        }
      }) 
    },

    closeMerge() {
      this.isSmetaUpdatePanel = false
      this.loadClassificatorNodes()
    },

    listProjectClassofocators() {
      return this.LOAD_PROJECT_CLASSIFICATORS(this.project.uuid)
    },

    checkRules(){
      api.smeta.checkrules(this.projectSettings.projectClassificator).then(() => {
        this.loadClassificatorNodes()
      })
    },

    loadClassificatorNodes() {
      this.LOAD_TREE(this.projectSettings.projectClassificator)
    },

    switchToNonDistinguished(){
      this.tab = 1
    },

    loadNonDistributedElements(axis){
      this.prepareForDataType(AxisDataType.COSTING)

      if (this.projectSettings.projectClassificator != null){
        this.nonDistributedElementsLoading = true

        
        api.smeta.loadNonDistributedElements(this.project.uuid, this.projectSettings.projectClassificator,axis).then(data => {
          this.nonDistributedElements = data
          this.nonDistributedElementsLoading = false
          data.forEach(node => {
            node.name = this.flatlist.find(model => model.uuid === node.model)?.title || node.name
          })
          this.commitTree(data)
        })
      } else {
        this.commitTree([])
      }
    },

    showDeleteDictDialog(){
      this.dialog.deleteDict = true
    },

    onDeletingDict() {
      this.deleteProjectDict(this.selectedProjectDict)
    },

    addDict() {
      this.tab = 2
      this.dialog.addProjectDict = true
    },

    createDict(dictName) {
      this.createProjectDict({
        title: dictName,
        projectUuid: this.projectUuid
      })
      this.dialog.addDict = false
    },

    editItem(item){
      this.$refs.classificatorControll.showEditItem(item)
    },

    showClassificatorControl() {
      this.$refs.classificatorControll.show()
    },

    showClassificatorControlAdd(){
      this.$refs.classificatorControll.showAdd()
    },

    showClassificatorControlImport(){
      this.$refs.classificatorControll.showImport()
    },

    showGrouping(){
      this.$refs.axisBlock.showAddingAxisDialog()
    },


    showSmeta(){
      this.dialog.grandSmeta = true
    },

    handleClose() {
      this.setActiveGlobalTab('')
    },


    showShareToOrg(){
      this.dialog.shareToOrg = true
    },

    shareToOrg(){
      api.smeta.sharetoorg(this.projectClassificatorUuid,this.project.org).then(() => {
        this.listProjectClassofocators()
        this.dialog.shareToOrg = false
      })
    },

    clearColorize(){
      XeokitMediator.clearColorizeModel()
    },
    
    async scrollTo(item) {
      this.expandTo(item.uuid)
      this.tab = 0
      await this.$nextTick()
      this.scrollToItemUuid = item.uuid
      setTimeout(() => this.scrollToItemUuid = null, 1000)
      setTimeout(() => this.setExpandAfterLoadUuid(null), 5000)
    }
  }
}
</script>

<style scoped>
.h100p {
  height: 100%;
}

.v-tab {
  opacity: 0.5;
  text-transform: none;
  color: var(--v-primary-darken2) !important;
}
.v-tab--active{
  opacity:1;
}

.column-height{
  height: 100%;
}

#smetapannel {
  max-height: 100%;
  height: 100%;
}

.classificator-controls-groupping{
  display: flex;
  padding-bottom: 8px;
}

::v-deep.dash-border-select.v-text-field--outlined fieldset {
  border-color: var(--v-accent-base);
  border-style: dashed;
  color: #3B93AF;
}

.dash-size {
  height: 42px;
  width: 48%;
  display: inline-block;
}

.suggest-input {
  height: 40px;
  width: 48%;
  display: inline-block;
}

::v-deep.dash-border-select .v-label,
::v-deep.dash-border-select .v-icon,
::v-deep.dash-border-select .v-select__selections,
::v-deep.dash-border-select .v-select__selections .v-select__selection,
::v-deep.dash-border-select .v-input__append-inner  {
  color: #3B93AF;
}
</style>
