<template lang="pug">
  app-section.mb-1.position-relative.rounded-0
    div.unit-action
      v-icon.mr-2(dense color="grey" ) list
      v-icon(dense small color="grey" @click.stop="showDeleteConfirm" ) clear
    v-row(no-gutters)
      v-col(cols=6)
        app-text-field(v-model="field.name" :label="$t('form.formFields.name')" test-id="form-field-name" hide-details @change="onChange")
      v-col(cols=6)
        app-text-field.ml-2(v-model="field.alias" :label="$t('form.formFields.alias')" hide-details test-id="form-field-alias" @change="onChange")
    v-row(no-gutters)
      v-col
        app-select.my-2(v-if="isFieldTypesLoaded" v-model="field.type" :items="namedFieldTypes" :label="$t('form.formFields.type')" item-value="value" item-text="text" test-id="form-select-type" @input="selectType" @change="onChange")
      v-col.ml-2
        v-row(v-if="field.type=='ListField'" no-gutters)
          v-col.d-flex.align-baseline
            app-select.my-2(v-model="field.value" :items="dictionaries" :label="$t('form.formFields.dictionary')" item-text="name" item-value="uuid" test-id="form-select-dictionary")
            v-icon.mb-4(small v-if="field.type=='ListField'" color="grey" @click.stop="showDict" ) edit

        app-text-field.my-2(v-if="field.type=='StringField' || field.type=='TextField'" v-model="field.defaultValue" :label="$t('form.formFields.defaultValue')" hide-details @change="onChange" test-id="form-default-value")
        v-switch( v-if="field.type=='DateField'" v-model="field.extraCheck" color="accent" dense light test-id="form-date-switch" @change="onChange")
          template( v-slot:label)
            app-text {{ $t('form.formFields.time') }}

    app-dialog-confirm(v-model="dialog.delField" @confirm="deleteField(field.uuid)")
      app-text {{ $t('form.formFields.deleteField') }} "{{ field.name }}"?
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import { api } from "@/api"

let ruType = {
  ModelField: "form.formFields.modelField",
  DateField: "form.formFields.dateField",
  ListField: "form.formFields.listField",
  StringField: "form.formFields.stringField",
  TextField: "form.formFields.textField",
  ClassField: "form.formFields.classField",
  AttrField: "form.formFields.attrField"
}

export default {
  name: 'FieldEdit',

  props: {
    field: null,
  },

  data () {
    return {
      dialog: {
        delField: false,
      },
      fieldValues: []
    }
  },

  computed: {
    ...mapGetters('forms', ['form', 'fieldTypes', 'isFieldTypesLoaded', 'dictionaries', 'isDictionariesLoaded']),
    namedFieldTypes() {
      let arr = []
      this.fieldTypes.forEach( t => {
        arr.push({"text": this.$t(ruType[t]), "value":t})
      })
      return arr.sort((a, b) =>  a.text.localeCompare(b.text))
    }
  },

  watch: {
    'field.value' () {
      this.onChange()
    }
  },


  mounted () {
    this.selectType()
  },

  methods: {
    ...mapActions("forms", ['saveField', 'deleteField']),

    onChange() {
      this.field.form = { "uuid" : this.form.uuid }
      this.saveField(this.field)
    },

    selectType() {
      /* this.fieldValues = []
      if(this.field.type) {
        this.field.form = { "uuid" : this.form.uuid }
        api.forms.fieldPrepare(this.field).then(data => {
          this.fieldValues = data
        }) 
      } */
    },

    showDeleteConfirm () {
      this.dialog.delField = true
    },

    showDict () {
      this.$emit("showDict", this.field)
    }
  }
}
</script>

<style scoped>
.unit-action {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
  margin-top: 2px;
  cursor: pointer;
}
.position-relative {
  position: relative;
}

</style>
