<template lang="pug">
.full.relative
  canvas#myCanvas(@mouseleave='handleCanvasLeave()' @mouseenter='handleCanvasEnter()')
  
  div#camera-pivot-marker.camera-pivot-marker
  div#spinner_placeholder
  
  .measurements(id='measurementsScreen' style='background: transparent;')
</template>

<script>
import { mapGetters } from 'vuex'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  data() {
    return {
      mySettingQualityAntialias: false,
      mySettingQualitySpeedRender: false,
    }
  },

  computed: {
    ...mapGetters('authUser', ['modelQuality']),

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    }
  },

  mounted() {
    // Выделение элементов на сцене переделано 28.03.2024
    // XeokitMediator.ElementsSelection.onSelectByShift()

    let modelQuality
    if (this.modelQuality) {
      modelQuality = JSON.parse(this.modelQuality.value)

      this.mySettingQualityAntialias = modelQuality.antialias
      this.mySettingQualitySpeedRender = modelQuality.speedRender
    }

    let viewer = XeokitMediator.installViewer({
      canvasId: "myCanvas",
      antialias: this.mySettingQualityAntialias,  // Сглаживание для улучшения,
      gammaInput: false,
      preserveDrawingBuffer: false,
      dtxEnabled: true
    })
  
    XeokitMediator.ModelFulcrum.init()

    XeokitMediator.viewer.scene.on('modelLoaded', () => {
      // TODO: IfcTypesTool - Нужно скрывать элементы по IFC-типам
    })

    if (modelQuality) {
      XeokitMediator.ModelQuality.setModelQuality({
        sao: modelQuality.sao ?? true,
        edges: modelQuality.edges ?? true,
        armature: modelQuality.armature ?? true,
        antialias: modelQuality.antialias ?? true,
        fastNav: modelQuality.speedRender ?? false,
      })
    }
    viewer.cameraControl.followPointer = false
    //const pivotElement = document.getElementById("camera-pivot-marker")
    //viewer.cameraControl.pivotElement = pivotElement
  },

  methods: {
    handleCanvasLeave() {
      XeokitMediator.FrustumToggleElements.deactivate()
    },

    handleCanvasEnter() {
      XeokitMediator.FrustumToggleElements.activate()
    },
  },

  beforeDestroy() {
    XeokitMediator.ElementsSelection.selectByShiftRemove()
    XeokitMediator.FrustumToggleElements.deactivate()
  }
}
</script>

<style scoped>
.empty {
  text-align: center;
  margin-top: 200px;
}

.full, #myCanvas {
  width: 100%;
  height: 100%;
}

.camera-pivot-marker {
  color: #ffffff;
  position: absolute;
  top: 0px;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  border: 2px solid #ebebeb;
  background: black;
  visibility: hidden;
  box-shadow: 5px 5px 15px 1px #000000;
  z-index: 10000;
  pointer-events: none;
}

.measurements {
  position: absolute;
  top: 0px;
  left: 0px;

  background: transparent;

  width: 100%;
  height: 100%;

  pointer-events: none;
}
</style>