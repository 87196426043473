<template lang='pug'>
div
  div.surface.surface--text.text--lighten-3.elevation-5.rounded(v-for='(sectionPlane, index) in sectionPlanes' :key='sectionPlane.id' :class='{"mt-2": index}')
    div.d-flex.align-center
      app-tooltip(:title="$t('viewer.sectionPlane.showHide')" :openDelay="300" top)
        v-btn(:color='sectionPlane.shownControl ? "accent" : "surface lighten-1"' x-small text @click='toggleSectionPlaneShowControl(sectionPlane)') Создан {{ sectionPlane.createDate.toLocaleTimeString() }}
      
      app-tooltip(:title="$t('viewer.sectionPlane.onOff')" :openDelay="300" top)
        v-switch.ma-0(:input-value='sectionPlane.active' color='accent' dense @change='toggleSectionPlaneActive(sectionPlane)')
      
      app-tooltip(:title="$t('viewer.sectionPlane.flip')" :openDelay="300" top)
        v-btn(text small @click='flipSectionPlane(sectionPlane)')
          v-icon flip

      app-tooltip(:title="$t('viewer.sectionPlane.delete')" :openDelay="300" top)
        v-btn(text small @click='deleteSectionPlane(sectionPlane)')
          v-icon delete
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  name: 'BimSectionPlane',

  computed: {
    sectionPlanes() {
      return XeokitMediator.SectionPlanes.sectionPlanes
    },
  },

  methods: {
    toggleSectionPlaneActive(sectionPlane) {
      const active = sectionPlane.active
      active ? XeokitMediator.SectionPlanes.deactivateSectionPlane(sectionPlane.id) : XeokitMediator.SectionPlanes.activateSectionPlane(sectionPlane.id)
    },

    flipSectionPlane(sectionPlane) {
      XeokitMediator.SectionPlanes.flipSectionPlane(sectionPlane.id)
    },

    deleteSectionPlane(sectionPlane) {
      XeokitMediator.SectionPlanes.deleteSectionPlane(sectionPlane.id)
    },

    toggleSectionPlaneShowControl(sectionPlane) {
      sectionPlane.shownControl ? XeokitMediator.SectionPlanes.hideSectionPlaneControl(sectionPlane.id) : XeokitMediator.SectionPlanes.showSectionPlaneControl(sectionPlane.id)
    },
  },
}
</script>

<style>

</style>