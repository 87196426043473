<template lang='pug'>
div.matching-attributes-form
  div.base-scroll-body
    draggable( :value='matchingAttributes' handle='.drag-handler' @input='updateAttributesPriorities($event)' )
      div.matching-attribute( v-for='(attribute, index) in matchingAttributes' @contextmenu.prevent='showMatchingAttributeMenu($event, attribute)' )
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs}")
            div.attribute( v-bind="attrs" v-on="on" ) {{ attribute.name }}
          div {{ attribute.name }}
        
        v-icon.ml-auto( color='var(--v-primary-base)' :disabled='!hasClassificatorControlUniversalAttr' small @click='showMatchingAttributeMenu($event, index)' ) more_vert
        v-icon.drag-handler.pointer.ml-1( color='var(--v-primary-base)' :disabled='!hasClassificatorControlUniversalAttr' small ) mdi-drag-vertical
    
    app-menu( ref='matching-attribute-menu' :menu='matchingAttributeMenu' )

    app-dialog( v-model='dialog.attributeForm' :confirmDisabled="!attributeFormValid" :confirm-text='$t("button.save")' 
                :header='$t("section.classification.attribute.editAttribute")' @confirm='updateAttribute(attributeForm, shownMenuAttributeIdx)' )
      app-section
        v-form( v-model='attributeFormValid' )
          app-text-field(v-model.trim='attributeForm.name' :rules='[rules.required]' :label='$t("section.classification.attribute.nameModelAttribute")' )

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { $_validate_rules } from '@/common/ValidateRules'

const defaultForm = Object.freeze({
  name: '',
  priority: 0,
})

export default {
  mixins: [$_validate_rules],

  components: {
    draggable,
  },

  props: {
    value: Array,
  },

  data() {
    return {
      attributeForm: Object.assign({}, defaultForm),
      shownMenuAttributeIdx: null,
      
      dialog: {
        attributeForm: false,
      },

      attributeFormValid: false,
    }
  },

  computed: {
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),

    matchingAttributes: {
      get() {
        return this.value.slice(0, this.value.length)
      },

      set(val) {
        this.$emit('input', val)
      }
    },

    matchingAttributeMenu() {
      return [
        { title: this.$t('button.edit'), action: () => this.showAttributeEditDialog() },
        { title: this.$t('button.delete'), action: () => this.deleteAttribute(this.shownMenuAttributeIdx) },
      ]
    },
  },

  methods: {
    deleteAttribute(attributeIdx) {
      this.matchingAttributes.splice(attributeIdx, 1)
      this.fireInput(this.matchingAttributes)
    },

    updateAttribute(attribute, attributeIdx) {
      this.matchingAttributes.splice(attributeIdx, 1, attribute)
      this.fireInput(this.matchingAttributes)
    },

    updateAttributesPriorities(attributes) {
      this.matchingAttributes = attributes.map((attribute, idx) => ({
        ...attribute,
        priority: idx
      }))
    },
    
    showAttributeEditDialog() {
      this.dialog.attributeForm = true
      this.attributeForm = Object.assign({}, this.matchingAttributes[this.shownMenuAttributeIdx])
    },
    
    showMatchingAttributeMenu(event, attributeIdx) {
      if(this.hasClassificatorControlUniversalAttr) this.$refs['matching-attribute-menu'].show(event)
      this.shownMenuAttributeIdx = attributeIdx
    },

    fireInput() {
      this.$emit('input', this.matchingAttributes)
    },
  },
}
</script>

<style scoped>
.matching-attributes-form {
  height: calc(26px * 4);
  /* overflow: auto; */
  border: 1px solid #9e9e9e;
  border-radius: 3px;
  /* overflow-x: hidden; */
}

.matching-attributes-form:hover {
   border: 1px solid var(--v-primary-base);
}

.attribute {
  max-width: calc(100% - 10%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matching-attribute {
  color: var(--v-primary-base);
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  transition: 0.1s;
}

.matching-attribute:hover {
  background: var(--v-primary-lighten5);
}
</style>