<template lang="pug">
v-list
  v-list-item
   v-checkbox( v-model="filter.OK" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Общие - ({{ getMergedStates.OK }})
  v-list-item
    v-checkbox( v-model="filter.INSERT" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Добавления - ({{ getMergedStates.INSERT }})
  v-list-item
    v-checkbox( v-model="filter.CHANGE" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Изменения - ({{ getMergedStates.UPDATE }})
  v-list-item
    v-checkbox( v-model="filter.DELETE" dense @change="setFilter" )
      template( v-slot:label )
        .smeta-tree-filter-list__text Удаления - ({{ getMergedStates.DELETE }})

    //- .d-flex.flex-column.align-start.pl-4.pr-4
    //-   v-checkbox( v-model="filter.OK" :label="`Общие - ${getMergedStates.OK} `" dense @change="setFilter" )
    //-   v-checkbox( v-model="filter.INSERT" :label="`Добавления - ${getMergedStates.INSERT}`" dense @change="setFilter" )
    //-   v-checkbox( v-model="filter.CHANGE" :label="`Изменения - ${getMergedStates.UPDATE}`" dense @change="setFilter" )
    //-   v-checkbox( v-model="filter.DELETE" :label="`Удаления - ${getMergedStates.DELETE}`" dense @change="setFilter" )
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  props: {
    oRules: null,
  },

  data() {
    return {
      filter: {
        DELETE: false,
        INSERT: false,
        CHANGE: false,
        OK: false
      }
    }
  },

  computed: {
    ...mapGetters('smetaMerge', ['getMergedStates'])
  },

  methods: {
    ...mapMutations('smetaMerge', ['setFilters']),

    setFilter() {
      this.setFilters(Object.keys(this.filter).filter(key => this.filter[key]))
    }
  }
}
</script>

<style scoped>
.smeta-tree-filter-list__text {
  color:#3b93af;
  font-size: 10px;
  text-transform: uppercase;
}
</style>