<template lang="pug">
  app-panel.pl-2( padding="0px" )
    app-floating-panels-section( style="height: calc(100% - 14px) !important; overflow: auto; overflow-x: hidden" )
      app-floating-panel( :title="editingWormNodeRule ? editingWormNodeRule.title : $t('section.classification.menuClassification.ruleSelected')" 
      bodyMinHeight="150" ref="wormRuleEditForm" overflowBody="hidden" :settings="floatingPanels('WORM_RULE_EDIT')")
        div.p-relative( style="height: 100%" )
          app-section.mh-49.base-scroll-body
            div(v-if="!editingWormNodeRule") 
              app-text {{$t('section.worm.ruleSelected')}}
            div(v-else-if="editingWormNodeRule && editingWormNodeRule.classificatorFindRuleUuid != null && editingWormNodeRule.classificatorFindRule == null") 
              v-alert(type="error") {{ $t('section.worm.ruleBound')}} {{editingWormNodeRule.title }}
            div(v-else-if='editingWormNodeRule.classificatorFindRule')
              v-form(ref="ruleForm" @submit.prevent="editRule")
                div.mb-5
                  app-text-field(v-model="editingWormNodeRule.title" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]" hide-details @change="ruleChanged" :disabled="!canWorkWithWorm")
                  
                template(v-if="editingWormNodeRule.classificatorFindRule.ruleType.name == 'DEFAULT'")
                  div.mb-5
                    app-text {{$t('section.classification.menuClassification.elementSelectionCondition')}}
                    div(v-if="isFormulaValid('findRule')")
                      condition-substitution(:rule="editingWormNodeRule.classificatorFindRule.findRule" :conditionLinks="editingWormNodeRule.conditionLinks" :worm-uuid="editingWormNodeRule.uuid" @updateConditionLinks="updateConditionLinks" :disabled="!canWorkWithWorm")
                    div(v-else) 
                      app-text {{ $t('section.worm.editCondition')}}

                  div.mb-5
                    app-text {{$t('section.classification.menuClassification.unit')}} {{ measureUnits }}
                    div(v-if="isFormulaValid('unitExpression')")
                      calc-condition-substitution(:rule="editingWormNodeRule.classificatorFindRule.unitExpression" :conditionLinks="editingWormNodeRule.conditionLinks" :worm-uuid="editingWormNodeRule.uuid" @updateConditionLinks="updateConditionLinks" :axisFilter="['ATTRIBUTE','USERPROP','CONST']" :simpleView="true" :disabled="!canWorkWithWorm")
                    div.d-flex(v-else) 
                      app-text {{ $t('section.worm.editCondition')}}
                      v-btn.ml-3.mt--2(v-if="canWorkWithWorm && hasClassificatorCreate" icon x-small light @click="switchToSmetaRuleEdit")
                        v-icon mdi-pencil

                  div.mb-5
                    app-text {{$t("section.classification.menuClassification.titleAttribute")}}
                    div(v-if="isFormulaValid('titleAttribute')")
                      calc-condition-substitution(:rule="editingWormNodeRule.classificatorFindRule.titleAttribute" :conditionLinks="editingWormNodeRule.conditionLinks" :worm-uuid="editingWormNodeRule.uuid" @updateConditionLinks="updateConditionLinks" :axisFilter="['ATTRIBUTE','USERPROP','CONST']" calcType="TITLECALC" :simpleView="true" :disabled="!canWorkWithWorm")
                    div.d-flex(v-else) 
                      app-text {{ $t('section.worm.editCondition')}}
                      v-btn.ml-3.mt--2(v-if="canWorkWithWorm && hasClassificatorCreate" icon x-small light @click="switchToSmetaRuleEdit")
                        v-icon mdi-pencil

                  div.mb-5(v-if="editingWormNodeRule.logicValidy")
                    app-text(v-if="editingWormNodeRule.logicValidy.valid")
                      v-icon(color="#0f0") mdi-circle-medium
                      | {{ $t('logic.rule.returnElements') }}
                    app-text(v-else)
                      div {{ $t('logic.rule.notReturnElements') }}
                  div.mb-5(v-else)
                    app-text {{ $t('logic.rule.validationNotPerformed') }}
                        
                  .d-flex.justify-space-between.align-center
                    div
                      v-btn(@click="setCheckLogicMode(0)" color='accent' :disabled="!haveAnyConditions" text x-small light) <b>•</b> {{$t("section.worm.checkTheRule")}}
                      v-btn(@click="setCheckLogicMode(1)" color='accent' :disabled="!haveAnyConditions || !pickedElement" text x-small light) 
                        span <b>•</b> {{$t("section.worm.checkTheElement")}}
                      v-progress-circular(v-if="checkLogicValidLoading" size="25" indeterminate color="accent")
                    div.d-flex.mt-1
                      v-icon.ml-2( color='#757575' @click="showPanel" :disabled="!haveAnyConditions") {{ showPanelHightLightGroups ? "mdi-eye" : "mdi-eye-off" }}
                      v-icon.ml-2(@click="openMenu" color='#757575') mdi-dots-vertical
                  app-menu(ref="wormRuleMenu" :menu="listMenu")

                template(v-else-if="editingWormNodeRule.classificatorFindRule.ruleType.name == 'NONMODELINGELEMENT'")
                  div
                    v-row(no-gutters)
                      v-col
                        app-text-field(v-model="editingWormNodeRule.elementName" :label="$t('section.worm.nameNonmodeledElement')" outlined :rules="[rules.required]" @change="ruleChanged")
                      v-col(cols="auto")
                        v-btn.mt-1.px-0(v-if="realWormRule.elementName != realWormRule.classificatorFindRule.elementName" color="primary" dense text small min-width="28" :title="$t('section.worm.ruleValue')")
                          v-icon( color="grey" @click.stop="dropElementName" small) clear

                  div
                    v-row(no-gutters)
                      v-col
                        app-text-field(v-model="editingWormNodeRule.value" :label="$t('section.classification.menuClassification.numberNonmodeledElement')" outlined :rules="[rules.required, rules.double]" @change="ruleChanged")
                      v-col(cols="auto")
                        v-btn.mt-1.px-0(v-if="realWormRule.value != realWormRule.classificatorFindRule.elementValue" color="primary" dense text small min-width="28" :title="$t('section.worm.ruleValue')")
                          v-icon( color="grey" @click.stop="dropElementValue" small) clear

                  div 
                    .d-flex.justify-end
                      v-icon.ml-2(@click="openMenu" color='#757575') mdi-dots-vertical
                  app-menu(ref="wormRuleMenu" :menu="listMenu")

              app-dialog(v-model="compounds.dialog" :header="$t('section.worm.resources')" width="700")
                app-section
                  app-section-header-tabs.mb-1( :items="compoundTypes" :value="selectedCompoundType.value" @select="onSelect" light )
                  v-data-table#copoundTable(
                    light
                    dense
                    :loading="compounds.loading"
                    :headers="headers"
                    :items="compoundsFiltred"
                    item-key="uuid"
                    :no-data-text="$t('section.classification.menuClassification.noBind')"
                  )
                    template(v-slot:item="{ item }")
                      v-hover( v-slot:default="{ hover }" )

                      
                        tr.compRow(v-if="item.substitutionWormCompound")
                          td(width="10%") 
                            div.striked {{ item.compoundedNode.pressmark }}
                            app-text(v-if="item.substitutionWormCompound.compoundedWormNode") {{ item.substitutionWormCompound.compoundedWormNode.classificatorNode.pressmark }}
                            app-text(v-else) {{ item.substitutionWormCompound.compoundedNode.pressmark }}
                          td
                            div.p-relative(@contextmenu.prevent="showReplaceWormCompoundMenu($event, item)")
                              span.striked {{ item.compoundedNode.title }} 
                              app-text(v-if="item.substitutionWormCompound.compoundedWormNode") {{ item.substitutionWormCompound.compoundedWormNode.classificatorFindRule.title }} ({{ item.substitutionWormCompound.compoundedWormNode.title }})
                              app-text(v-else) {{ item.substitutionWormCompound.compoundedNode.title }}
                              v-icon.p-absolute.top-0.right-0.unit-action.mt-6px( v-if="hover" color="#000" @click.stop="showReplaceWormCompoundMenu($event,item)" ) more_vert
                          template(v-if="item.substitutionWormCompound.compoundedWormNode")
                            td(colspan=2)
                          template(v-else)
                            td.text-center(width="10%") {{ item.substitutionWormCompound.compoundedNode.unitValue }} {{ item.substitutionWormCompound.compoundedNode.unitType.title }}
                            td.text-center(:title="$t('section.classification.menuClassification.changeFlow')" width="20%") {{ item.substitutionWormCompound.rate }}
                          td(width="10%").text-center.w60
                            v-icon.unit-action(v-if="hover" color="#000" @click.stop="deleteReplacedCompound(item)" :title="$t('section.classification.menuClassification.delete')") mdi-delete


                        tr.compRow(v-else-if="item.compoundedNode")
                          td(width="10%") 
                            div {{ item.compoundedNode.pressmark }}
                          td
                            div(@contextmenu.prevent="showReplaceWormCompoundMenu($event, item)")
                              .d-flex.justify-space-between
                                div
                                  div {{ item.compoundedNode.title }}
                                  div.note.mt-2(v-if="item.compoundUuid == null") Добавлен вручную
                                
                                div.d-flex
                                  v-icon.justify-center( v-if="hover" color="#000" @click.stop="showReplaceWormCompoundMenu($event,item)" ) more_vert
                            
                          td.text-center(width="10%") {{ item.compoundedNode.unitValue }} {{ item.compoundedNode.unitType.title }}

                          template(v-if="item.compoundUuid != null")
                            td.text-center(:title="$t('section.classification.menuClassification.changeFlow')" width="20%") {{ item.rate }}
                          template(v-else-if="item.editMode")
                            td(align="center" width="20%")
                              app-text-field(v-model="item.rate" :label="$t('section.classification.menuClassification.outgo')" hide-details outlined :rules="[rules.required, rules.double]" @change="saveRate(item)" @blur="saveRate(item)" autofocus style="width:100px;")
                          template(v-else)
                            td.text-center.pointer(@click="editRate(item)" :title="$t('section.classification.menuClassification.changeFlow')" width="20%") {{ item.rate }}

                          //- td
                            v-checkbox.mx-n1(v-model="item.showInSmeta" dense hide-details light @change="saveRate(item)")

                          td(width="10%").text-center.w60
                            v-icon(v-if="item.compoundUuid != null" :title="$t('section.classification.menuClassification.relatedClassifier')") mdi-link
                            v-icon.unit-action( v-else-if="item.compoundUuid == null && hover" color="#000" @click.stop="showDeleteCompound(item)" :title="$t('section.classification.menuClassification.delete')") mdi-delete

                        tr.compRow(v-else style="background: #f00;") 
                          td(width="10%" colspan=3) {{ item.uuid }}
                          td(colspan=2) {{$t('section.classification.menuClassification.errorBind')}}
                          td(width="10%").text-center.w60
                            v-icon.unit-action( v-if="hover" color="#000" @click.stop="showDeleteCompound(item)" :title="$t('section.classification.menuClassification.delete')") mdi-delete


                  .d-flex.mt-2(v-if="canWorkWithWorm")
                    .btn-action(@click="compoundByDefaultDialog = true")
                      span {{ $t('button.default') }}

                    .btn-action.px-2.ml-3(@click="showAddCompound()")
                      v-icon(color="#3B93AF" ) mdi-plus
                      span {{$t('button.add')}}

                app-menu(ref="compoundReplaceMenu" :menu="compoundMenu" @close="closeItemMenu")


              AddWormCompoundDialog(v-model="addCompoundDialog" :wormNode="editingWormNodeRule" :show-default="false" :nodeType="selectedCompoundType" :wormCompounds="wormCompounds" @addCompound="addCompound")
              ReplaceWormCompoundDialog(v-model="replaceCompound.dialog" :oldCompound="replaceCompound.replacingCompound" :nodeType="selectedCompoundType" :wormCompounds="wormCompounds" :mode="replaceCompound.replaciningMode" @replaceCompoundAction="replaceCompoundAction")

              app-dialog(v-if="deleteCompound.compound" v-model="deleteCompound.dialog" :header="` ${$t('section.classification.menuClassification.removeBinds')} ${deleteCompound.compound.compoundedNode ? deleteCompound.compound.compoundedNode.title : deleteCompound.compound.uuid}?`")
                app-section
                  v-btn.text-none.dialog-confirm-action.mr-2( height="32" small depressed light @click="deleteCompound.dialog = !deleteCompound.dialog") {{$t('button.cancel')}}
                  v-btn.text-none.dialog-confirm-action(color="accent" height="32" small depressed light @click="delCompound()") {{$t('button.delete')}}

              app-dialog-confirm( v-model="compoundByDefaultDialog" @confirm="compoundByDefault")
                app-section
                  app-text {{ $t('section.worm.confirmDeleteCompound')}}

          div.text-center.form-hoverer.p-absolute.pt-16(v-if="savingRule")
            div
              v-progress-circular.mr-2(size="50" indeterminate)
            div {{ $t('section.worm.savingCalculating')}}
      app-floating-panel( v-if="showPanelHightLightGroups" style="margin-right: -8px" ref="hightLightGroups" :title="$t('section.classification.menuClassification.elementsInRule') + hightLightElementsTotal" 
        bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('WORM_RULE_ELEMENTS')")
        hight-light-groups( v-if="showPanelHightLightGroups" style="overflow-y: auto; overflow-x: hidden;")

</template>

<script>
import { api } from "@/api"
import { mapState, mapMutations, mapGetters , mapActions } from 'vuex'

import WormNode from '@/assets/model/worm/WormNode'

import ConditionSubstitution from './ConditionSubstitution'
import CalcConditionSubstitution from './CalcConditionSubstitution'

import Pagination from '@/components/project/panel/left/smeta/components/rule/Pagination'

import WormConditionValid from '@/assets/model/condition/WormConditionValid'
import ElementWormConditionValid from '@/assets/model/condition/ElementWormConditionValid'

import HightLightGroups from '../../../components/element/HightLightGroups'

import AddWormCompoundDialog from "./AddWormCompoundDialog"
import ReplaceWormCompoundDialog from "./ReplaceWormCompoundDialog"

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { useHighlightGroupsStore } from "@/pinia/smeta/highlightGroups.store"

const highlightGroupsStore = useHighlightGroupsStore()

export default {
  name:'worm-rule-edit-form',
  
  components: {
    ConditionSubstitution,
    CalcConditionSubstitution,
    Pagination,

    HightLightGroups,

    AddWormCompoundDialog,
    ReplaceWormCompoundDialog
  },

  props: {
    
  },

  data() {
    return {
      editingWormNodeRule: null,
      wormCompounds: [],

      selectedCompoundType: {value:2,name:'WORK', title: this.$t('module.task.smeta.works')},

      compounds: {
        dialog: false,
        loading: false
      },

      savingRule: false,
      savingErrorText: null,

      addCompoundDialog: false,
      deleteCompound: {
        compound: null,
        dialog: false
      },
      compoundByDefaultDialog: false,

      compoundMenuItem: null,

      replaceCompound: {
        dialog: false,
        replacingCompound: null,
        replaciningMode: 'RULE',
      },

      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(\.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },

      checkLogicValidLoading: false,

      listMenu: [
        {
          title: 'section.worm.resources',  
          // icon: "mdi-cog-outline",
          action: this.showResourcesDialog,
        }, 
        {
          title: 'section.worm.classifier',
          // iconSrc: "/img/project/tools/classifier.svg",
          action: this.switchToSmetaRuleEdit,
        }
      ]
    }
  },

  mounted() {
    if (this.editingRuleUuid) this.init()
  },

  computed: {
    ...mapState('project', ['projectSettings']),
    ...mapState('entities/WormNode', ['editingRuleUuid']),
    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapState('smeta', ['dataExportTypes', 'nodeTypes','items', 'isTreeInPreparation']),
    ...mapGetters('smeta', ['getNode']),
    ...mapState('worm', ['showPanelHightLightGroups']),
    ...mapGetters('authUser', ['canWorkWithWorm']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreate']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    measureUnits() {
      return this.editingWormNodeRule.classificatorNode && this.editingWormNodeRule.classificatorNode.unitType ? this.editingWormNodeRule.classificatorNode.unitValue+' '+this.editingWormNodeRule.classificatorNode.unitType.title : ''
    },

    realWormRule(){
      if (this.editingRuleUuid) return WormNode.find(this.editingRuleUuid)
      else return null
    },
    
    compoundTypes(){
      return this.nodeTypes.filter(e => (e.value != 1 && e.value != 0))
    },

    compoundsFiltred() {
      return this.wormCompounds.filter(e => (e.compoundedNode && e.compoundedNode.nodeType && e.compoundedNode.nodeType.value == this.selectedCompoundType.value) || !e.compoundedNode)
    },

    headers () {
      return [
        {text: this.$t('section.classification.menuClassification.code'), align: 'start', sortable: false},
        {text: this.$t('section.classification.menuClassification.title'),  align: 'start', sortable: false},
        {text: this.$t('section.classification.menuClassification.smallUnit'), align: 'center', sortable: false},
        {text: this.$t('section.classification.menuClassification.outgo'), align: 'center', sortable: false},
        // {text: this.$t('section.classification.menuClassification.ind'), align: 'center', sortable: false},
        {text: '', align: 'center', sortable: false}
      ]
    },

    hightLightElementsTotal(){
      return highlightGroupsStore.totalElements
    },

    haveAnyConditions() {

      let conds = (rule) => {
        let conditions = rule.condition
        rule.children?.forEach(child => {
          conditions = conditions.concat(conds(child))
        })
        return conditions
      }

      let c = []
      if (this.editingWormNodeRule.classificatorFindRule) {
        if (this.editingWormNodeRule.classificatorFindRule.findRule) c = c.concat(conds(this.editingWormNodeRule.classificatorFindRule.findRule))
        if (this.editingWormNodeRule.classificatorFindRule.unitExpression) c = c.concat(conds(this.editingWormNodeRule.classificatorFindRule.unitExpression))
        if (this.editingWormNodeRule.classificatorFindRule.titleAttribute) c = c.concat(conds(this.editingWormNodeRule.classificatorFindRule.titleAttribute))
      }
      return c.length > 0
    },

    checkLogicMode: {
      get () {
        return this.$store.state.worm.checkLogicMode
      },
      set (value) {
        this.$store.commit('worm/setCheckLogicMode', value)
      }
    },

    compoundMenu() {
      // return [
      //   { title: this.$t('section.classification.menuClassification.replaceRule'), action: () => this.showReplaceWormCompound('RULE') },
      //   { title: this.$t('section.classification.menuClassification.replaceType'), action: () => this.showReplaceWormCompound('TYPE') }
      // ]
      return [
        { title: this.$t('section.classification.menuClassification.replace'), action: () => this.showReplaceWormCompound('TYPE') }
      ]
    }
  },
  
  watch: {
    editingRuleUuid() {
      this.checkLogicMode = null
      this.init()
    },

    'highLightedGroup.selected'(v){
      this.selectElements([])
      if (v) {
        let a = [].concat(...v.map(e => e.guids))
        this.selectElements(a)
      }
    },

    pickedElement(v, ov){
      if (!v || (v && ov && v != ov)) this.checkLogicMode = null
    },

    checkLogicMode(){
      this.checkLogicValid()
    }
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    ...mapMutations('smeta', ['setEditRuleItem','setExpandAfterLoadUuid']),
    ...mapMutations('worm', ['setShowPanelHightLightGroups']),
    ...mapActions('smeta', ['LOAD_TREE','expandTo']),

    ...mapMutations('project', ['setActiveGlobalTab']),

    init(){
      this.savingRule = false
      this.savingErrorText = null
      if (this.editingRuleUuid) {
        if (this.$refs.wormRuleEditForm.openedPanel != true) {
          this.$refs.wormRuleEditForm.openBody()
        }
        this.editingWormNodeRule = JSON.parse(JSON.stringify(WormNode.find(this.editingRuleUuid)))
        this.editingWormNodeRule.wormRuleResults = []
      } else {
        this.editingWormNodeRule = null
        this.wormCompounds = []
      }
      this.fetchElementByGlobalId(null)
      XeokitMediator.ElementsSelection.selectElements([])
      XeokitMediator.ElementsSelection.pickElement(null)
      if(this.showPanelHightLightGroups === true){
      this.loadElementsForHighLigths()
      }
    
    },
    openMenu(event) {
      this.$refs.wormRuleMenu.show(event)
    },

    isFormulaValid(field){
      let f = this.editingWormNodeRule.classificatorFindRule[field]
      if (f && f.uuid && ((f.children && f.children.length > 0) || (f.condition && f.condition.length > 0))) return true
      return false
    },

    dropElementName(){
      this.editingWormNodeRule.elementName = this.editingWormNodeRule.classificatorFindRule.elementName
      this.ruleChanged()
    },

    dropElementValue(){
      this.editingWormNodeRule.value = this.editingWormNodeRule.classificatorFindRule.elementValue
      this.ruleChanged()
    },

    ruleChanged(){
      this.editRule()
    },

    editRule(){
      if (this.$refs.ruleForm.validate()) {
        this.savingErrorText = null
        this.savingRule = true
        WormNode.api().put(`/worm/projectworm/editnode`,this.editingWormNodeRule).then(() => {
          this.savingRule = false
          this.editingWormNodeRule = JSON.parse(JSON.stringify(WormNode.find(this.editingWormNodeRule.uuid)))

          this.checkLogicMode = null
          this.dropConditionValid()
          this.dropElementWormConditionValid()
          this.setShowPanelHightLightGroups(false)
          highlightGroupsStore.resetState()
        }).catch(e => {
          this.savingRule = false
          this.savingErrorText = e
        })
      }
    },

    updateConditionLinks(conditionLinks){
      this.editingWormNodeRule.conditionLinks = conditionLinks
      this.editRule()
    },


    loadCompounds(){
      this.wormCompounds = []
      this.compounds.loading = true
      api.worm.loadWormCompounds(this.editingRuleUuid).then(data => {
        this.wormCompounds = data
        this.compounds.loading = false
      })
    },

    showResourcesDialog(){
      if (!this.editingWormNodeRule.resource) {
        this.compounds.dialog = true
        this.loadCompounds()
      }
    },

    onSelect(item) {
      this.selectedCompoundType = item
    },

    showAddCompound() {
      this.addCompoundDialog = true
    },

    addCompound(item) {
      let saveData = JSON.parse(JSON.stringify(item))
      saveData.wormNode = {uuid:this.editingRuleUuid}
      saveData.compoundedNode = {uuid:item.compoundedNode.uuid}
      api.worm.saveCompound(saveData).then(() => {
        this.loadCompounds()
        this.addCompoundDialog = false
      })
    },

    showReplaceWormCompoundMenu(event,item) {
      this.compoundMenuItem = item
      this.$refs.compoundReplaceMenu.show(event)

    },
    setCheckLogicMode(value) {
      this.$store.commit('worm/setCheckLogicMode', value)
    },
    closeItemMenu() {
      this.compoundMenuItem = null
    },

    showReplaceWormCompound(mode){
      this.replaceCompound.replacingCompound = this.compoundMenuItem
      this.replaceCompound.replaciningMode = mode,
      this.replaceCompound.dialog = true

      this.compoundMenuItem = null
    },

    replaceCompoundAction(item){
      api.worm.replaceCompound(item).then((data) => {
        
        if (item.mode == 'RULE') {
          if (data.substitutionWormCompound.compoundedWormNode) {
            WormNode.insert({data: data.substitutionWormCompound.compoundedWormNode})
            WormNode.api().recalculate(data.substitutionWormCompound.compoundedWormNode.uuid)
          }
        }

        if (this.replaceCompound.replacingCompound.substitutionWormCompound) {
          let scc = this.replaceCompound.replacingCompound.substitutionWormCompound
          if (scc.compoundedWormNode) {
            WormNode.delete(scc.compoundedWormNode.uuid)
          }
        }

        this.loadCompounds()

        this.replaceCompound.replacingCompound = null
        this.replaceCompound.replaciningMode = item.mode,
        this.replaceCompound.dialog = false
      })
    },

    deleteReplacedCompound(item){
      api.worm.removeReplaceCompound(item.uuid).then(() => {
        if (item.substitutionWormCompound) {
          let scc = item.substitutionWormCompound
          if (scc.compoundedWormNode) {
            WormNode.delete(scc.compoundedWormNode.uuid)
          }

          this.loadCompounds()
        }
      })
    },

    editRate(item) {
      this.wormCompounds.filter(e => e.editMode).forEach(e => e.editMode = false)
      item.editMode = true
    },

    saveRate(item) {
      let saveData = JSON.parse(JSON.stringify(item))
      saveData.wormNode = {uuid:this.editingRuleUuid}
      saveData.compoundedNode = {uuid:item.compoundedNode.uuid}
      api.worm.saveCompound(saveData).then(() => {
        item.editMode = false
      })
    },

    showDeleteCompound(item) {
      this.deleteCompound.compound = item
      this.deleteCompound.dialog = true
    },

    delCompound(){
      api.worm.removeCompound(this.deleteCompound.compound.uuid).then(() => {
        this.loadCompounds()
        this.deleteCompound.dialog = false
        this.deleteCompound.compound = null
      })
    },

    compoundByDefault(){
      let subs = this.wormCompounds.filter(e => e.substitutionWormCompound != null)
      api.worm.dropCompoundToDefault(this.editingRuleUuid).then(() => {

        subs.forEach(el => {
          let scc = el.substitutionWormCompound
          if (scc.compoundedWormNode) {
            WormNode.delete(scc.compoundedWormNode.uuid)
          }
        })

        this.loadCompounds()
        this.compoundByDefaultDialog = false
      })
    },

    loadElementsForHighLigths() {
      if(!this.showPanelHightLightGroups) {
        this.setShowPanelHightLightGroups(true)
      }
      this.editingWormNodeRule? highlightGroupsStore.loadWormHighLightsGroup(this.editingWormNodeRule.uuid): highlightGroupsStore.resetState()
      this.openPanelHightLightGroups()
    },

    showPanel() {
      if(this.showPanelHightLightGroups){
        this.setShowPanelHightLightGroups(false)
      }
      else {
        this.setShowPanelHightLightGroups(true)
        this.loadElementsForHighLigths()
      }
    },

    openPanelHightLightGroups() {
      const hightLightGroups = this.$refs['hightLightGroups']
      if(this.showPanel) {
        !hightLightGroups?.openedPanel && hightLightGroups?.openBody()
      }
      return this.$nextTick()
    },


    selectElements(arr) {
      XeokitMediator.ElementsSelection.selectElements([])
      if (arr && arr.length>0) XeokitMediator.ElementsSelection.selectElements(arr)
    },

    checkLogicValid(){
      if (this.checkLogicMode == 0) {
        this.dropConditionValid()
        this.checkLogicValidLoading = true
        WormConditionValid.api().checkByWormNode(this.editingWormNodeRule.uuid).then(() => {
          this.checkLogicValidLoading = false
        })
      } else if (this.checkLogicMode == 1) {
        this.dropElementWormConditionValid()
        this.checkLogicValidLoading = true
        ElementWormConditionValid.api().check(this.pickedElement, this.editingWormNodeRule.uuid).then(() => {
          this.checkLogicValidLoading = false
        })
      } else {
        this.dropConditionValid()
        this.dropElementWormConditionValid()
      }
    },
    
    dropConditionValid(){
      if (this.editingWormNodeRule) WormConditionValid.delete(this.editingWormNodeRule.uuid)
    },

    dropElementWormConditionValid(){
      if (this.editingWormNodeRule) {
        if (this.pickedElement) ElementWormConditionValid.delete([this.editingWormNodeRule.uuid, this.pickedElement])
        else {
          ElementWormConditionValid.delete((elem) => elem.wormUuid == this.editingWormNodeRule.uuid)
        }
      }
    },

    getCondReq(rule){
      if (!rule) return []
      let cond = []
      if (rule.condition && rule.condition.length > 0) cond = cond.concat(rule.condition)
      if (rule.children && rule.children.length > 0) {
        for (let c of rule.children) cond = cond.concat(this.getCondReq(c))
      }
      return cond
    },


    switchToSmetaRuleEdit(){
      if (!this.canWorkWithWorm && !this.hasClassificatorCreate) return

      if (this.projectSettings.projectClassificator != this.editingWormNodeRule.classificatorNode.projectClassificatorUuid) {
        let ps = JSON.parse(JSON.stringify(this.projectSettings))
        ps.projectClassificator = this.editingWormNodeRule.classificatorNode.projectClassificatorUuid
        this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
        this.setExpandAfterLoadUuid(this.editingWormNodeRule.classificatorFindRule.uuid)
      } else if (this.items?.length === 0) {
        this.setExpandAfterLoadUuid(this.editingWormNodeRule.classificatorFindRule.uuid)
      } else {
        this.setExpandAfterLoadUuid(this.editingWormNodeRule.classificatorFindRule.uuid)
        this.expandTo(this.editingWormNodeRule.classificatorFindRule.uuid)
        let fullNode = this.getNode(this.editingWormNodeRule.classificatorFindRule.uuid)
        if (fullNode?.isRule || null) this.setEditRuleItem(fullNode)
        else if ((fullNode?.children || null ) && fullNode?.children?.length > 0) this.setEditRuleItem(fullNode.children[0])
      }
      this.$nextTick(() => this.setActiveGlobalTab('smeta'))
    }
  }
}
</script>

<style lang="scss" scoped>
  .mh-49 {
    /* max-height: 49%; */
    height: inherit;
    overflow: auto;
  }
  .form-hoverer {
    top: 0px;
    left: 0px;;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
  }
  .btn-action {
    width:100px;
    margin: 0px;
    font: normal 12px/18px $roboto;
    background-color: transparent;
    color: #3B93AF;
    text-align: center;
    border: 1px dashed #3B93AF;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }
  .mt--2 {
    margin-top: -2px;
  }
  .striked {
    text-decoration: line-through;
  }
  .top-0 {
    top: 0;
  }
  .right-0 {
    right: 0;
  }
  .note{
    font: normal 11px/12px $roboto;
    color: grey;
  }
  .pop{
    text-align: right;
  }
</style>