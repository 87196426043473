<template lang="pug">
app-toolbar( gap="12px" )
  app-toolbar-button( icon="$viewer-element-selector-icon" :title="$t('viewer.tools.highlightFrame')" :disabled='atModelPositioning' :active="isSelectionFrameActive" @click="toggleHighlightFrame()" )
  app-toolbar-button( icon="$viewer-show-icon" :title="$t('viewer.tools.showSelObjs')" :active="isShowOnlySelectedElements" @click="toggleShowOnlySelectedObjects()" )
  app-toolbar-button( icon="$viewer-hide-clear-icon" :active="isHideSelectedElements" @click="toggleHideSelectedObjects()" :title="$t('viewer.tools.hideSelObjs')" )
  app-toolbar-button( icon="$viewer-reset-clear-icon" @click="showAllObjects()" :title="$t('viewer.tools.clearSelObjs')" )
</template>

<script>
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator"
import { mapState, mapGetters, mapMutations } from "vuex"
import { useViewerGroupsStore } from '@/pinia'

export default {
  data() {
    return {
      lastPickedElement: null,
      lastSelectedCollisionsElementAB: null,
      lastCollisionSelectedElements: null,
      // isCtrlKeyPressed: false,
      // IFCGridActive: false,
      // showPanel: false,
      // menuActivator: {
      //   value: false
      // },
      // mouseDownTime: 0,
      // openMenuTimeout: null
    }
  },

  computed: {
    ...mapGetters('project', ['atModelPositioning']),
    ...mapGetters('collision/table', ['selectedCollisionsElementAB']),
    ...mapState('collision/search', ['collisionSelectedElements']),

    store: () => useViewerGroupsStore(),

    isSelectionFrameActive() {
      return XeokitMediator.SelectionFrame.isSelectionFrameActive
    },

    isShowOnlySelectedElements() {
      return XeokitMediator.ElementsSelection.isShowOnlySelectedElements
    },

    isHideSelectedElements() {
      return XeokitMediator.ElementsSelection.isHideSelectedElements
    },

     selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },
  },

  methods: {
    ...mapMutations('projectDict', ['setShowPanel']),
    ...mapMutations('smeta', ['setShowPanelHightLight']),
    ...mapMutations('worm', ['setShowPanelHightLightGroups']),

    toggleHighlightFrame(){
      if (this.isSelectionFrameActive) XeokitMediator.SelectionFrame.deactivate()
      else XeokitMediator.SelectionFrame.activate()
    },

    toggleShowOnlySelectedObjects () {
      if (!this.isShowOnlySelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements, ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if (!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        for(let element of this.lastSelectedCollisionsElementAB) {
          elementsSet.delete(element)
        }  
        elementsSet.delete(this.lastCollisionSelectedElements[0])
        elementsSet.delete(this.lastCollisionSelectedElements[1])
        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setShowOnlySelectedElements(!this.isShowOnlySelectedElements)
    },

    toggleHideSelectedObjects () {
      if(!this.isHideSelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements, ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if(!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        this.lastSelectedCollisionsElementAB?.forEach(element => {
          elementsSet.delete(element)
        })
        if(this.lastCollisionSelectedElements) {
          elementsSet.delete(this.lastCollisionSelectedElements[0])
          elementsSet.delete(this.lastCollisionSelectedElements[1])
        }
      
        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setHideSelectedElements(!this.isHideSelectedElements)
    },

    showAllObjects () {
      this.store.showAllObjects()
      this.setShowPanel(false)
      this.setShowPanelHightLightGroups(false)
      this.setShowPanelHightLight(false)
      XeokitMediator.ElementsSelection.showAllElements()
    },
  }
}
</script>