<template lang="pug">
  app-dialog( v-model="$_show" :width='900' @close='clear()' complex)
    v-row( no-gutters )
      v-col( :cols="2" )
        app-panel-section( :title="$t('section.elementTree.selectingParam')" )
          condition-parameter.condition-parameters( :condAxisEnum='attributeConditionAxisEnum' @selectOperand="selectOperand($event)")
          div(v-for="item in attributeConditionAxisEnum")
            .manual-input( v-if="item && item.name == 'MANUALINPUT'" :class='{"accent--text": manualInput}' @click='setManualInput(true)') {{ $t('section.collision.rulesFounder.manualInput') }}
      
      v-col( :cols="5" style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingClassAttr')" )
          condition-class-attribute(
            v-if='!manualInput'
            ref='conditon-class-attribute'
            :conditionTreeCache="conditionTreeCache"
            @selectAttr='selectAttribute($event)'
          )
          .manual-input-field(v-if='manualInput')
            app-text-field( v-model='form.manualOperand' :placeholder='$t("section.elementTree.enterValue")' hide-details)
            div.ma-3
              app-text.text--lighten-3 {{ $t('app.markdownTextarea.helperExample') }}: 
              app-text.text--lighten-3.ml-2 {{ $t('section.elementTree.IFCtypeGroupName') }} <br/> {{ $t('section.elementTree.groupName') }} <br/> {{ $t('section.elementTree.name') }}

      v-col( :cols='2' style='padding-left: 1px;' )
        app-panel-section( :title="$t('section.elementTree.choosingComparison')" )
          app-text( v-for="item in condOperatorAttrCheck" :key="item.value")
            div( :style="form.operator && form.operator.name == item.name ? activeClass : ''" @click="selectOperator(item)" ) {{ $t(`section.elementTree.condition.${item.name}`) }}

      v-col( :cols='3' style="padding-left: 1px;" )
        app-panel-section( :title="$t('section.elementTree.selectingValue')" )
          div( v-if="form.operator && (form.operator.name!='EX' && form.operator.name!='NOTEX' && form.operator.name!='ISSTR' && form.operator.name!='ISNUM')" )
            app-select.select-maxw( v-if="form.operandUuid != null && (currentType == 'ENUM' || currentType == 'BOOL')" v-model="form.value" :items="attrTypeEnum" :label="$t('section.elementTree.enterValue')" item-value="value" )
            app-text-field( v-else v-model="form.value" :label="$t('section.elementTree.enterValue')" :rules="[this.rules.required]")
            app-text.ml-3.mt-n2.text--lighten-3(v-if="form.operator.name == 'RANGE'") Например: 1-10
            app-text.ml-3.mt-n2.text--lighten-3(v-if="form.operator.name == 'IN'") Например: 1,5,7

    v-row.align-center.white( slot='footer' no-gutters style="margin-top: 1px;" )
      app-button.ml-auto.my-2.mr-2( action :disabled='!valid' @click='saveCondition()' test-id="attr-checking-save") {{ $t('button.save') }}
</template>

<script> 
import ConditionParameter from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionParameter.vue'
import ConditionClassAttribute from '@/components/project/panel/left/components/logicGroup/conditionSections/ConditionClassAttribute.vue'

import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { ConditionTreeCache } from '@/assets/model/smeta/ConditionTreeCache'
import { projectService } from '@/_services'
import values from "@/assets/model/collision/AttributeCheckingValues"

import { $_validate_rules } from '@/common/ValidateRules'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  name: "AttributeCheckingCondition.vue",

  mixins: [$_validate_rules, DialogToggable],
  
  components: { ConditionParameter, ConditionClassAttribute },

  props: {
    condition: Object
  },

  data() {
    return {
      attrTypeEnum: null,
      conditionTreeCache: new ConditionTreeCache(),
      manualInput: false,
      recursionFlag: null,
    }
  },

  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.condition || this.defaultCondition
        this.setForm(JSON.parse(JSON.stringify(baseObj)))
        this.show()
      }
    },
  }, 

  computed: {
    ...mapGetters('project', ['axisEnum', 'projectUuid']),
    ...mapState('condition', ['form', 'defaultCondition', 'currentType']),
    ...mapGetters('condition', ['activeClass', 'condOperatorAttrCheck']),
    ...mapState('copyutil', ['copiedElementAttribute']),

    attributeConditionAxisEnum() {
      return this.axisEnum.filter(a => a.name == 'ATTRIBUTE' || a.name == 'USERPROP' || a.name == 'MANUALINPUT')
    },
    
    attributeCheckingValues() {
      return values
    },

    valid() {
      let valueCanEmpty = this.form.operator && (this.form.operator.name=='EX' || this.form.operator.name=='NOTEX' || this.form.operator.name=='ISSTR' || this.form.operator.name=='ISNUM') ? true : false
      /*return this.manualInput ? 
        ( this.form.manualOperand && this.form.value && this.form.value.trim() ) : 
        ( this.form.value && this.form.value.trim() !== "" && this.form.operandUuid ) */

      return this.manualInput ? 
        ( this.form.manualOperand && ( valueCanEmpty || (this.form.value && this.form.value.trim()) ) ) : 
        ( this.form.operandUuid && ( valueCanEmpty || (this.form.value && this.form.value.trim() !== "") ) )

    }
  },

  methods: {
    ...mapMutations('condition', ['setLoaded', 'setCurrentType', 'setSearch', 'setForm']),
    ...mapActions('condition', ['selectOperator']),
    ...mapMutations('copyutil', ['setCopiedElementAttribute']),

    selectOperand(operand, isCopy = false) {
      this.setLoaded(false)
      this.setManualInput(false)

      if (!isCopy) {
        this.form.value = null
        this.form.operandUuid = null
        this.form.operand = operand

        this.setSearch('')
      }

      return projectService.loadParamForCondition(this.projectUuid, operand.name).then(data => {
        this.conditionTreeCache.setItems(data)

        let title = ""
        if( this.form.manualOperand ) {
          if( this.form.manualOperand.includes("/") ) title = this.form.manualOperand.split("/")
          if( title.length >= 2 ) {
            title = title[title.length - 2]
          } 
        }

        this.setSearch(title)
        this.setLoaded(true)
        this.recursionFlag = false
        if( this.form.manualOperand ) this.recursionPickedElement(this.conditionTreeCache.tree.items, isCopy) 
      })
    },

    recursionPickedElement( objArray, copy = false ) {
      let arr = this.form.manualOperand.split('/')
      let titleString = "'" + this.form.manualOperand + "' "
      let titleArr = this.reducedСonditions ? titleString.split('/') : this.form.manualOperand.split('/')
      for( let i = 0; i < objArray.length; i++ ) {
        if( ( objArray[i].name == arr[ arr.length - 1 ] || objArray[i].name == titleArr[ titleArr.length - 1 ] ) && !objArray[i]?.children?.length ) {
          this.selectAttribute( objArray[i], true )
          this.recursionFlag = true
        }
        if( objArray[i]?.children?.length && !this.recursionFlag && !copy && ( arr.indexOf(objArray[i].name) !== -1 ) ) {
          this.handleTreeOpen( objArray[i])
          this.recursionPickedElement( objArray[i].children )
        }

        if( copy ) {
          for( let j = 0; j < arr.length; j++ ) {
            if( objArray[i].children.length && !this.recursionFlag && objArray[i].name == arr[j] ) {
              this.handleTreeOpen( objArray[i] )
              this.recursionPickedElement( objArray[i].children, true )
            }
          }
        }
      }
    },

    setManualInput(state) {
      this.manualInput = state

      if (state) {
        this.form.operand = {
          name: ""
        }
      }
    },

    selectAttribute(item) {
      if(item.type && item.type.name != "SET" && item.type.name != "CLASS" && item.type.name != "CLASSIFICATOR"){
        this.setCurrentType( item.type.name )
        this.form.operandUuid = item.uuid 

        if(this.currentType == "ENUM") projectService.loadBClassEnumElements(item.uuid).then(data => this.attrTypeEnum = data)
        if(this.currentType == "BOOL") this.attrTypeEnum = [{"value":"true", "text":this.$t('section.elementTree.yes')}, {"value":"false", "text":this.$t('section.elementTree.no')}]
      }

      this.handleTreeOpen(item)
    },

    selectValue(value) {
      this.form.value = value
    },

    handleTreeOpen(item) {
      if (this.conditionTreeCache.isOpened(item)) {
        this.conditionTreeCache.close(item)
      }
      else {
        this.conditionTreeCache.open(item)
      }
    },

    saveCondition() {
      this.form.title = this.buildTitle()

      this.condition.title = this.form.title
      this.condition.operand = this.manualInput ? null : this.form.operand
      this.condition.manualOperand = this.manualInput ? this.form.manualOperand : null
      this.condition.operandUuid = this.form.operandUuid
      this.condition.value = this.form.value
      this.condition.operator = this.form.operator

      if (this.copiedElementAttribute) this.setCopiedElementAttribute(null)

      this.$emit('created', this.condition)
      this.clear()
    },

    buildTitle() {
      if (this.manualInput) {
        return this.form.manualOperand
      }
      let title = ''
      let item = this.conditionTreeCache.getById(this.form.operandUuid)
      let parents = this.conditionTreeCache.getAllParents(item).reverse()

      parents.map(parent => {
        title += parent.name + "/"
      })
      title += item.name
      return title
    },

    clear() {
      this.setSearch('')
      this.setForm({ ...this.defaultCondition })
    },

    show() {
      if (this.copiedElementAttribute) {

        let operand = this.copiedElementAttribute.operand ? this.copiedElementAttribute.operand : 'USERPROP'
        this.form.operand = this.attributeConditionAxisEnum.find(e => e.name == operand)

        if(this.form.operand) {
          this.form.operator = this.condOperatorAttrCheck.find(e => e.name == 'EQ')
          this.form.value = this.copiedElementAttribute.stringValue
          this.form.operandUuid = this.copiedElementAttribute.elementClass
          this.form.title = this.copiedElementAttribute.title
          if( this.copiedElementAttribute.title.includes("'") ) {
            this.form.manualOperand = this.copiedElementAttribute.title.split("'")[1]
          }

          this.selectOperand(this.form.operand, true)
          return
        }   
      }

      if (this.condition && this.condition.operand) {
        this.selectOperand(this.condition.operand).then(() => {
          if (this.condition.operandUuid) {
            this.$nextTick(() => {
              let title = this.condition.title.slice(this.condition.title.lastIndexOf("/") + 1)
              this.setSearch(title)

              this.form.operandUuid = this.condition.operandUuid
              this.form.value = this.condition.value
            })
          } 
        }) 
      } 
      else if (this.condition && this.condition.manualOperand) {
        this.setManualInput(true)
        this.form.manualOperand = this.condition.manualOperand
        this.form.value = this.condition.value
      }
    }
  }
}
</script>

<style scoped>
.h-100 {
  height:100%;
  max-height:300px;
  min-height:300px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.manual-input {
  font: normal 12px/16px "Roboto", sans-serif;
  color: var(--v-primary-base);
}
.condition-parameters {
  height: auto !important;
}
</style>