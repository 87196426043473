<template lang="pug">
app-dialog( v-model="$_show" header="Обновление классификатора" width="500" )
  .d-flex.flex-column
    .d-flex.mb-4( style="gap: 5px;" )
      .flex-basis-0.d-flex.flex-column.justify-space-between
        div
          app-text Наименование: {{ classificatorName }}
          app-text Текущая версия: {{ classificatorVersion }}
          v-divider.mb-2.mt-2( light horizontal )
        div( v-if="selectedVersion" )
          app-text Версия после обновления: {{ selectedVersion.name }}
          v-checkbox( v-for="file in selectedVersion.files" v-model="selectedFiles" 
                      :value="file.uuid" :disabled="file.required" :key="file.uuid" 
                      :label="file.originalName" dense light hide-details )
      v-divider( light vertical )
      v-treeview( dense light open-on-click hoverable item-key="uuid" item-text="name" :items="dictionary" item-children="versions" )
        template( v-slot:label="{ item }" )
          div( @click="selectVersion(item)" :class="{ 'item--hightlighted' : hightlight(item) }" ) {{ item.name }}
    app-button( :disabled="selectedVersion && !selectedFiles.length" action small @click="save" ) Обновить
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'

export default {
  mixins: [DialogToggable],
  props: {
    classificatorUuid: null
  },

  data() {
    return {
      selectedVersion: null,
      selectedFiles: []
    }
  },

  computed: {
    ...mapGetters('smeta', ['getProjectClassificator']),
    ...mapGetters('smetadictionary', ['getAllVersions']),
    ...mapState('smetadictionary', ['dictionary']),

    classificatorName() {
      return this.getProjectClassificator(this.classificatorUuid)?.title
    },

    classificatorVersion() {
      const versionUuid = this.getProjectClassificator(this.classificatorUuid)?.smetaDictionaryVersionUuid
      if(!versionUuid) return "отсутствует"
      return this.getAllVersions.find(version => version.uuid == versionUuid)?.name
    }
  }, 

  methods: {
    ...mapActions('smetadictionary', ['initSmetaDictionaries']),
    ...mapActions('smetaMerge', ['mergeVersions']),

    selectVersion(item) {
      if (!!item['files'] && !!item['created']) {
        this.selectedVersion = item
        this.selectedFiles = item.files.map(file => file.uuid)
      }
    },

    save() {
      let newVersion = {
        projectClassificatorUuid: this.classificatorUuid,
        versionUuid: this.selectedVersion.uuid,
        files: this.selectedFiles
      }
      this.$emit('merge', newVersion)
    },

    hightlight(item) {
      return this.selectedVersion?.uuid == item.uuid ? true : false
    }
  },

  mounted() {
    this.initSmetaDictionaries()
  }
}
</script>

<style scoped>
.item--hightlighted {
  background-color: var(--v-primary-lighten5);
  box-shadow: inset 0px 0px 0px 1px var(--v-primary-lighten4);
}
</style>