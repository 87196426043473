<template lang="pug">
  div.relative.base-scroll-body(style="overflow-x: hidden")
    template( v-if="isTreeInPreparation && selectedProjectDict" )
       v-progress-linear.absolute( indeterminate color="accent" )

    div.d-flex.px-2.pt-1
      v-select.dash-border-select.dash-size.mr-1.flex-1(
        :items="projectDicts"
        :value="selectedProjectDict"
        @input='setSelectedProjectDict'
        item-text="title"
        item-value="uuid"
        color="#3B93AF"
        :menuProps="menuProps"
        flat
        solo
        clearable
        open-on-clear
        dense
        outlined
        hideDetails
        :label="$t('section.classification.attribute.selectDictionary')"
      )
      app-text-field.ml-1.flex-1( v-model="searchAttribute" @input="handInut()" label="Поиск по атрибуту" hide-details outlined dense clearable )

    v-btn.mt-4.px-2( v-if="!selectedProjectDict && hasClassificatorControlUniversalAttr" text color="accent" x-small @click="showAddDictDialog()" )
      v-icon.mr-1 mdi-plus-circle-outline
      span Добавить Справочник

    div.mt-2( v-if="selectedProjectDict" )
      template( v-if="selectedProjectDictAttributeTree.length > 0" )
        v-btn.px-2( color="accent" dense text x-small @click="() => (allIsOpenedGroup ? closeAll() : openAll())" )
          v-icon.mr-1( color="accent" size="16" left ) unfold_more
          | {{ allIsOpenedGroup ? $t('section.elementTree.axis.collapseTree') : $t('section.elementTree.axis.expandTree') }}

    div.mt-4
      template( v-if="selectedProjectDict" )  
        div( v-for="group in sortedGroup" )
          div
            v-hover( v-slot:default="{ hover }" )
              div.d-flex.align-center.description-block( @click="toggleOpenedGroup(group)" )
                v-icon.icon-size {{ openedGroups.includes(group.uuid) ? 'mdi-menu-down' : 'mdi-menu-right' }}
                b.text.group( @contextmenu.prevent="showAttributeGroupMenu($event, group)" ) {{ group.name }}
                v-icon.icon-size.ml-auto( v-if="hover && hasClassificatorControlUniversalAttr" tag="button" color="#303030" @click.stop="showAttributeGroupMenu($event, group)" ) more_vert
            div
              div( v-if="openedGroups.includes(group.uuid)" )
                div( v-for="attribute in sortAttribute(group.childs, group)" )
                  v-hover( v-slot:default="{ hover }" )
                    div.d-flex.align-center.description-block( :class="{activ: attribute.uuid === (selectedAttribute && selectedAttribute.uuid)}" )
                      div.pl-6.attribute( @click="setSelectedAttribute(attribute)" @contextmenu.prevent="showAttributeMenu($event, attribute)" ) {{ attribute.name }}
                      v-icon.icon-size.ml-auto( v-if="hover && hasClassificatorControlUniversalAttr" tag="button" color="#303030" @click.stop="showAttributeMenu($event, attribute)" ) more_vert

    //- attribute-tree

    v-btn.mt-4.px-2( v-if="selectedProjectDict" text color="accent" :disabled="!selectedProjectDict || !hasClassificatorControlUniversalAttr" x-small @click="showAddGroupDialog()" )
      v-icon.mr-1 mdi-plus-circle-outline
      span {{ $t('section.classification.attribute.addGroup') }}

    app-menu( ref="attributeGroupMenu" :menu="attributeGroupMenu" )
    app-menu( ref="attributeMenu" :menu="attributeMenu" )

    app-dialog( v-model="dialog.addGroup" :header="$t('section.classification.attribute.addGroup')" :confirm-text="$t('button.add')" @confirm="createGroup" :confirmDisabled="!attributeGroupName.trim()")
      app-section
        app-text-field( v-model="attributeGroupName" :label="$t('section.classification.attribute.nameGroup')" outlined )

    app-dialog( v-model="dialog.addAttribute" :header="$t('section.classification.attribute.addAttributeGroup')" :confirm-text="$t('button.add')" @confirm="_createAttribute" :confirmDisabled="!attributeName.trim()" )
      app-section
        app-text-field( v-model="attributeName" :label="$t('section.classification.attribute.nameAttribute')" outlined )

    add-project-dict-dialog( v-model="dialog.addDict" @createDict="createDict($event)")

    app-dialog-confirm( v-if="selectedGroup" v-model="dialog.deleteGroup" delete @confirm="delGroup") 
      | {{ $t('section.classification.attribute.deleteGroup') }} "{{ selectedGroup.name }}"?

    app-dialog-confirm( v-if="selectedAttribute" v-model="dialog.deleteAttribute" delete @confirm="onDeletingAttribute") 
      | {{ $t('section.classification.attribute.deleteAttribute') }} "{{ selectedAttribute.name }}"?

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AddProjectDictDialog from './AddProjectDictDialog.vue'
// import AttributeTree from './components/attribute/AttributeTree'

export default {
  name: 'project-dict-classification',

  components: {
    AddProjectDictDialog,
    // AttributeTree
  },

  props: {
    menuProps: {
      type: [String, Object, Array],
      default: () => ({ 
        bottom: true, offsetY: true, contentClass: 'menu-scroll', light: true
      })
    },
  },

  data() {
    return {
      dialog:{
        addGroup: false,
        addAttribute: false,
        addDict: false,
        deleteGroup: false,
        deleteAttribute: false
      },
      openedGroups: [],
      attributeGroupName: '',
      attributeName: '',
      selectedGroup: null,
      searchAttribute: '',
      chosenAttribute: null,
    }
  },

  beforeMount(){
    this.loadProjectDicts()
  },

  watch: {
    selectedProjectDict(){
      this.openedGroups = []
    }
  },

  computed: {
    ...mapState('projectDict', ['projectDicts', 'selectedProjectDict', 'selectedProjectDictAttributeTree', 'selectedAttribute', 'isTreeInPreparation']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),


    attributeGroupMenu(){
      return [
        { title: this.$t("section.classification.attribute.addAttributeGroup"), action: this.showAddAttributeDialog },
        { title: this.$t('section.classification.attribute.classifyGroup'), action: () => this.classifyGroup(this.selectedGroup) },
        { title: this.$t('section.classification.attribute.delete'), action: this.showDeleteGroupDialog }
      ]
    },

    attributeMenu() {
      return [
        { title: this.$t('section.classification.attribute.classifyAttribute'), action: () => this.classifyAttribute(this.chosenAttribute) },
        { title: this.$t('section.classification.attribute.delete'), action: this.showDeleteAttributeDialog }
      ]
    },

    allIsOpenedGroup() {
      return this.openedGroups.length === this.selectedProjectDictAttributeTree.length;
    },

    sortedGroup() {
      if (this.searchAttribute) {
        const foundGroups = [];
        const filteredTree = this.selectedProjectDictAttributeTree.filter((item) => {
          const childs = item.childs?.map(({ name }) => name.toLowerCase()) ?? [];
          const arr = [item.name.toLowerCase(), ...childs];
          const containsSearchAttribute = arr.some(e => e.includes(this.searchAttribute.toLowerCase()));
          if (containsSearchAttribute) {
            foundGroups.push(item)
          }
          return containsSearchAttribute;
        });
        
        foundGroups.forEach(group => {
          if(!this.openedGroups.includes(group.uuid)) {
            this.toggleOpenedGroup(group)
          }
        });

        return filteredTree;
        
      }
      else {
        return this.selectedProjectDictAttributeTree.slice().sort((a, b) => a.name.localeCompare(b.name))
      }
    },
  },
  methods: {
    ...mapActions('projectDict', ['setSelectedProjectDict', 'loadProjectDicts', 'createAttributeGroup', 'classificateAttribute',
                                  'createAttribute', 'setSelectedAttribute', 'createProjectDict', 'deleteElement']),

    handInut() {
      if (!this.searchAttribute) {
        this.closeAll()
      }
    },

    classifyAttribute(attribute) {
      this.classificateAttribute({
        projectUuid: this.projectUuid,
        projectAttributeDictionaryUuid: null,
        attributeDictionaryUuid: attribute.uuid 
      })
    },

    classifyGroup(group) {
      this.classificateAttribute({
        projectUuid: this.projectUuid,
        attributeDictionaryUuid: group.uuid
      })
    },
    
    sortAttribute(childs) {
      return childs.slice().sort((a, b) => a.name.localeCompare(b.name))
    },

    toggleOpenedGroup(group) {
      this.openedGroups.includes(group.uuid)
        ? (this.openedGroups = this.openedGroups.filter((g) => g !== group.uuid))
        : this.openedGroups.push(group.uuid);
    },

    openAll() {
      this.openedGroups = this.selectedProjectDictAttributeTree.map((group) => group.uuid);
    },

    closeAll() {
      this.openedGroups = [];
    },

    showAttributeGroupMenu(event, group) {
      if(this.hasClassificatorControlUniversalAttr) this.$refs.attributeGroupMenu.show(event)
      this.selectedGroup = group
      if(!this.openedGroups.includes(group.uuid)) {
        this.toggleOpenedGroup(group)
      }
    },

    showAttributeMenu(event, attribute) {
      if(this.hasClassificatorControlUniversalAttr) this.$refs.attributeMenu.show(event)
      this.chosenAttribute = attribute
    },

    showAddGroupDialog() {
      this.dialog.addGroup = true
      this.attributeGroupName = ''
    },

    createGroup() {
      this.createAttributeGroup({
        attributeGroup: {
          name: this.attributeGroupName
        },
        projectDictUuid: this.selectedProjectDict
      })
      this.dialog.addGroup = false
    },

    showAddAttributeDialog() {
      this.dialog.addAttribute = true
    },

    _createAttribute() {
      this.createAttribute({
        attribute: {
          name: this.attributeName,
          matchingAttributes: [],
          classificatorNode: [],
        },
        attributeGroupUuid: this.selectedGroup.uuid
      })
      this.dialog.addAttribute = false
      this.attributeName = ''
    },

    showAddDictDialog() {
      this.dialog.addDict = true
    },

    createDict(dictName) {
      this.createProjectDict({
        title: dictName,
        projectUuid: this.projectUuid
      })
      this.dialog.addDict = false
    },

    showDeleteGroupDialog() {
      this.dialog.deleteGroup = true
    },

    delGroup() {
      this.deleteElement(this.selectedGroup.uuid)
      this.dialog.deleteGroup = false
    },

    showDeleteAttributeDialog() {
      this.dialog.deleteAttribute = true
    },

    onDeletingAttribute() {
      this.deleteElement(this.selectedAttribute.uuid)
      this.dialog.deleteAttribute = false
    },
  }
}
</script>

<style lang='scss' scoped>
.description-block:hover {
  background-color: var(--v-primary-lighten5);
  min-height: 20px;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}

.icon-size {
  font-size: 16px;
}
.group {
  color: #303030;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 6px;
  min-height: 26px;
  padding-top: 4px;
  width: 100%;
  cursor: pointer;
}

.attribute {
  color: #303030;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 6px;
  min-height: 26px;
  padding-top: 4px;
  width: 100%;
  cursor: pointer;
}

.activ {
  background-color: var(--v-primary-lighten5);
}

.no-group {  
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.38);
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-1 {
  flex: 1;
}
.dash-size {
  height: 42px;
  width: 48%;
  display: inline-block;
}
::v-deep.dash-border-select .v-label,
::v-deep.dash-border-select .v-icon,
::v-deep.dash-border-select .v-select__selections,
::v-deep.dash-border-select .v-select__selections .v-select__selection,
::v-deep.dash-border-select .v-input__append-inner  {
  color: #3B93AF;
}
::v-deep.dash-border-select.v-text-field--outlined fieldset {
  border-color: var(--v-accent-base);
  border-style: dashed;
  color: #3B93AF;
}
</style>