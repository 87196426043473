<template lang="pug">
div
  app-section(:class="defaultClass ? 'h-100' : ''")
    draggable(v-model="conditionRows" @change="changeDraggable")
      v-row(no-gutters v-for="(row, index) in conditionRows" :key="index")
        v-col.d-flex.align-center(cols='auto')
          v-btn.mt-1(v-if='row.attr.uuid' height="16" width="16" color='#505254' x-small icon test-id="copy-expression-row" @click='copyExpression(row)')
            v-icon(x-small) mdi-content-copy
        v-col(cols="auto")
          v-menu
            template(v-slot:activator="{ on }")
              v-btn.mt-1.px-0( color="primary" dense text v-on="on" small min-width="20" test-id="first-staples") 
                span(v-if="row.start!=null && row.start!=''") {{ row.start }}
                v-icon(v-else color="primary" small) arrow_drop_down
            v-list.p-0(light dense)
              v-list-item.body-2(v-for="(item, ind) in startBracket" :key="ind" @click="setValue(index, 'start', item)") {{ item }}
        v-col.overflow-hidden
          div.pseudoinput.d-flex.flex-row-reverse.flex-nowrap.mt-2.pa-1.rounded(@click="editCondition(row.attr)")
            app-text.text-nowrap {{row.attr.title}}
        v-col(cols="auto")
          v-menu
            template(v-slot:activator="{ on }")
              v-btn.mt-1.px-0( color="primary" dense text v-on="on" small min-width="20" test-id="last-staples") 
                span(v-if="row.end!=null && row.end!=''") {{ row.end }}
                v-icon(v-else color="primary" small) arrow_drop_down
            v-list.p-0(light dense)
              v-list-item.body-2(v-for="(item, ind) in endBracket" :key="ind" @click="setValue(index, 'end', item)") {{ item }}
        v-col(cols="auto")
          v-menu
            template(v-slot:activator="{ on }")
              v-btn.mt-1.px-0( color="primary" dense text v-on="on" small min-width="28" test-id="logic-operator") 
                span.caption(v-if="row.oper!=null && row.oper.title!=''") {{ row.oper.title }}
                v-icon(v-else color="primary" small) arrow_drop_down
            v-list.p-0(light dense)
              v-list-item.body-2(v-for="item in oper" :key="item.value" @click="setValue(index, 'oper', item)") {{ item.title }}
        v-col.mt-1(cols="auto" v-if="lockable")
          app-tooltip(:title="$t('section.worm.blockingSubstitution')" bottom)
            v-icon.mt-2.ml-1( color="grey" @click.stop="toggleLockCond(row)" small ) {{ row.attr.locked ? 'mdi-lock' : 'mdi-lock-open' }}
        v-col.mt-1(cols="auto")
          v-icon.mt-2.ml-1( color="grey" @click.stop="removeCond(index)" small test-id="clear-btn") clear
    
    .d-flex
      .d-inline-flex.align-center.add-container.mt-4( test-id="create-condition-btn" @click="createCondition" )
        v-icon.icon-wrapper( size="18" ) add
        .section-header.ml-3 {{ $t('section.elementTree.addCalcCondition') }}

      .d-inline-flex.align-center.add-container.mt-4(v-if='clipboardFilled' @click="pasteConditions()" test-id="paste-expression-row")
        v-icon.icon-wrapper( size="18" ) mdi-content-paste
        .section-header.ml-3 {{ $t('section.classification.clipboard.paste') }}

  simple-condition(v-model="dialogСondition" :condition.sync="condition" :axisFilter="axisFilter" :simpleView="true" :calcType="calcType" @created="afterCreateCondition")

  v-snackbar(v-model="errorSnack" color="error" :timeout="6000" vertical top) {{errorSnackText}}
    v-btn(light text @click="errorSnack = false") {{ $t('button.clear') }}

</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import SimpleCondition from './Condition'
import {$_parse} from './calcParse'
import i18n from '@/plugins/i18n'

export default {
  name: 'LogicGroupCalc',
  mixins: [$_parse],
  components: { SimpleCondition, draggable },
  props: {
    logicGroup: null,
    defaultClass: {
      type: Boolean,
      default: true
    },
    calcType: {
      type: String,
      default: ''
    },
    lockable: {
      type: Boolean,
      default: false
    },
    axisFilter: Array
  },
  data () {
    const defaultConditionRow = Object.freeze({
      start: null,
      attr: null,
      end: null,
      oper: null
    })

    return {
      errorSnackText: i18n.t('section.elementTree.errorSnackText'), 
      errorSnack: false,
      valid: false,
      dialogСondition:  false,
      defaultConditionRow,
      conditionRows: [],
      condition: null
    }
  },
  watch: {
    logicGroup (value) {
      if (value) {
        this.updateForm()
      }
    },
  },
  computed: {
    ...mapGetters('project', ['calcOperator', 'isCalcOperatorLoaded']),
    ...mapState('copyutil', ['copiedUnitExpressionConditions','copiedTitleAttributeConditions']),

    startBracket() {
      return ["", "(", "((", "(((", "(((("]
    },                                    
    endBracket() {                        
      return ["", ")", "))", ")))", "))))"]
    },
    oper() {
      let l = this.calcOperator.slice(0)
      l.unshift({"value": -1, "title": "", "name": ""})

      if (this.calcType != 'TITLECALC') l = l.filter(el => el.name!='CONCAT')

      return l
    },

    clipboardFilled() {
      if (this.calcType === 'TITLECALC') return this.copiedTitleAttributeConditions.length > 0
      else return this.copiedUnitExpressionConditions.length > 0
    }
  },
  methods: {
    ...mapMutations('condition', ['setReducedСonditions'] ),

    changeDraggable(){
      this.conditionRows.forEach((cond, index) => {
        if(cond.oper == null && index < this.conditionRows.length-1) cond.oper=this.calcOperator[0]
        else if( index == this.conditionRows.length-1) cond.oper=null
      })
      this.$emit('changed', this.logicGroup)
    },
    setValue(index, param, val) {
      this.conditionRows[index][param] = val
      this.$emit('changed', this.logicGroup)
    },
    showErrorSnack(text) {
      this.errorSnackText = text
      this.errorSnack = true
    },
    toggleLockCond(condRow){
      condRow.attr.locked = !condRow.attr.locked
    },
    removeCond(index) {
       this.conditionRows.splice(index, 1)
       this.$emit('changed', this.logicGroup)
    },
    createCondition() {
      this.setReducedСonditions( true )
      this.condition = null
      this.dialogСondition = true
    },
    editCondition(cond) {
      this.condition = cond
      this.dialogСondition = true
    },
    afterCreateCondition(cond) {
      if(this.condition==null) {
        if(this.conditionRows.length>0){
          let prevCond = this.conditionRows[this.conditionRows.length-1]
          if(prevCond.oper==null) prevCond.oper=this.calcOperator[0]
        }
        
        let newRow = Object.assign({}, this.defaultConditionRow)
        newRow.attr = cond
        this.conditionRows.push(newRow)
      } else {
        Object.assign(this.condition, cond)
      }
      this.dialogСondition=false

      this.$emit('changed', this.logicGroup)
    },
    makeLogicGroup() {
      let lg_uuid = this.logicGroup.uuid!==undefined ? this.logicGroup.uuid : null
      let newLogicGroup = {}
      newLogicGroup.uuid = lg_uuid

      if(this.conditionRows.length>0){
        let prepareCondition = this.$parse_to_back(this.conditionRows)
        if( prepareCondition.status == "OK" ){
           newLogicGroup = prepareCondition.expr
           newLogicGroup.uuid = lg_uuid
           return newLogicGroup
        } else {
           this.showErrorSnack(prepareCondition.err)
           return null
        }
      } else 
        return newLogicGroup
    },
    makeConditionRow(logicGroup, start, end, prevOperatorType) {

      let rows = []
      if(logicGroup.condition)
        logicGroup.condition.forEach(cond => rows.push(cond))
      if (logicGroup.children) 
        logicGroup.children.forEach(lg => rows.push(lg))
      rows.sort((a, b) => a.sortOrder - b.sortOrder)
      
      rows.forEach((row, index) => {
        if(row.calcOperatorType !== undefined ){
            if(rows.length==1) 
                this.makeConditionRow(row, start+"(", end+")", prevOperatorType)
            else if(index==0) 
                this.makeConditionRow(row, start+"(", ")", logicGroup.calcOperatorType)
            else if(index<rows.length-1) 
                this.makeConditionRow(row, "(", ")", logicGroup.calcOperatorType)
            else                                  
                this.makeConditionRow(row, "(", end+")", prevOperatorType)
        } else {
            let newRow = Object.assign({}, this.defaultConditionRow)
            newRow.attr = row
            if(index==0 || index<rows.length-1) 
               newRow.oper = logicGroup.calcOperatorType
            else if(prevOperatorType!=null)
               newRow.oper = prevOperatorType
            if(index==0 ) {
               newRow.start=start
               start=""
            }
            if(index==rows.length-1) {
               newRow.end=end
               end=""
            }
            this.conditionRows.push(newRow)
        }
      })
    },

    makeConditionRow_(logicGroup, end, prevOperatorType) {
      if(logicGroup.condition!=null){
        if(logicGroup.condition.length==0) end=""
        logicGroup.condition.forEach((cond, index) => {
           let newRow = Object.assign({}, this.defaultConditionRow)
           newRow.attr = cond
           if(index==0 || index<logicGroup.condition.length-1 || (index==logicGroup.condition.length-1 && logicGroup.children.length>0) ) 
              newRow.logic = logicGroup.logicOperatorType
           else if(prevOperatorType!=null)
              newRow.logic = prevOperatorType
           if(index==0 ) 
              newRow.start="("
           if(index==logicGroup.condition.length-1 && (logicGroup.children === undefined || logicGroup.children.length==0) ) {
              newRow.end=end
              end=""
           }
           this.conditionRows.push(newRow)
        })
      }
      if(logicGroup.children!=null){
        logicGroup.children.forEach((lg, index) => {
           if(index<logicGroup.children.length-1 ) 
               this.makeConditionRow(lg, end+")", logicGroup.logicOperatorType)
           else
               this.makeConditionRow(lg, end+")", null)
        })
      }
    },

    updateForm() {
      this.conditionRows = []
      this.makeConditionRow(this.logicGroup, "", "", null)
    },

    copyExpression(row) {
      let expression = row.attr
      this.$emit('copy', expression)
    },

    pasteConditions() {
      if (this.calcType === 'TITLECALC') {
        this.copiedTitleAttributeConditions.forEach(condition => this.afterCreateCondition(JSON.parse(JSON.stringify(condition))))
      } else {
        this.copiedUnitExpressionConditions.forEach(condition => this.afterCreateCondition(JSON.parse(JSON.stringify(condition))))
      }
      
    }
  },
  mounted() {
    this.updateForm()
  }
}

</script>

<style lang="scss" scoped>
.add-container {
  cursor: pointer;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #C4C4C4;
}
.section-header {
  font: normal 12px/16px $roboto;
  color: #3B93AF;
}
.icon-wrapper {
  background-color: #C4C4C4;
  border-radius: 2px;
}

.h-100 {
  height:90%;
  max-height:500px;
  min-height:340px;
  overflow: auto;
}
.w85 {
  width:85px;
}
.w70 {
  width:70px;
}
.w24 {
  width:24px;
}
.abs-but {
  position: absolute;
}
.text-nowrap {
  white-space: nowrap;
}
.pseudoinput {
  cursor: pointer;
  background-color: #f0f0f0;
}
</style >
