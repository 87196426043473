<template lang="pug">
.d-flex.flex-column
  v-btn.dict-button.mb-3.mr-2.ml-2( color="accent" small @click="isUpdateDialogOpened = true" ) Обновление классификатора
  .d-flex.mb-2.justify-space-between
    v-btn.dict-button.px-1( color="accent" dense text x-small test-id="smeta-merge-expand-collapse" @click="handleToggleAll" )
      v-icon.mr-1( color="accent" size="16" left ) unfold_more
      | {{ areAllTreeNodesOpened ? $t('section.elementTree.axis.expandTree') : $t('section.elementTree.axis.collapseTree') }}
    smeta-tree-filter-menu
      smeta-update-tree-filter-list
  .mb-4
    v-divider.mb-1.mt-1( horizontal )
    .base-scroll-body.pa-1( id="merged-tree-table" ref="mergedTreeTable" )
      .smeta-merged-tree__wrap( ref="mergedTreeVirtualTable" :style="{ 'padding-top': `${scroller.firstRowHeight}px`, 'padding-bottom': `${scroller.lastRowHeight}px` }" )
        //- .first-row( :style="{height: `${scroller.firstRowHeight}px`}" )
        template( v-if="!smetaMergeSearchText" )
          div( v-for="(item, index) in getElements" :key="item.uuid" )
            .smeta-merged-tree__wrap-item( :style="`min-height: 24px`" )
              .d-flex.align-center.wrap-item__title
                app-icon-button( v-if="item.isNode && item.children.length" :style="`margin-left: ${ item.element.level * calcOffset(item.element.type) }px;`" :icon="item.expanded ? 'mdi-menu-down' : 'mdi-menu-right'" iconSize="16px" buttonSize="24px" @click="handleToggleNode(item.uuid, item)" )
                .wrap-item__title(:style="getMarginByLevel(item)") {{ item.element.code }} {{ item.element.title.oldValue }} 
              .d-flex.align-center
                app-tooltip( v-if="!item.disabled" bottom :title="getTranslate(item)"  )
                  app-icon-button( v-if="!item.disabled"  :iconColor="getColor(item.element.state)" @click="handleClick(item)" :icon="getIcon(item)" )
                app-tooltip(v-if="getAlertTitle(item)" bottom :title="getAlertTitle(item)")
                  app-icon-button( iconColor="yellow darken-1" icon="mdi-alert-box-outline")
        template( v-else )
          div( v-for="(item, index) in getElements" :key="item.uuid" )
            .smeta-merged-tree__wrap-item( style="min-height: 24px;" )
              .d-flex.align-center.wrap-item__title
                .wrap-item__title {{ item.element.code }} {{ item.element.title.oldValue }}
              .d-flex.align-center
                app-tooltip( v-if="!item.disabled" bottom :title="getTranslate(item)"  )
                  app-icon-button( v-if="!item.disabled"  :iconColor="getColor(item.element.state)" @click="handleClick(item)" :icon="getIcon(item)" )
                app-tooltip(v-if="getAlertTitle(item)" bottom :title="getAlertTitle(item)")
                  app-icon-button( iconColor="yellow darken-1" icon="mdi-alert-box-outline")
              
        //- .last-row( :style="{height: `${scroller.lastRowHeight}px`}" )
  v-btn.dict-button.mb-3.mr-2.ml-2.accept-button_position( :disabled="!mergedItemsLength" x-small color="accent" @click="applyMerge" ) Применить изменения
  smeta-merge-dialog( v-model="isUpdateDialogOpened" :classificatorUuid="classificatorUuid" @merge="mergeClassificators" )
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { SmetaMergedElementsScroller } from '../utils/SmetaMergedElementsScroller.js'
import SmetaUpdateTreeFilterList from '../filters/SmetaUpdateTreeFilterList.vue'
import SmetaTreeFilterMenu from '../filters/SmetaTreeFilterMenu.vue'
import SmetaMergeDialog from '../forms/SmetaMergeDialog.vue'
import { SmetaMergedElement } from '@/assets/model/smeta/merge/SmetaMergedElement.js'

export default {
  components: {
    SmetaUpdateTreeFilterList,
    SmetaTreeFilterMenu,
    SmetaMergeDialog
  },

  props: {
    classificatorUuid: null
  },

  data() {
    return {
      scroller: new SmetaMergedElementsScroller(),
      isUpdateDialogOpened: false,
      debounceFetchElements: null,
    }
  },

  computed: {
    ...mapState('smetaMerge', ['mergedItemsLength', 'smetaMergeSearchText']),
    ...mapGetters('smetaMerge', ['getMergedElement', 'getMergedElements']),

    getElements() {
      return this.getMergedElements(this.scroller.startIndex, this.scroller.startIndex + this.scroller.step)
    }, 

    areAllTreeNodesOpened() {
      if (this.mergedItemsLength == SmetaMergedElement.rootElements) return true
      if (SmetaMergedElement.totalVisible < SmetaMergedElement.allElements ) return true
      else return false
    }
  },

  methods: {
    ...mapActions('smetaMerge', ['mergeVersions', 'fetchMergedElements', 'toggleNode', 'applyAcceptance', 'applyMergeVersions', 'expandAll', 'collapseAll', 'expandItem']),
    ...mapMutations('smetaMerge', ['setMergeItemsLength']),

    handleScroll() {
      this.scroller.handleScroll(this.$refs.mergedTreeVirtualTable.getBoundingClientRect().top, this.mergedItemsLength)
    },

    handleToggleNode(key, item) {
      item
      this.toggleNode(key)
      this.setMergeItemsLength(SmetaMergedElement.totalVisible)
      this.expandItem(key)
      this.scroller.handleScroll(0, 0)
      this.scroller.handleScroll(this.$refs.mergedTreeVirtualTable.getBoundingClientRect().top, this.mergedItemsLength)
    },

    getMarginByLevel(item) {
      if (item.isNode && !item.children.length) return `margin-left: ${ item.element.level * this.calcOffset(item.element.type) + 24 }px`
      if (!item.isNode && !item.children.length) return `margin-left: ${ item.element.level * this.calcOffset(item.element.type) + 8 }px`
      return ''
    },

    handleToggleAll() {
      this.areAllTreeNodesOpened ? this.expandAll() : this.collapseAll()
      this.setMergeItemsLength(SmetaMergedElement.totalVisible)
      this.scroller.handleScroll(0, 0)
      this.scroller.handleScroll(this.$refs.mergedTreeVirtualTable.getBoundingClientRect().top, this.mergedItemsLength)
    },

    calcOffset(type) {
      if (type == 'TOPIC' || type == 'NODE') return 8
      return 12
    },

    getTranslate(item) {
      switch (item.element.state) {
        case "OK": return "Общий"
        case "DELETE": return item.accepted ? "Отменить удаление" : "Применить удаление"
        case "INSERT": return item.accepted ? "Отменить добавление" : "Применить добавление"
        case "UPDATE": return item.accepted ? "Отменить обновление" : "Применить обновление"
      }
    },

    getColor(state) {
      switch (state) {
        case "OK": return "var(--v-primary-darken2)"
        case "DELETE": return "var(--v-alert-base)"
        case "INSERT": return "var(--v-success-base)"
        case "UPDATE": return "var(--v-accent-base)"
      }
    },

    getIcon(item) {
      switch (item.element.state) {
        case "OK": return "mdi-text-box-outline"
        case "DELETE": return item.accepted ? "mdi-cancel" : "mdi-minus-box-outline"
        case "INSERT": return item.accepted ? "mdi-cancel" : "mdi-plus-box-outline"
        case "UPDATE": return item.accepted ? "mdi-cancel" : "mdi-autorenew"
      }
    },

    
    getAlertTitle(item) {
      return item.getTitle()
    },

    mergeClassificators(payload) {
      this.isUpdateDialogOpened = false
      this.mergeVersions(payload).then(() => {
        this.scroller.initScroll(this.$refs.mergedTreeVirtualTable.getBoundingClientRect().top, this.mergedItemsLength)
        document.getElementById('merged-tree-table').onscroll = this.handleScroll
        this.from = this.scroller.startIndex
        this.to = this.scroller.startIndex + this.scroller.step
      })
    },

    handleClick(item) {
      if (item.element.state != "OK") {
        this.applyAcceptance(item.uuid)
        this.scroller.handleScroll(0, 0)
        this.scroller.handleScroll(this.$refs.mergedTreeVirtualTable.getBoundingClientRect().top, this.mergedItemsLength)
      }
    },

    applyMerge() {
      this.applyMergeVersions().then(() => {
        this.$emit('closeMerge')
      })
    }
  },

  mounted() {
    if (this.mergedItemsLength) {
      document.getElementById('merged-tree-table').onscroll = this.handleScroll
    }
  }
}
</script>

<style scoped>
.smeta-merged-tree__wrap {
  display: flex;
  flex-direction: column;
}
.smeta-merged-tree__wrap-item {
  color: #000;
  display: flex;
  gap: 10px;
  font-size: 12px;
  height: 24px;
  justify-content: space-between;
}
.wrap-item__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.base-scroll-body {
  height: 590px;
}
.count-block {
  color: #000;
  font-size: 12px;
  justify-content: space-between;
  display: flex;
}

.accept-button_position {
  position: sticky;
  bottom: 5px;
}
</style>