<template lang="pug">
  div
    profile-form(v-model="$_show" :user="formUser" :title="$t('registration.title')"  isRegistration @click="registration()" @close="handleClose" :noDialog="noDialog" )

    app-dialog(v-model="dialog.info" :header="$t('registration.info')")
      app-section 
        span(style="color:black") {{ infoText }}

    AccessLogin(v-model="dialog.login" :noDialog="true")
    

</template>

<script>
import { mapGetters } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'
import Login from './Login'

//import { authUserService } from '@/_services/'
import { api } from '@/api'
import ProfileForm from '@/components/admin/users/ProfileForm'
//import { App } from '@app/App'

import { AlertService } from '@app/AlertService'

export default {
  name: 'AccessRegistation',

  components: {
    ProfileForm,
    [Login.name]: Login
  },

  props: {
    email: {
      type: String,
      default: ''
    },
    noDialog: {
      default: false,
      type: Boolean
    },
  },

  mixins: [DialogToggable],

  data () {
    const defaultForm = Object.freeze({
      uuid:	null,
      email: null,
      name: null,
      phone: null,
      archive: null,
      password: null,
    })
    return {
      dialog: {
        info: false,
        login: false
      },
      infoText: "",
      showPSWD: false,
      formUser: Object.assign({}, defaultForm),
      defaultForm,
    }
  },

  watch: {
    $_show (value) {
      if (value) {
        let baseObj = this.ProfileForm || this.defaultForm
        this.formUser = JSON.parse(JSON.stringify(baseObj))
      }
    },
  },

  mounted () {
    if(this.email!='') this.formUser.email = this.email
  },
  
  computed: {
    ...mapGetters('roles', ['roles']),
  },
  methods: {
    registration () {
        let obj = {
          profile: this.formUser,
          password: this.formUser.password
        }

        api.users.registration(obj).then(data => {
          if (window.settings.server.registrationEmail) {
            this.infoText = this.$t('section.сonfiguration.linkSent', {emil: this.formUser.email})
            AlertService.success({info: this.infoText})
          } else {
            console.log(data);
            localStorage.setItem("profile", "")//data
            this.login()
          }

          this.$metrika.reachGoal('REGISTRATION', {user_email: this.formUser.email })
          this.$_show = false
          this.dialog.login = true
        })
    },

    login () {
      /*authUserService.login(this.formUser.email, this.formUser.password).then(status => {
        if (status) {
          this.$store.dispatch('authUser/LOAD_CURRENT_USER')
          localStorage.setItem("loginName", this.formUser.email)
          if (App.auth.isAdmin) 
            return this.$router.push('/admin/users')
          return this.$router.push('/dashboard')
        } else {
          this.error = this.$t('error.loginPassword')
        }
      })*/
      window.location = "/login?fromext";
    },
    
    resetError () {
      this.error = null
    },

    handleClose() {
      this.$emit('close')
    }
  },
  
}
</script>