<template lang="pug">
ProjectToolWindowAbstraction( :title="$t(titleKey)" )
  template( v-if="hasToolbar" #toolbar )
    component( :is="toolbarComponentName" )
  
  template( v-if="hasActionbar" #actionbar )
    component( :is="actionbarComponentName" )

  component( :is="contentComponentName" )
</template>

<script>
import { ViewerTool, useViewerToolsStore, useViewerPluginsStore, ViewerPlugin } from "@/pinia"
const store = useViewerToolsStore()
const pluginStore = useViewerPluginsStore()

// const availableToolbars = new Set([ViewerTool.GROUPS, ViewerTool.SCENE, ViewerTool.PLUGINS])
const availableToolbars = new Set([ViewerTool.GROUPS, ViewerTool.PLUGINS])
const availableActionbars = new Set([ViewerTool.GROUPS, ViewerTool.SCENE, ViewerTool.COLORS])
const availablePluginActionbars = new Set([ViewerPlugin.MEASUREMENTS, ViewerPlugin.SECTION_PLANES, ViewerPlugin.SECTION_CUBE, ViewerPlugin.COORDINATE_PICKER])

export default {
  components: {
    ProjectToolWindowAbstraction: () => import('./windowAbstraction/ViewerToolWindowAbstraction.vue'),

    [`${ViewerTool.GROUPS}__Toolbar`]: () => import('./groups/ViewerToolGroupsToolbar.vue'),
    [`${ViewerTool.GROUPS}__Actionbar`]: () => import('./groups/ViewerToolGroupsActionbar.vue'),
    [`${ViewerTool.GROUPS}__Content`]: () => import('./groups/ViewerToolGroupsContent.vue'),

    [`${ViewerTool.SCENE}__Toolbar`]: () => import('./scene/ViewerToolSceneToolbar.vue'),
    [`${ViewerTool.SCENE}__Actionbar`]: () => import('./scene/ViewerToolSceneActionbar.vue'),
    [`${ViewerTool.SCENE}__Content`]: () => import('./scene/ViewerToolSceneContent.vue'),

    [`${ViewerTool.PLUGINS}__Toolbar`]: () => import('./plugins/ViewerToolPluginsToolbar.vue'),
    [`${ViewerTool.PLUGINS}__Actionbar`]: () => import('./plugins/ViewerToolPluginsActionbar.vue'),
    [`${ViewerTool.PLUGINS}__Content`]: () => import('./plugins/ViewerToolPluginsContent.vue'),
    
    [`${ViewerTool.COLORS}__Actionbar`]: () => import('./colors/ViewerToolColorsActionbar.vue'),
    [`${ViewerTool.COLORS}__Content`]: () => import('./colors/ViewerToolColorsContent.vue'),
  },

  computed: {
    titleKey: () => `project.control.viewerTool.${store.activeTool}`,
    
    hasToolbar: () => availableToolbars.has(store.activeTool),
    hasActionbar: () => store.activeTool == ViewerTool.PLUGINS ? availablePluginActionbars.has(pluginStore.activeTab) : availableActionbars.has(store.activeTool),

    toolbarComponentName: () => `${store.activeTool}__Toolbar`,
    actionbarComponentName: () => `${store.activeTool}__Actionbar`,
    contentComponentName: () => `${store.activeTool}__Content`,
  },
}
</script>