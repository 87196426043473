<template lang="pug">
  .left-layer.align-self-stretch.fill-height.interactive.layers
    //- .tools(v-if="$app.version.isNotCloud && $app.version.isNotUndefined")
    .wiki-link
      app-tooltip( :title="$t('patch.knowledgeBase')" right )
        a( href="https://wiki.bimit.ru" target="_blank" ) ?
    .tools( id = "tools" v-if="$app.version.isNotCloud && $app.version.isNotUndefined")
      //- tool-layer-unit( icon="bimit_logo_white" @click.native="toDashboard" hint="Список проектов")
      
      app-toolbar.px-2
        //- tool-layer-unit( icon="class" :active="'class' === activeGlobalTab" @click.native="setUnit('class')" )
        app-toolbar-button( icon="$new-setup-icon" size="large" :active="'setup' === activeGlobalTab" test-id="project-structure" @click="setUnit('setup')" :title="$t('section.structure.title')")
        app-toolbar-button( icon="$new-project-icon" size="large" test-id="element-tree" :active="('show' === activeGlobalTab)" @click="setUnit('show')" :title="$t('section.elementTree.title')")
        app-toolbar-button( v-if="isPro && $_iot_isAvailable" icon="$class-icon" size="large" :active="('iot' === activeGlobalTab) " @click="setUnit('iot')" :title="$t('section.iot.title')")
        app-toolbar-button( v-if="isPro && hasCollision" icon="$new-project-collision-icon" size="large" test-id="collision" :active="('collision' === activeGlobalTab)" @click="setUnit('collision')" :title="$t('section.collision.title')")
        //- app-toolbar-button(v-if="showSmeta" icon="format_list_numbered" :active="'classification' === activeGlobalTab" @click="setUnit('classification')" :title="$t('section.classification.default')")
        app-toolbar-button(v-if="showNewSmeta && canViewClassificator" icon="$new-report-icon" size="large" test-id="classification" :active="('smeta' === activeGlobalTab)" @click="setUnit('smeta')" :title="$t('section.classification.default')") 
        app-toolbar-button(v-if="showWorm && canViewWorm" icon="$new-worm-icon" size="large" test-id="worm" :active="('wormpanel' === activeGlobalTab)" @click="setUnit('wormpanel')" :title="$t('section.worm.default')") 
        app-toolbar-button( icon="$new-drawing-icon" test-id="left-drawings" size="large" :active="('drawings' === activeGlobalTab)" @click="setUnit('drawings')" :title="$t('module.drawing.drawings')")
        app-toolbar-button(v-if="showMnemoschemas" icon="$new-mnemoschema-icon" size="large" test-id="mnemoschemas" :active="('mnemoschemas' === activeGlobalTab)" @click="setUnit('mnemoschemas')" :title="$t('module.mnemoschemas.title')")
        app-toolbar-button(v-if="showReport" icon="$new-format-list-icon" size="large" :active="('reports' === activeGlobalTab)" @click="setUnit('reports')" :title="$t('section.reports.default')")
        app-toolbar-button(v-if="showPlugin" icon="$new-plugins-icon" size="large" :active="('plugins' === activeGlobalTab)" @click="setUnit('plugins')" :title="$t('section.plugins.default')")
        app-toolbar-button(v-if="showFace"  icon="$new-photo-front-icon" size="large" :active="('face' === activeGlobalTab)" @click="setUnit('face')" :title="$t('corp.presenceControl')")
        app-toolbar-button(v-if="showWorkJournal" icon="$new-attach-file-icon" size="large" :active="('workjournal' === activeGlobalTab)" @click="setUnit('workjournal')" :title="$t('section.journal.title')")
        app-toolbar-button(v-if="showFileManager" icon="$new-cloud-icon" size="large" :active="('filemanager' === activeGlobalTab)" @click="setUnit('filemanager')" :title="$t('section.fileManager.title')")
        app-toolbar-button(v-if="showLocationControl" icon="$location-control-icon" size="large" :active="('locationcontrol' === activeGlobalTab)" @click="setUnit('locationcontrol')" :title="$t('section.locationControl.title')")
        app-toolbar-button( v-if="isPro && hasMaintaince  && showMaintenance" icon="$exploitation-icon" size="large" test-id="maintenance" :active="('maintenance' === activeGlobalTab)" @click="setUnit('maintenance')" :title="$t('section.maintenance.title')")
      
        
    .inline.fill-height(id = "leftpanelsize")
      project-panel-element-tree.width-panel( v-if="activeGlobalTab === 'show' || activeGlobalTab === 'iot' || $app.version.isCloud" )
      project-panel-structure( v-if="!$app.version.isCloud && activeGlobalTab === 'setup'" width="240" )
      project-panel-model( v-if="activeGlobalTab === 'setup' && !! selectedModel" )
      project-panel-model-patch( v-if="activeGlobalTab === 'setup' && !! selectedPatch && !selectedModel")
      project-panel-iot.mx-2( v-if="activeGlobalTab === 'iot' && !activeIotAdmin" width="320" )
      project-panel-iot-widget( v-if="activeGlobalTab === 'iot' && !activeIotAdmin" width="320")
      collision-control-panel.collision-panel( v-if="activeGlobalTab === 'collision'" )
      project-panel-drawings-list( v-if="activeGlobalTab === 'drawings'" ref='project-panel-drawings-list')
      project-panel-floors.floors-panel(v-if="activeGlobalTab === 'floors'")
      project-panel-clouds(v-if="activeGlobalTab === 'clouds'")
      project-mnemoschemas-panel(v-if="activeGlobalTab === 'mnemoschemas'")

      project-panel-workspaces(v-if="activeGlobalTab === 'workspaces'")

      project-panel-templates( v-if="showTemplates && (activeGlobalTab === 'templates') " )
      
      project-panel-api(v-if="(activeGlobalTab === 'api')")
      project-panel-members(v-if="(activeGlobalTab === 'rights') ")
      passport(v-if="(activeGlobalTab  === 'passport')")
      statistics(v-if="(activeGlobalTab  === 'statistics')")

      grids(v-if="activeGlobalTab === 'grids'")

      smeta-pannel( v-if="showNewSmeta && canViewClassificator && activeGlobalTab === 'smeta'" :width="halfWidth")
      worm-pannel( v-if="showWorm && canViewWorm && (activeGlobalTab === 'worm' || activeGlobalTab === 'wormpanel')" :width="halfWidth")

      face( v-if="showFace && (activeGlobalTab === 'face')" :width="thirdWidth" )

      plugins( v-if="(activeGlobalTab === 'plugins')" :width="halfWidth" )
      reports( v-if="(activeGlobalTab === 'reports')" width="320" )

      work-journal( v-if="(activeGlobalTab === 'workjournal')" :width="halfWidth")

      file-manager( v-if="(activeGlobalTab === 'filemanager')" :width="thirdWidth")

      maintenance-panel( v-if="(activeGlobalTab === 'maintenance') && hasMaintaince" :width="halfWidth")

      model-visual-settings( v-if="(activeGlobalTab === 'modelVisualSettings' || activeGlobalTab == 'modelvisualsettings')" :width="halfWidth" )

      location-control( v-if="(activeGlobalTab === 'locationcontrol')" :width="halfWidth" )

      forms.fill-height.tools( v-if="(activeGlobalTab === 'forms')")



      project-instruments



    v-snackbar(v-if="copiedElementAttribute" v-model="copyAttributeSnackBar" :multi-line="true" timeout="3000" color="green") 
      div {{ $t('section.elementTree.copyAttr') + ':'}}
      div {{ copiedElementAttribute.name }} {{ copiedElementAttribute.stringValue ? ' : '+copiedElementAttribute.stringValue : '' }}
      template(v-slot:action="{ attrs }")
        v-btn(color="red" icon v-bind="attrs" @click="cancelCopyAttribute" :title="$t('section.classification.clipboard.cancel')")
          v-icon mdi-close
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { panelEnum } from '@/store/sections/LayoutRepository'
import ProjectPanelFloors from '@@/project/panel/left/floors/Floors'
import ProjectPanelTemplates from '@@/project/panel/left/templates/Templates'
import ProjectPanelApi from '@@/project/panel/left/api/Api'
import ProjectPanelMembers from '@@/project/panel/left/rights/Members'
import Passport from '@@/project/panel/left/passport/Passport'
import Statistics from '@@/project/panel/left/passport/Statistics'
import ProjectPanelDrawingsList from "@@/project/panel/right/DrawingsList"
import ProjectPanelStructure from '@@/project/panel/left/Structure'
import ProjectPanelModel from '@@/project/panel/left/Model'
import ProjectPanelModelPatch from '@@/project/panel/left/modelPatch/ModelPatch'
import ProjectPanelElementTree from '@@/project/panel/left/ElementTree'
import ProjectPanelIot from '@@/project/panel/left/Iot'
import ProjectPanelIotWidget from '@@/project/panel/left/IotWidget'
import CollisionControlPanel from '@@/project/panel/left/CollisionControlPanel'
import SmetaPannel from '@@/project/panel/left/smeta/SmetaPannel'
import Drawing2DLogo from '@@/drawings/Drawing2DLogo.vue'
import Face from '@@/project/panel/left/Face'
import WorkJournal from '@@/project/panel/left/documents/WorkJournal/WorkJournal'
import FileManager from '@@/project/panel/left/fileManager/FileManagerPanel'
import ProjectMnemoschemasPanel from '@@/project/panel/left/mnemoschemas/MnemoschemasPanelNew'
import LocationControl from '@/components/project/panel/left/locationControl/LocationControl'
import MaintenancePanel from '@@/project/panel/left/maintenance/MaintenancePanel'
import ToolLayerUnit from "@@/project/common/ToolLayerUnit"
import Plugins from '@@/project/panel/left/plugin/Plugins'
import Reports from '@@/project/panel/left/Reports'
import Forms from '@/pages/admin/forms/Forms.vue'
import Grids from "@@/project/panel/left/Grids"

import ProjectPanelWorkspaces from '@@/project/panel/left/workspaces/Workspaces'
import ProjectPanelClouds from '@@/project/panel/left/clouds/Clouds'
import ModelVisualSettings from '@@/project/panel/left/modelVisualSettings/ModelVisualSettings'
import ProjectInstruments from "@@/project/common/ProjectInstruments";
import WormPannel from '@@/project/panel/left/worm/WormPannel'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';

export default {
  name: 'ProjectLayerLeft',
  components: {
    panelEnum,
    ProjectPanelClouds,
    ProjectPanelWorkspaces,
    ProjectPanelDrawingsList,
    ProjectPanelStructure,
    ProjectPanelModel,
    ProjectPanelTemplates,
    CollisionControlPanel,
    ProjectPanelElementTree,
    ProjectPanelIot,
    ProjectPanelIotWidget,
    ProjectInstruments,
    ToolLayerUnit,
    SmetaPannel,
    Face,
    Plugins,
    Reports,
    Drawing2DLogo,
    Forms,
    Grids,
    ProjectPanelFloors,
    ProjectPanelApi,
    ProjectPanelMembers,
    Passport,
    Statistics,
    ModelVisualSettings,
    ProjectPanelModelPatch,

    MaintenancePanel,
    WormPannel,
    WorkJournal,
    FileManager,
    ProjectMnemoschemasPanel,
    LocationControl
  },

  data () {
    return {
      counter: false,
      copyAttributeSnackBar: false,
    }
  },

  watch: {
    copiedElementAttribute(val){
      if (val) this.copyAttributeSnackBar = true
      else this.copyAttributeSnackBar = false
    },
  },

  computed: {
    ...mapState('project', ['selectedModel','activeGlobalTab', 'activeIotAdmin', 'compareRevisions']),
    ...mapState('patches', ['selectedPatch']),
    ...mapState('copyutil', ['copiedElementAttribute']),
    ...mapGetters("project", ["projectShortcut"]),

    ...mapGetters('authUser', ['canViewClassificator', 'canViewWorm']),

    ...mapGetters('projectPermissions', ['hasCollision', 'hasMaintaince']),

    $_iot_isAvailable () {
      return window.settings.iot && window.settings.iot.on==true
    },
    halfWidth () {
      return window.innerWidth/2
    },
    thirdWidth () {
      return window.innerWidth/3
    },

    showMaintenance () { // временно, потом убрать
      return window.settings.showMaintenance || false
    },

    showNewSmeta () { // временно, потом убрать
      return window.settings.showNewSmeta || false
    },

    showWorm () { // временно, потом убрать
      return window.settings.showWorm || false
    },

    showFace () { // временно, потом убрать
      return window.settings.showFace || false
    },

    showPlugin () { // временно, потом убрать
      return window.settings.showPlugin || false
    },

    showReport () { // временно, потом убрать
      return window.settings.showReport || false
    },

    showTemplates () { // временно, потом убрать
      return window.settings.showTemplates || false
    },

    showWorkJournal () { // временно, потом убрать
      return window.settings.showDocument || false
    },

    showFileManager(){ // временно, потом убрать
      return window.settings.showFileManager || false
    },

    showMnemoschemas() {
      return window.settings.showMnemoschemas ?? false
    },

    showLocationControl() {
      return window.settings.showLocationControl ?? false
    }

  },

  methods: {
    ...mapMutations('project', ['setSelectedModel', 'setActiveGlobalTab', 'setCompareRevisions']),
    ...mapMutations('copyutil', ['setCopiedElementAttribute']),
    ...mapMutations('axis/tree', ['resetAxisTreeElement']),
    ...mapActions('project', ['postPanelsStatus']),

    cancelCopyAttribute(){
      if (navigator.clipboard != null){
        navigator.clipboard.writeText("").then(()=>{
          this.setCopiedElementAttribute(null)
        })
      }
    },

    clearCompareRevisions () {
      let scene = XeokitMediator.viewer.scene
      if (this.compareRevisions) {
          let { first, second } = this.compareRevisions
          let firstModel = first ? scene.models[first.uuid] : null;
          let secondModel = second ? scene.models[second.uuid] : null;

          let current = this.selectedModel.revision
          if (current.uuid === first.uuid) {
            firstModel.visible = false
            secondModel.destroy();
          }
          else if (current.uuid === second.uuid) {
            secondModel.visible = false
            firstModel.destroy();
          }
          else {
            firstModel.destroy();
            secondModel.destroy();
          }

          this.setCompareRevisions(null)
          XeokitMediator.clearColorizeModel()
        }
    },

    setUnit (unit) {
      this.clearCompareRevisions()
      let newUnit = this.activeGlobalTab !== unit ? unit : ''
      if (this.activeGlobalTab == 'show' && newUnit != 'show')  this.$store.dispatch('selected/dropColorized')

      if (this.activeGlobalTab == 'setup' && newUnit != 'setup' && this.selectedModel) this.setSelectedModel(null)
      this.setActiveGlobalTab(newUnit)
      this.postPanelsStatus()
    },

    toDashboard () {
      this.setSelectedModel(null)
      this.$router.push({ path: `/dashboard` })
    },
  },
}
</script>

<style lang="scss" scoped>
.left-layer {
  height: calc(100% - 47px);
  pointer-events: all;
  position: absolute;
  display: inline-block;
}

.tools {
  display: inline-block;
  vertical-align: top;
}

.inline {
  display: inline-block;
}

.wiki-link {
  position: fixed;
  bottom: 14px;
  left: 19px;
  a {
    text-align:center;
    font-weight: bold;
    font-size:16px;
    color: var(--v-surface-lighten3);
    text-decoration: none;
    display:block;
    width: 24px;
    height: 24px;
    background: var(--v-surface-base);
    border-radius: 12px;
  }
}

.interactive {
  pointer-events: all;
}

.collision-panel {
  max-width: calc(100vw - 50vw);
}
.width-panel {
  width: 25vw !important
}
.floors-panel {
  min-width: calc(100vw - 60vw) !important;
}
</style>