<template lang="pug">
  .tree
    draggable(v-if="isProjectOwner" :list="items" @change="onChange" :group="archived ? 'toolTreeNodeArchived': 'toolTreeNode'" handle=".unit-drag" fallback-on-body="true" swap-threshold="0.65")
      project-tool-tree-content( v-for="u in items" :key="u.uuid" style='position: relative' :u="u" @menu="handleContextMenu" :archived="archived")

    template(v-else)
      project-tool-tree-content(v-for="u in items" :key="u.uuid" style='position: relative' :u="u" @menu="handleContextMenu" :archived="archived")

</template>

<script>
import ProjectToolTreeContent from "@/components/project/common/ToolTreeContent"
import { mapGetters, mapActions } from 'vuex'
import draggable from 'vuedraggable'


export default {
  name: 'ProjectToolTreeDraggable',

  components: { draggable, ProjectToolTreeContent },

  props: {
    archived: {
      default: false,
      type: Boolean
    },
    items: Array,
    parent: null
  },

  data() {
    return {
      treeItems: []
    }
  },


  computed: {
    ...mapGetters('authUser', ['isProjectOwner']),
  },

  methods: {
    ...mapActions('project', ['updateProjectStructure', 'changeModelOrder']),

    handleContextMenu (event, model) {
      this.$emit('menu', event, model)
    },

    onChange(evt){
      let e = evt.moved ? evt.moved : evt.added ? evt.added : null
      if(e != null) {
        this.changeModelOrder({"id":e.element.uuid, "order": e.newIndex, "parent":this.parent})
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.tree {
  padding-left: 8px;
  // background-color: var(--v-surface-base) !important;
}
</style>

<style scoped>
.list {
  border-left: 1px dotted #303030;
}

.hide-tree-button {
  position: absolute;
  top: 22px;
  left: -7px;

  height: 16px;
  width: 16px;
  background: #686868; 
  border: 1px solid #262626
}
</style>