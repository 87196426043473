<template lang="pug">
.fullscreen
  bim-viewer
  
  .interface-overlay.through
    project-toolbar.mb-2.interactive(:logo="'/img/home/bimit_logo_white.svg'")
    
    .d-flex.align-end.fullscreen.through
      project-layer-left.interactive.layers(ref="left")
        .inline
          viewer-tooltip-button( icon-name="ic_fit" @click="fitToVisible()" ) {{ $t('viewer.tools.defaultView') }}
          .mt-7
            viewer-tooltip-button( icon-name="ic_planes" :selected="contentOutSectionPlane" @click="createSectionPlane()" ) {{ $t('viewer.tools.sectionPlane') }}
            viewer-tooltip-button( v-if="isPro" icon-name="ic_ruler" :selected="activeMeasurments" @click="toggleMeasurments()" ) {{ $t('viewer.tools.ruler') }}
          .mt-7
            viewer-tooltip-button( icon-name="ic_show" :selected="isShowOnlySelectedElements" @click="toggleShowOnlySelectedObjects()" ) {{ $t('viewer.tools.showSelObjs') }}
            viewer-tooltip-button( icon-name="ic_hide" :selected="isHideSelectedElements" @click="toggleHideSelectedObjects()" ) {{ $t('viewer.tools.hideSelObjs') }}
            viewer-tooltip-button( icon-name="ic_reset" @click="showAllObjects()" )  {{ $t('viewer.tools.clearSelObjs') }}
        
        bim-axis-gizmo-widget.interactive.inline.gizmo.under-all

      project-layer-bottom( ref="bottom" )
        .inline.coordinates
          bim-cursor-navigation
          bim-slider-widget.interactive( v-if="$app.device.isPortable" )

      project-layer-right.interactive.layers( ref="right" )
        bim-nav-cube-widget.under-all
        .sections.under-all
          .relative
            canvas#mySectionPlanesOverviewCanvas.interactive(style="display:none;")
            div
              .interactive(v-for="(section, index) in sectionList" )
                .d-flex.surface.section-plane-item.mt-2
                  v-tooltip(top open-delay=300)
                    template(v-slot:activator="{ on }")
                      v-btn.mt-1(x-small text @click="showControlSectionPlane(section)" :color="section.active ? '#3b93af' : 'white'" v-on="on") 
                        | {{ section.name }}
                    span {{ $t('viewer.sectionPlane.showHide') }} 

                  v-tooltip(top open-delay=300)
                    template(v-slot:activator="{ on }")
                      v-switch.mt-0( v-model="section.visibility" color="#3b93af" @change="visibilitySectionPlane(section)" dense v-on="on" )
                    span {{ $t('viewer.sectionPlane.onOff') }}

                  v-tooltip(top open-delay=300)
                    template(v-slot:activator="{ on }")
                      v-btn( small text @click="flipSectionPlane(section.id)" v-on="on" ) 
                        v-icon flip
                    span {{ $t('viewer.sectionPlane.flip') }}

                  v-tooltip(top open-delay=300)
                    template(v-slot:activator="{ on }")
                      v-btn( small text @click="deleteSectionPlane(section)" v-on="on" )
                        v-icon delete
                    span {{ $t('viewer.sectionPlane.delete') }}

      app-dialog(v-model="dialogProcess" :header="$t('cloud.waitModelProcessed')" closeButtonHidden)
        app-section
          .mt-4( v-for="u in uploads" :key="u.idx" )
            .p-text {{ $t(getAlias(u.name)) }}
            v-progress-linear.mt-1( v-model="u.progress" color="light-blue" height="10" value="10" striped )
        
          .mb-4( v-for="p in processes" :key="p.uuid" )
            .p-text {{ $t(getAlias(p.name)) }}
            v-progress-linear.mt-1( v-model="p.progress" color="light-blue" height="10" value="10" striped )
            .caption.mt-1 {{ p.startDate }}

  v-progress-circular.convertProjectLoading(v-if="loading" size="60" width="4" indeterminate)

  distance-measurement( v-if='activeMeasurments' :withIntersection="mySettingQualityIntersectionRuler" @close="activeMeasurments = false")
</template>

<script>
import ProjectLayerLeft from "@@/project/layer/ProjectLayerLeft"
import ProjectLayerRight from "@@/project/layer/ProjectLayerRight"
import ProjectLayerBottom from "@@/project/layer/ProjectLayerBottom"
// import ProjectScreenshotDialog from "@/components/project/common/ScreenshotDialog"
import ProjectToolbar from "@/components/project/common/ProjectToolbar"
import ViewerTooltipButton from "@/components/project/common/ViewerTooltipButton"
import { NotificationService } from '@/assets/model/notifications/NotificationService'

import { api } from '@/api'

import { math } from "@xeokit/xeokit-sdk"

import { SectionPlanesPlugin } from "@xeokit/xeokit-sdk"
import DistanceMeasurement from '@/components/project/common/distanceMeasurment/DistanceMeasurement'

import { SectionPlanes } from '@/plugins/xeokit/modules/SectionPlanes.work'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import BimViewer from '@/components/project/bim/BimViewer'
import BimNavCubeWidget from '@/components/project/bim/BimNavCubeWidget'
import BimAxisGizmoWidget from '@/components/project/bim/BimAxisGizmoWidget'
import BimSliderWidget from '@/components/project/bim/BimSliderWidget'
import BimCursorNavigation from '@/components/project/bim/BimCursorNavigation'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { SourcePath } from '@app/SourcePath'

import { App } from '@app/App'

export default {
  mixins: [ SectionPlanes ],
  components: {
    ProjectLayerLeft,
    ProjectLayerRight,
    ProjectLayerBottom,
    // ProjectScreenshotDialog,
    ProjectToolbar,
    ViewerTooltipButton,
    BimViewer,
    BimNavCubeWidget,
    BimAxisGizmoWidget,
    BimSliderWidget,
    BimCursorNavigation,
    DistanceMeasurement
  },

  props: {
    uuid: String,
    hashProject: String
  },

  data () {
    return {
      hidden: true,
      buildingStoreys: [],
      selectedStorey: [],
      sceneObjectsIds: [],
      dialog: {
        screenshot: false,
      },
      imageData: null,
      camera: null,
      taskUuid: '',
      contentOutSectionPlane: false,
      activeMeasurments: false,

      mouseClick: null,
      cloudSelectedModel: null,
      disabledConvert: true,

      test: {
        dialog: false,
        enableSizeTest: false,
        minSize: 10,
        enableComplexityTest: false,
        maxComplexity: 1000,
        types: {
          all: [
            'IfcMember', 
            'IfcSpace', 
            'IfcOpeningElement',
            'IfcFurnishingElement',
            'IfcRailing',
            'IfcWindow',
            'IfcDoor',
            'IfcBuildingElementProxy',
            'IfcReinforcingBar',
            'IfcWallStandardCase',
            'IfcBeam',
            'IfcColumn',
          ],
          selected: [],
        },
        models: [],
        showProgress: true,
      }
    }
  },

  async created () {
    this.$store.dispatch('project/initUUID', this.uuid)
  },

  async mounted () {
    await api.projects.getTariffCloud().then(() => {
      this.$app.version.useTariff("Cloud")
    })

    await XeokitMediator.waitForViewer()

    // FIXME: костыль - надо перенести расчеты внутрь Viewer'а
    this.viewer.findCameraCorrections = this.findCameraCorrections

    this.$store.dispatch('project/loadProjectCloud', {uuid: this.uuid, hash: this.hashProject})
    this.$store.dispatch('process/startUpdatingByCloudProject', this.uuid)
    this.init()

    if (this.mouseNav === "professional")
      XeokitMediator.installInput("./modules/InputMouseProfController.js")
    else if (this.mouseNav === "user")
      XeokitMediator.installInput("./modules/InputMouseUserController.js")

    XeokitMediator.on("mouseClick", (mouseClick) => {
      this.mouseClick = mouseClick
    });

    XeokitMediator.on("PickedElement", (entity) => {
      if (this.activeGlobalTab!=='collision' && !this.contentOutSectionPlane && !this.activeMeasurments)
        XeokitMediator.ElementsSelection.pickElement(entity.id)
    })
    
    if (App.auth.accessToken)
      this.cloudListProjectsByHash()
  },

  beforeDestroy() {
    this.$store.dispatch('process/stopUpdating')
    XeokitMediator.reset()
  },

  computed: {
    ...mapState('authUser', ['mouseNav']),
    ...mapState('project', ['selectedRevision','projectSettings', 'selectedModel', 'activeGlobalTab', 'project']),
    ...mapState('collision', ['collisionPickedElement']),
    ...mapState('collision/search', ['collisionSelectedElements']),
    ...mapState('process', ['processes', 'uploads', 'notifications']),
    ...mapGetters('cloud', ['flatlistTreeModel', 'loading']),
    ...mapGetters('project',['flatlist']),
    ...mapState('viewerSectionPlane', ['sectionList']),
    ...mapGetters('dashboard', ['isProjectsLoading']),

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    isHideSelectedElements() {
      return XeokitMediator.ElementsSelection.isHideSelectedElements
    },

    isShowOnlySelectedElements() {
      return XeokitMediator.ElementsSelection.isShowOnlySelectedElements
    },

    showLeftPanel () {
      return this.project.model.filter(m => m.revision && m.revision.state.name !== "READY").length == 0
    },

    dialogProcess () {
      if (this.project &&  this.project.model.length > 0 && this.project.model[0].revision.state.name==="READY") {
        this.setShowProject(true)
        this.stopUpdating()
      } else {
        if ((this.uploads.length > 0 || this.processes.length > 0)) {
          this.setShowProject(false)
        } else {
          this.getProject()
        }
      }
      return this.showProgress
    },

    availableModels () {
      let list = []
      for (const model of this.flatlist) {
        if (model && model.revision) {
          let rev = model.revision
          if (rev) list.push({ uuid: rev.uuid, name: model.name })
        }
      }
      return list
    },

    viewer () {
      return XeokitMediator.viewer
    },

    xktLoader () {
      return XeokitMediator.xktLoader
    },
  },

  watch: {
    projectSettings: 'loadComposition',
    selectedRevision: 'reloadModel',
    collisionSelectedElements: 'highlightCollisionSelected',
  },

  methods: {
    ...mapMutations('project',['setModelState', 'setActiveGlobalTab']),
    ...mapActions('theme', ['setTheme']),
    ...mapActions('collision', ['pickElement']),
    ...mapActions('process', ['stopUpdating']),
    ...mapActions('cloud', ['cloudListProjectsByHash']),
    ...mapMutations('viewerSectionPlane', ['setItemSectionList', 'setSectionList']),

    getAlias(alias) {
      return NotificationService.NotificationWithValue(alias)
    },

    getProject () {
      this.setHideProgress()
      let _this = this
      setTimeout(function() {  _this.$store.dispatch('project/loadProjectCloud', {uuid: _this.uuid, hash: _this.hashProject}) }, 1000)
    },

    setHideProgress () {
      this.showProgress = false
    },

    setShowProject (isProgress) {
      if (isProgress) {
        this.showProgress = false
        this.setActiveGlobalTab('show')
      } 
      else this.showProgress = true
    },

     init() {
      this.viewer.scene.input.on("mouseclicked", (canvasCoords) => {
        console.time('mouseclicked')
        this.viewer.cameraControl.scene.canvas.canvas.style.cursor = 'default'

        if (this.contentOutSectionPlane || this.activeMeasurments) {
          return false
        }
  
        console.time('mouseclicked find pick result')
        let pickResult = this.viewer.scene.pick({
          canvasPos: canvasCoords,
          pickSurface: true
        });

        console.timeEnd('mouseclicked find pick result')
        
        if (pickResult) {
          console.log('‼️ Pick:', pickResult.entity.id)
          console.log('‼️ Mouse click:', this.mouseClick)

          if (this.mouseClick && this.mouseClick.buttons == 1) {
            if (this.mouseClick.shiftKey || this.mouseClick.ctrlKey) {
              XeokitMediator.ElementsSelection.serialSelectElements(pickResult.entity.id);
            }
            XeokitMediator.ElementsSelection.pickElement(pickResult.entity.id);
            switch (this.activeGlobalTab) {
              case 'collision':
                  this.pickElement(pickResult.entity)
                break;
            }
          }
        }
        else if (this.pickedElement) {
          XeokitMediator.ElementsSelection.pickElement(null)
        }
        console.timeEnd('mouseclicked')
      })

      let timeoutDuration = 1000; // Milliseconds
      let timer = timeoutDuration;
      let saoAndEdgesDisabled = false;

      const myViewer = this.viewer
      const vm = this

      function hideByCond () {
        const scene = myViewer.scene;
        const objectIds = scene.objectIds;
        const entities = scene.objects;
        const numEntities = objectIds.length;

        let count = 0
        for (let i = 0; i < numEntities; i++) {
          const objectId = objectIds[i];
          const entity = entities[objectId];
          if (entity && entity.visible) {
            const entityDiagSize = math.getAABB3Diag(entity.aabb)
            const entityTriangles = (entity.numTriangles || 0)

            if ((vm.test.enableSizeTest && entityDiagSize <= vm.test.minSize) || (vm.test.enableComplexityTest && entityTriangles >= vm.test.maxComplexity)) {
              entity.visible = false
              count++
            }
          }
        }
        console.log(`Hide by condition ${count}`);
      }

      function hideObjects () {
        for (const type of vm.test.types.selected) {
          hideByType(type)
        }
      }

      function hideByType (type) {
        const scene = myViewer.scene
        const entities = scene.objects
        const list = myViewer.metaScene.getObjectIDsByType(type)

        let count = 0

        for (let i = 0; i < list.length; i++) {
          const objectId = list[i]
          const entity = entities[objectId]

          if (entity) {
            entity.visible = false
            count++
          }
        }
        console.log(`Hide by type: ${type} - ${count}`)

        this.sectionPlanesPlugin = new SectionPlanesPlugin(this.viewer, {
          overviewCanvasId: "mySectionPlanesOverviewCanvas",
          overviewVisible: false
        })
      }

      function hideModels () {
        for (const uuid of vm.test.models) {
          let model = vm.viewer.scene.models[uuid];
          model.visible = false
        }
      }

      function showAllObjects () {
        const scene = myViewer.scene;
        const objectIds = scene.objectIds;
        const entities = scene.objects;
        const numEntities = objectIds.length;
        
        for (let i = 0; i < numEntities; i++) {
          const objectId = objectIds[i];
          const entity = entities[objectId];
          if (entity) {
            if (vm.selectedElements || vm.collisionSelectedElements) {
              if (vm.selectedElements.find(item => item === entity.id) || vm.collisionSelectedElements.find(item => item === entity.id)) {
                if (vm.isShowOnlySelectedElements) entity.visible = false 
                if (vm.isHideSelectedElements) entity.visible = true 
              } 
              else {
                if (vm.isShowOnlySelectedElements) entity.visible = true 
                if (vm.isHideSelectedElements) entity.visible = false 
              }
            } 
            else 
              entity.visible = true;
          }
        }

        for (const uuid of vm.test.models) {
          let model = vm.viewer.scene.models[uuid];
          model.visible = true
        }
      }

      myViewer.scene.camera.on("matrix", () => {
        timer = timeoutDuration;
        if (!saoAndEdgesDisabled) {
          myViewer.scene.sao.enabled = false;
          myViewer.scene.edgeMaterial.edges = false;
          myViewer.scene.pbrEnabled = false;
          
          hideModels();
          hideByCond();
          hideObjects();

          saoAndEdgesDisabled = true;
        }
      });

      this.viewer.scene.on("tick", (tickEvent) => {  // Milliseconds
          if (!saoAndEdgesDisabled) {
            return;
          }
          timer -= tickEvent.deltaTime;
          if (timer <= 0) {
            if (saoAndEdgesDisabled) {
              myViewer.scene.sao.enabled = true;
              myViewer.scene.edgeMaterial.edges = true;
              myViewer.scene.pbrEnabled = true
              showAllObjects()
              saoAndEdgesDisabled = false;
            }
          }
      });

      this.sectionPlanesPlugin = new SectionPlanesPlugin(this.viewer, {
        overviewCanvasId: "mySectionPlanesOverviewCanvas",
        overviewVisible: false
      })
    },

    loadComposition() {
      let modelsToLoad = this.projectSettings.workModel
      let arrToLoad = []
      
      modelsToLoad.forEach(pModel => {
        let on = pModel.switchon
        let model = this.flatlist.find(ele => ele.uuid === pModel.modelUuid)
        let rev = model?.revision
        
        if (rev) {
          if (on) {
            if (!this.viewer.metaScene.metaModels[rev.uuid]) {
              arrToLoad.push(rev)
            }
          }
          else {
            let component = this.viewer.scene.models[rev.uuid]
            component?.destroy()
          }
        }
      })

      if (arrToLoad.length > 0) this.loadModelsOneByOne(arrToLoad)
    },

    loadModelsOneByOne(array){
      let rev = array.shift()
      let calback
      if (array.length > 0) {
        calback = () => {
          this.loadModelsOneByOne(array)
        }
      } else {
        calback = () => this.afterLoadEvent()
      }
      this.loadRevision(rev,calback)
    },

    loadRevision (rev, callback) {
      let { jsonFile, xktFile } = rev

      if (jsonFile && xktFile) {
        this.hidden = false
        
        let objectDefaults = {
          IfcSpace: { 
            pickable: true, 
            opacity: 0.5
          },
        }

        let rotation = [90 + rev.showEulerX, rev.showEulerZ, rev.showEulerY];
        let offset = [rev.showOffsetX, rev.showOffsetY, rev.showOffsetZ];

        let model = this.xktLoader.load({ 
          id: rev.uuid, 
          src: SourcePath.fileCloud(xktFile), 
          metaModelSrc: SourcePath.fileCloud(jsonFile), 
          edges: true, 
          objectDefaults, 
          position: offset, 
          rotation: rotation, 
          saoEnabled: false,
          pbrEnabled: true,
          backfaces: false,
        });

        model.on("loaded", () => { 
          this.$metrika.reachGoal('NEXTCLOUD', {model: model.title})
          callback
        });
      }
    },

    toggleHideSelectedObjects () {
      let selectElementsUuids = [this.pickedElement, ...this.selectedElements, 
                                ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB]
      XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

      XeokitMediator.ElementsSelection.setHideSelectedElements(!this.isHideSelectedElements)
    },

    toggleShowOnlySelectedObjects () {
      let selectElementsUuids = [this.pickedElement, ...this.selectedElements, 
                                ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB]
      XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

      XeokitMediator.ElementsSelection.setShowOnlySelectedElements(!this.isShowOnlySelectedElements)
    },
    
    showAllObjects () {
      XeokitMediator.ElementsSelection.dropAll()
    },

    hideAllLoadedModels() {
      let loadedModels = this.viewer.metaScene.metaModels
      Object.keys(loadedModels).map((key) => {
          var loadedMetaModel = loadedModels[key];
          let model = this.viewer.scene.models[loadedMetaModel.id];
          model.visible = false
      });
    },

    destroyUnnecessaryModels () {
      let uuids = Object(this.projectSettings.workModel).values().filter(m => m.switchon && m.revisionUuid).map(m => m.revisionUuid)
      Object.keys(this.viewer.scene.models).forEach(key => {
        if (!uuids.includes(key)) {
          let component = this.viewer.scene.models[key]
          component?.destroy()
        }
      })
    },

    reloadModel () {
      this.destroyUnnecessaryModels()

      let allModels = this.viewer.scene.models
      if (this.selectedRevision) {
        Object.keys(allModels).map(function(objectKey) {
          let model = allModels[objectKey];
          model.opacity = 0.3
          model.edges = false
        });

        let revUid = this.selectedRevision.uuid

        if (this.viewer.metaScene.metaModels[revUid]) {
          let model = this.viewer.scene.models[revUid];
          model.opacity = 1
          model.edges = true
          model.visible = true
        } else {
          let revO = this.selectedRevision
          if (revO) {
            let cb = () => {
              let model = this.viewer.scene.models[revO.uuid];
              model.opacity = 1
              model.edges = true
              this.afterLoadEvent()
            }
            this.loadRevision(revO, cb)
          }
        }
      } else {
        Object.keys(allModels).map(function(objectKey) {
            let model = allModels[objectKey];
            model.opacity = 1
            model.edges = true
            model.visible = false
        });

        let modelsToLoad = Object(this.projectSettings.workModel).values().filter(e => e.switchon == true)
        modelsToLoad.forEach(pModel => {
          let model = this.flatlist.find(ele => ele.uuid === pModel.modelUuid)
          if (model && model.revision) {
            let rev = model.revision
            
            if (rev) {
              if (pModel.switchon) {
                  let model = this.viewer.scene.models[rev.uuid];
                  model.opacity = 1
                  model.visible = true
                  model.edges = true
              }
            }
          }
        })
      }
    },

    afterLoadEvent () {
      this.fitToVisible()
      this.sceneObjectsIds = this.viewer.scene.visibleObjectIds
    },

    createPlan(){
      console.log("CreatePlan: off")
    },

    findCameraCorrections(_aabb, _eye, _look, _up) {
      let fitFOV = this.viewer.camera.project.fov
      let aabbX = _aabb[3] - _aabb[0]
      let aabbY = _aabb[4] - _aabb[1]

      let viewportWidth = this.viewer.scene.viewport.boundary[2]
      let viewportHeight = this.viewer.scene.viewport.boundary[3]

      let leftWidth = this.$refs.left.$el.clientWidth
      let rightWidth = this.$refs.right.$el.clientWidth
      let bottomHeight = this.$refs.bottom.$el.clientHeight

      let kX = aabbX / viewportWidth
      let kY = aabbY / viewportHeight

      let nX = (leftWidth*kX) - (rightWidth*kX)
      let nY = 0 - bottomHeight*kY
      let nDiag = Math.sqrt(Math.pow(nX,2)+Math.pow(nY,2)) + this.viewer.camera.eyeLookDist
      let nZ = Math.abs(nDiag / Math.tan(fitFOV * math.DEGTORAD))

      this.viewer.corrections.correction = [nX, nY, nZ]

      let tempVec3 = math.vec3()
      let aabb = _aabb.slice()
      const diag = math.getAABB3Diag(aabb)
      const aabbCenter = math.getAABB3Center(aabb)
      let up = _up
      let look = aabbCenter
      
      const eyeLookVec = math.subVec3(_eye, _look, tempVec3)
      const eyeLookVecNorm = math.normalizeVec3(eyeLookVec)
      const sca = Math.abs(diag / Math.tan(fitFOV * math.DEGTORAD))
      let eye = math.vec3();
      eye[0] = look[0] + (eyeLookVecNorm[0] * sca)
      eye[1] = look[1] + (eyeLookVecNorm[1] * sca)
      eye[2] = look[2] + (eyeLookVecNorm[2] * sca)

      tempVec3 = math.vec3();
      const tempVec3a = math.vec3();
      const tempVec3b = math.vec3();
      const tempVec3c = math.vec3();
      const tempVec3d = math.vec3();
      const tempVec3e = math.vec3();
      const tempVec3f = math.vec3();

      const eye2 = math.subVec3(eye, look, tempVec3);
      const vec = [0, 0, 0];
      let v;

      let pan = this.viewer.corrections.correction

      if (pan[0] !== 0) {
          const left = math.cross3Vec3(math.normalizeVec3(eye2, tempVec3a), math.normalizeVec3(up, tempVec3b));
          v = math.mulVec3Scalar(left, pan[0]);
          vec[0] += v[0];
          vec[1] += v[1];
          vec[2] += v[2];
      }
      if (pan[1] !== 0) {
          v = math.mulVec3Scalar(math.normalizeVec3(up, tempVec3c), pan[1]);
          vec[0] += v[0];
          vec[1] += v[1];
          vec[2] += v[2];
      }
      if (pan[2] !== 0) {
          v = math.mulVec3Scalar(math.normalizeVec3(eye2, tempVec3d), pan[2]);
          vec[0] += v[0];
          vec[1] += v[1];
          vec[2] += v[2];
      }
      let newEye = math.addVec3(eye, vec, tempVec3e);
      let newLook = math.addVec3(look, vec, tempVec3f);

      this.viewer.corrections.eye = newEye
      this.viewer.corrections.look = newLook
      this.viewer.corrections.up = up
    },

    fitToVisible() {
      this.findCameraCorrections(this.viewer.scene.aabb, this.viewer.camera.eye, this.viewer.camera.look, this.viewer.camera.up)
      this.cameraFlyToCorrections()
    },

    cameraFlyToCorrections(){
      let { look, eye, up } = XeokitMediator.viewer.corrections
      XeokitMediator.CameraFlight.cameraFlyTo({ look, eye, up, duration: 0.5 })
    },

    highlightSelected () {
      const scene = this.viewer.scene
      if(!this.isHideSelectedElements && !this.isShowOnlySelectedElements){
        scene.setObjectsHighlighted(scene.visibleObjectIds, false) //надо обратить внимание
        scene.setObjectsHighlighted(this.selectedElements, true)
        console.log(this.selectedElements)
        console.log(this.viewer.scene.highlightedObjects)
      }else if(this.isHideSelectedElements) {
        if(this.selectedElements.length != 0){
          scene.setObjectsVisible(this.sceneObjectsIds, false)
          scene.setObjectsVisible(this.selectedElements, true)
        }else{
          scene.setObjectsVisible(this.sceneObjectsIds, true)
          this.isHideSelectedElements = false
        }
      }else if(this.isShowOnlySelectedElements) {
        scene.setObjectsVisible(this.sceneObjectsIds, true)
        scene.setObjectsVisible(this.selectedElements, false)
        scene.setObjectsHighlighted(this.sceneObjectsIds, false)
        scene.setObjectsHighlighted(this.selectedElements, true)
      }
    },

    highlightCollisionSelected () {
      this.viewer.scene.setObjectsHighlighted(this.sceneObjectsIds, false)
      this.viewer.scene.setObjectsSelected(this.sceneObjectsIds, false)
      
      let [first, second] = this.collisionSelectedElements

      this.viewer.scene.setObjectsHighlighted([first], true)
      this.viewer.scene.setObjectsSelected([second], true)
      
      let aabb = this.viewer.scene.getAABB(this.collisionSelectedElements)
      XeokitMediator.CameraFlight.cameraFlyTo(aabb)
      this.viewer.scene.setObjectsPickable(this.collisionSelectedElements, true)
    },

    toggleMeasurments () {
      if (this.activeMeasurments) {
        this.activeMeasurments = false
      }
      else {
        this.activeMeasurments = true
      }
    },
  }
}
</script>

<style>
/* Xeokit  */

.viewer-ruler-wire {
  z-index: 1 !important;
}
.viewer-ruler-dot {
  z-index: 2 !important;
}
.viewer-ruler-label {
  z-index: 3 !important;
}
</style>

<style lang="scss" scoped>
.p-text {
  font: normal 11px/16px $roboto;
  color: #505254;
}

.interface-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
}
.through {
  pointer-events: none;
}
.interactive {
  pointer-events: all;
}

.fullscreen {
  height: calc(100vh - 52px);
  width: 100vw;
  overflow: hidden;
}

.relative {
  position: relative;
}

// .absolute {
//   position: absolute;
// }

// .test-camera {
//   position: absolute;
//   top: 55px;
// }

// #storeyMap {
//     padding: 5px;
//     background: #4d4d4d !important;
//     right: 0;
//     bottom: 0;
//     width: 260px;
// }

// .space-between {justify-content: space-between;}

#mySectionPlanesOverviewCanvas {
  width: 120px;
  height: 120px;
}

// .containerSectionPlainsList {
//   width: 240px; 
//   max-height: 400px; 
//   overflow-x: hidden;
//   scrollbar-width: thin;
// }

// .containerSectionPlainsList::-webkit-scrollbar {
//     width: 5px;
//     background-color: var(--v-primary-lighten3);
// }
// .containerSectionPlainsList::-webkit-scrollbar-thumb  {
//    background-color: var(--v-surface-lighten3);
// }

.section-plane-item {
  border-radius: 4px;
  height: 32px;
}

// #camera-center-marker div {
//   width: 5px;
//   height: 5px;
//   background: #000000;
//   border-radius: 25px;
// }

// #camera-center-marker {
//   color: #ffffff;
//   position: absolute;
//   margin-bottom: 100px;
//   left: calc(100vw / 2);
//   padding: 25px;
//   opacity: 0.8;
//   border-radius: 30px;
//   background: #4f4a40;
//   visibility: hidden;
//   pointer-events: none;
// }

// #cursor { 
//     pointer-events: none; 
//     position: absolute;
//     visibility: hidden;
//     z-index: 1;
//     margin-bottom: 110px;
//     left: calc(100vw / 2 + 12px);
//     border-radius: 25px;
//     background: #4f4a40;
//     padding: 5px;
// } 

.convertProjectLoading {
position: absolute;
top: calc(50% - 20px);
left: calc(50% - 20px);
}

.sections {
  position: absolute;
  left: -250px;
  bottom: 65px;
}

.gizmo{
  position: absolute;
  bottom: 0px;
  right: -65px;
}

.coordinates {
  white-space: nowrap;
  position: relative;
  bottom: 90px;
  right: 300px;
  width: 100px;
  height: 100px;
}
</style>