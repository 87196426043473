<template lang="pug">  
app-dialog(v-model="$_show" width="470" :confirmDisabled="!floorFormName.trim() && elevation.trim()" :confirm-text="$t('button.save')" :header="$t('floor.buildAccordance.floorMapping')" @confirm="save()")
  app-section.cr-accordance
    div.cr-accordance-2
      app-text-field.name-of-floor(v-model="floorFormName" :label="$t('floor.buildAccordance.inputLabel')" :rules="[rules.required]" focus test-id="build-accordance-input")
      app-text-field.mark( v-model="elevation" type="number" :label="$t('floor.buildAccordance.mark')" :rules="[rules.mark]")
      //- div.mx-1.mb-3(v-if="readyLayer" v-for='model in readyLayer')
        p.ma-0.ml-1(style="color: black;" ) {{ model[0].modelName }}
        .d-flex.flex-wrap
          div.d-inline-flex.element-unit.align-center(v-for="floor in model" test-id="build-accordance-check")
            v-checkbox.flex-1.ma-0.pa-0(v-model="choosenElementsUuid" hide-details light :ripple="false" :value="floor.uuid" :label="floor.name")
</template>

<script>
import {  mapActions, mapGetters, mapState } from 'vuex'
import DialogToggable from '@/components/app/DialogToggable'

export default {

  data () {
    return {
      floorFormName: '',
      choosenElementsUuid: [],
      floorForm: {
        elements: [],
        name: null,
        project: '',
      },
      rules: {
        required: (value) => {
          if(typeof value === "string" && value.length === 0 || !this.floorFormName.trim()) {
            return this.$t('floor.buildAccordance.inputLabel')
          } else {
            return true
          }
        },
        mark: (value) => {
          if(value.length === 0 || !this.elevation.trim()){
            return this.$t('floor.buildAccordance.mark')
          } else {
            return true
          }
        },
      },
      elevation: '',
    }
  },
  mixins: [DialogToggable],

  props: {
    floorData: null,
    floorUuid: null,
    isEdit: null,
  },

  watch: {
    floorUuid () {
      this.choosenElementsUuid = []
      this.checkboxesOn()
    },
    $_show () {
      this.checkboxesOn()
    }
  },

  methods: {
    ...mapActions("axis", ["saveFloor"]),

    save () {
      this.floorForm.elements = []
      this.floorForm.project = this.projectUuid
      this.choosenElementsUuid.forEach( uuid => this.floorForm.elements.push({ uuid: uuid }))
      
      if (!this.isEdit) {
        this.floorForm.name = this.floorFormName
        this.floorForm.elevation = this.elevation
        this.$emit('savefloors', this.floorForm)
      }
      else {
        this.floorForm.name = this.floorFormName
        this.floorForm.elevation = this.elevation
        this.$emit('editfloors', this.floorForm)
      }
      if (this.floorUuid == null) {
        this.floorForm = {
          elements: [],
          name: null,
          project: ''
        },
        this.choosenElementsUuid = []
      }
    },

    checkboxesOn () {
      this.choosenElementsUuid = []
      this.floors.forEach( model => {
        if (model.uuid == this.floorUuid) {
          this.floorFormName = model.name
          this.floorForm.name = model.name;
          this.elevation = model.elevation
          model.elements.forEach(el => {
            this.choosenElementsUuid.push(el.uuid)
          })
        }
      })
    },
  },

  computed: {
    ...mapGetters("project", ["projectUuid", "flatlist"]),
    ...mapState("axis", ["floors", "layers"]),

    readyLayer () {
      let layersData = []
      let floorsList = []
      let checkedList = []
      
      this.floors.forEach(floor => {
        if(floor.elements.length !== 0) {
          floor.elements.forEach(fl => {
            floorsList.push(fl.uuid)
          })
        }
      })

      this.floors.forEach( floor => {
        if(floor.uuid == this.floorUuid) {
          floor.elements.forEach(el => {
            checkedList.push(el.uuid)
          })
        }
      })

      this.layers.forEach(layer => {
        let model = this.flatlist.find( el =>  el.uuid == layer.model )
        if (model != undefined && (!floorsList.find(uuid => layer.uuid == uuid) || checkedList.find(uuid => layer.uuid == uuid))) {
          let layerData ={
            name: layer.name,
            modelName: model.title,
            uuid: layer.uuid
          }
          layersData.push(layerData)
        }
      })
      
      let layersDataSorted = Object.values( layersData.reduce(
        (acc, layersData) => {
          const modelName = layersData.modelName;
          acc[modelName] ??= [];
          acc[modelName].push({ ...layersData });
          return acc;
        },
        {},
      ));
      
      layersDataSorted.sort( (a,b) => (a[0].modelName > b[0].modelName) ? 1 : -1 )
      
      return layersDataSorted
    },

  },

  mounted () {
    this.checkboxesOn()
  },
}
</script>

<style scoped lang="scss">

.cr-accordance-2 {
  max-height: 70vh;
}
.name-of-floor {
  width: 100%;
}
.element-unit {
  color: black;
  font: normal 14px/8px "Roboto", sans-serif;
  width: auto;
}
.flex-1 {
  min-width: 70px;
}
::v-deep.flex-1 .v-label {
  font: normal 14px/8px "Roboto", sans-serif;
  color: black;
  left: -9px !important;
}
.element-unit:hover {
  background: #E5E5E5;
}
</style>