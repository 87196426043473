import { defineStore } from 'pinia'
import vuexStore from '@/store'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { IFCGridsService } from '@/plugins/IFCGrids/IFCGridsService'

export const useModeStore = defineStore('viewer.modes', {

  getters: {
    isGridsAvailable: () => true, // TODO: Заменить на актуальный флаг доступности сеток. Булат
    isGridsEnabled: () => vuexStore.state.IFCGrids.gridsActivity,
    isModeOrthogonal: () => XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.ORTHO,
    isModePrespective: () => XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.PERSPECTIVE,
    isMouseNavigationPro: () => XeokitMediator.MouseNavigation.mouseNav === 'professional',
    isMouseNavigationCustom: () => XeokitMediator.MouseNavigation.mouseNav === 'user',
  },

  actions: {
    setPerspectiveMode: () => { XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.PERSPECTIVE) },
    setOrthogonalMode: () => { XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.ORTHO) },

    setProfessionalMouseNavigation: () => { setMouseNavigationMode('professional') },
    setUserMouseNavigation: () => { setMouseNavigationMode('user') },

    // Контекст нужен, чтобы IFCGridsService мог показать сообщение об ошибке в случае необходимости (костыль)
    showIFCGrids(context) { 
      IFCGridsService.handleGridsDisplay(context) 
    }
  }
})

// MARK:- утилиты

function setMouseNavigationMode (mouseMode) {
  vuexStore.dispatch('authUser/setProfileSettingModeMouseNav', mouseMode)
  XeokitMediator.MouseNavigation.setMouseNavigation(mouseMode)
}