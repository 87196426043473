<template lang="pug">
  v-tooltip(right open-delay=1000)
    template(v-slot:activator="{ on, attrs }" v-if="model.archived == archived || archived == true")
      v-row.unit-row.mt-2(v-on="on")
        v-hover( v-slot:default="{ hover }" close-delay="150" )
          .unit.app-cursor--context( :class="{'unit-active': isActive, 'unit-archived': isArchived, 'archive-unit-notarchived': !showSwitch, 'elevation-5': hover, 'padding-for-icon': hover}" test-id="tree-unit-item" @click="toggle" @contextmenu.stop="showMenu" ) 
            v-icon.unit-drag( v-if="hover && hasProjectStruct" :color="isActive ? 'var(--v-primary-darken2)' : 'var(--v-surface-lighten2)'" size="18") drag_indicator
            | {{ isAlias ? $t(model.title) : model.title }}
            v-icon.unit-action( v-if="hover && checkRole" :color="isActive ? 'var(--v-primary-darken2)' : 'var(--v-surface-lighten2)'" @click.stop="showMenu" ) more_vert
        v-switch.viewSwitch.ml-2.mr-1(v-show="showSwitch" v-model="models" :value="model.uuid" color="accent" dense light)
    span {{ isAlias ? $t(model.title) : model.title }} 
</template>

<script>
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
// import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectToolTreeUnit',

  props: {
    archived: {
      default: false,
      type: Boolean
    },
    model: Object
  },

  computed: {
    ...mapState('project', ['selectedModel', 'projectSettings', 'compareRevisions']),
    ...mapGetters('project', ['flatlist']),
    ...mapGetters('projectPermissions', ["hasProjectReconstruction", "hasProjectStruct", "hasProjectStruct", "hasProjectAddAttribution", "hasProjectDeleteModel", "hasProjectControlArchive", "hasModelDownloadRevision", "hasProjectSendToArchive" ]),


    checkRole() { 
      return this.hasProjectControlArchive || this.hasProjectSendToArchive || this.hasProjectStruct || this.hasProjectDeleteModel || (this.model.model.length > 0)
    },

    models:{
      get() {
        let l = []
        Object.keys(this.projectSettings.workModel).forEach(modelUUID => {
          if (this.projectSettings.workModel[modelUUID].switchon) l.push(modelUUID)
        });
        return l;
      },
      set(value) {
        if (value.includes(this.model.uuid) && this.model.model.length > 0) {
          value = this.showChild(value, this.model.model.filter(el => !el.archived))
        } else {
          value = this.hideChild(value, this.model.model.filter(el => !el.archived))
        }
        this.updateWorkModels(value)

        // const { uuid } = this.model
        // this.makeModelVisible({ modelUuid: uuid, on: value.includes(uuid) })
      }
    },

    isAlias () {
      return this.model.title.indexOf("notify.") != -1
    },

    isActive () {
      return this.selectedModel && this.selectedModel.uuid === this.model.uuid
    },

    isArchived () {
      return this.model.archived
    }, 

    showSwitch() {
      return this.archived && !this.model.archived ? false : true
    },

    parentTitle () {
      if(this.archived && this.model.stack.length > 1) { 
        let parent =  this.flatlist.find(m => m.uuid == this.model.stack[this.model.stack.length-2])
        return parent !== undefined && !parent.archived ? parent.title + " / " : ""
      } else return ""
    },
  },
  
  methods: {
    ...mapMutations('project', ['setSelectedModel', 'setCompareRevisions']),
    ...mapActions('project', ['updateWorkModels', 'makeModelVisible']),

    clearCompareRevisions () {
      // let scene = XeokitMediator.viewer.scene
      if (this.compareRevisions) {
          // let { first, second } = this.compareRevisions
          // let firstModel = first ? scene.models[first.uuid] : null;
          // let secondModel = second ? scene.models[second.uuid] : null;

          // let current = this.selectedModel.revisions.current
          // if (current.uuid === first.uuid) {
          //   firstModel.visible = false
          //   secondModel.destroy();
          // }
          // else if (current.uuid === second.uuid) {
          //   secondModel.visible = false
          //   firstModel.destroy();
          // }
          // else {
          //   firstModel.destroy();
          //   secondModel.destroy();
          // }
          // XeokitMediator.clearColorizeModel()
          // this.$root.$emit("reloadModel")
          XeokitMediator.ModelsManager.setModelsVisible(XeokitMediator.viewer.scene.modelIds, true)
          XeokitMediator.ModelsManager.setModelsXrayed(XeokitMediator.viewer.scene.modelIds, false)
          this.setCompareRevisions(null)
        }
    },

    async toggle () {
        this.clearCompareRevisions()
        
        // let scene = XeokitMediator.viewer.scene
        // let objectIds = scene.objectIds
        
        if (this.isActive) {
          await this.setSelectedModel(null)
          // scene.setObjectsPickable(objectIds, true)
        } 
        else {
          this.setSelectedModel(this.model)
          // scene.setObjectsPickable(objectIds, false)
          
          // let modelObjectIds = scene.models[this.model.revisions.current?.uuid]?._nodeList.map(node => node.id) || []
          // scene.setObjectsPickable(modelObjectIds, true)
        }
    },

    showMenu (event) {
      this.$emit('menu', event, this.model)
    },

    showChild (list, models) {
      if (models.length > 0) {
        models.map(item => {
          if (!this.archived && !item.archived) {
            list.push(item.uuid)
            list = this.showChild(list, item.model)
          }
        })
      }
      return list
    },

    hideChild (list, models) {
      if (models.length > 0) {
        models.map(item => {
          if (!this.archived && !item.archived) {
            var index = list.indexOf(item.uuid)
            if (index !== -1) list.splice(index, 1)
            list = this.hideChild(list, item.model)
          }
        })
      }
      return list
    },
  }
}
</script>

<style lang="scss" scoped>
.unit-row {
  //margin-bottom: 8px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
  flex-wrap: nowrap;
}
.viewSwitch {
  margin-left: 6px;
  margin-top: 0px;
}
.unit {
  position: relative;
  padding: 8px;
  width: 100%;
  height: 32px;
  background-color: var(--v-surface-lighten1);
  border-radius: 5px;
  color: var(--v-primary-darken2);
  font: normal 12px/18px $roboto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.unit-active {
  background-color:  var(--v-surface-base);
  border: 1px solid var(--v-surface-lighten2);
  color: var(--v-primary-darken2);
  font-weight: bold;
}
.unit-action {
  position: absolute;
  right: -2px;
  top: 5px;
  cursor: pointer;
}
.unit-drag {
  position: absolute;
  left: 0;
  top: 7px;
  cursor: move;
}

.unit-archived {
  color: var(--v-primary-darken2);
}
.archive-unit-notarchived{
  background-color: var(--v-surface-lighten1);
  color: var(--v-primary-darken2);
  // opacity: 0.5;
  margin-right: 44px;
  &.unit-active {
  background-color:  var(--v-surface-base);
  border: 1px solid var(--v-surface-lighten2);
  color: var(--v-primary-darken2);
  font-weight: bold;
  }
}
.padding-for-icon {
  padding-left: 16px;
}
</style>