<template lang="pug">
.right-layer.fill-height.darken-1
  bim-nav-cube-widget.under-all( @click.native="clickOnCube()" )
  .sections.under-all
    .relative
      canvas#mySectionPlanesOverviewCanvas.interactive( style="display:none;" )
      div( v-if="rework_isVisibleOldPlugins" )
        bim-section-planes

  .d-flex.align-stretch.fill-height.right-gap
    project-panel-element-tree.width-panel( v-if="rework_isUpdatedTopToolbarVisible && (topUnit === 'elementTree' || $app.version.isCloud)" )
    project-panel-tasks( v-if="topUnit === 'task'" )
    project-process-list( v-if="topUnit === 'process'" )
    project-notification-list( v-if="topUnit === 'notification'" )
    profile-settings( v-if="topUnit === 'profileSettings'" )

    .right-panel
      ProjectControlRightBarToolbar( v-if="rework_isRightToolbarVisible" )

  .tools-window( v-if="hasActiveTool && rework_isRightToolbarVisible" )
    ViewerToolWindowBridge

  task-screenshot-dialog
</template>

<script>
import ProjectPanelElementTree from '@/components/project/panel/left/ElementTree.vue'
import ProjectProcessList from "@/components/project/panel/right/ProcessList.vue"
import ProjectNotificationList from "@/components/project/panel/right/NotificationList.vue"
import ProfileSettings from "@/components/project/panel/right/ProfileSettings.vue"
import ProjectPanelTasks from '@/components/project/panel/right/ProjectPanelTasks.vue'
import BimNavCubeWidget from "@/components/project/bim/BimNavCubeWidget.vue"
import BimSectionPlanes from "@/components/project/bim/BimSectionPlanes.vue"
import TaskScreenshotDialog from "@/components/task/TaskScreenshotDialog.vue"

import ProjectControlRightBarToolbar from "@/components/project/control/rightBar/ProjectControlRightBarToolbar.vue"
import ViewerToolWindowBridge from "@/components/viewerTool/ViewerToolWindowBridge.vue"

import { mapState } from 'vuex'
import { PanelController } from "@/pages/panelController"

import { ViewerTool, useViewerToolsStore } from "@/pinia"
const store = useViewerToolsStore()

export default {
  name: 'ProjectLayerRight',
  
  components: {
    ProjectPanelElementTree,
    ProjectPanelTasks,
    ProjectProcessList,
    ProjectNotificationList,
    ProfileSettings,
    BimSectionPlanes,
    BimNavCubeWidget,
    TaskScreenshotDialog,
    ProjectControlRightBarToolbar,
    ViewerToolWindowBridge,
  },

  computed: {
    ...mapState('project', ['topUnit']),
    hasActiveTool: () => store.activeTool != ViewerTool.NONE,
    rework_isRightToolbarVisible: () => window.settings.rework?.projectTools?.rightToolbar ?? false,
    rework_isUpdatedTopToolbarVisible: () => window.settings.rework?.projectTools?.topToolbar ?? false,
    rework_isVisibleOldPlugins: () => window.settings?.rework?.projectTools?.oldPlugins ?? false,
  },

  methods: {
    clickOnCube() {
      PanelController.setPanelPadding(true)
    },
  }
}
</script>

<style lang="scss" scoped>
// .right-panel {
//   // background-color: var(--v-surface-base);
//   // height: calc(100% - 33px);
//   // padding: 8px 2px;
// }
.tools-window {
  position: absolute;
  right: 37px;
  top: 0px;

  overflow: auto;
  resize: vertical;
  min-height: 300px;
  max-height: calc(100vh - 48px);
  width: 300px;
  height: 300px;

  box-shadow: -1px 2px 2px 1px var(--v-surface-lighten2);
}
.right-gap {
  gap: 8px;
}
.toolbar-t {
  position: relative;
  width: 40px;
  height: calc(100% - 40px);
  background-color: gray;
}
.right-layer {
  height: calc(100% - 47px);
  position: absolute;
  right: 0px;
  top: 33px;
}

.under-all {
  z-index: -1000;
}

.sections {
  position: absolute;
  left: -250px;
  bottom: 65px;
}

.relative {
  position: relative;
}

.interactive {
  pointer-events: all;
}

#mySectionPlanesOverviewCanvas {
  width: 120px;
  height: 120px;
}

.width-panel {
  width: 320px;
}
</style>