<template lang="pug">
app-panel.px-1.py-2(ident='statistics')
  task-header.mb-2(:title="$t('statistics.header')" headerItemsCenter mainTitle )
    template( v-slot:headerAfter )
      app-icon-button( icon="mdi-close" @click="handleClose" test-id="close-app-panel" )
    //- template( v-slot:menu )
    //-   v-btn( icon @click="" style="outline: none !important;" dense ) 
    //-     v-icon( size="24" color="#959595") mdi-tune
  
  .base-scroll-body
    .statisticsSection.mb-2(v-if='checkExistModels')
      h2(style="color: #505254") {{$t("statistics.modelHeader")}}
      .modelStatistics(v-for="statistic in generateModelsStatistics")
        span(v-html='statistic')

    .statisticsSection.mb-2(v-if='checkExistModels')
      h2(style="color: #505254") {{$t("statistics.revTable")}}
      v-data-table#statistic-table(light :headers="headersRevTable" :items="itemsRevTable" :items-per-page="paginationItems.itemsPerPage")
        template(v-slot:item.title='{ item }')
          span.text {{ item.title }}
        template(v-slot:item.ifc='{ item }')
          span.offset {{ item.ifc }}
        template(v-slot:item.xktFile='{ item }')
          span.offset {{ item.xktFile }}
        template(v-slot:item.xktCHFile='{ item }')
          span.offset {{ item.xktCHFile }}
        template(v-slot:item.attribute='{ item }')
          span.offset {{ item.attribute }}
        template(v-slot:item.elements='{ item }')
          span.offset {{ item.elements }}
        template(v-slot:item.triangles='{ item }')
          span.offset {{ item.triangles }}
        template(v-slot:item.trianglesCH='{ item }')
          span.offset {{ item.trianglesCH }}
        //-  hide-default-footer :page.sync="paginationItems.page" @page-count="paginationItems.pageCount = $event")
      //- div(class="text-center pt-2")
      //- v-row
      //-   v-col.d-flex.justify-start(cols=3)
      //-     AppAutocomplete( dense auto-select-first
      //-       label="Страниц на экране" item-text="text" item-value="value" v-model="paginationItems.itemsPerPage" :items="paginationItems.pageCountList")
      //-   v-col.d-flex.justify-start
      //-     v-pagination.mt-5.ml-10(light v-model="paginationItems.page" :length="paginationItems.pageCount")

    .statisticsSection.mb-4(v-if='checkExistModels')
      members-table

</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import TaskHeader from '@/components/task/TaskHeader'
import { NotificationService } from '@/assets/model/notifications/NotificationService'
import MembersTable from './MembersTable'

let flatlist = function (tree, level = 0) {
  let array = []
  tree.forEach(element => {
    element.level = level
    array.push(element, ...flatlist(element.model, level + 1))
  })
  return array
}

export default {
  name: "Statistics",

  components: {
    TaskHeader,
    MembersTable,
  },

  data() {
    return {
      paginationItems: {
        page: 1,
        pageCount: 0,
        pageCountList: [
          {text: "5", value: 5}, {text: "10", value: 10}, {text: "15", value: 15}, {text: "Все", value: -1}
        ],
        itemsPerPage: 5,
      },
    }
  },

  computed: {
    ...mapState('project', ['project', 'projectSettings']),

    checkExistModels(){
      let psModelsSize = Object.keys(this.projectSettings.workModel).length
      let projectModelsSize = flatlist(this.project.model).length
      
      return psModelsSize && projectModelsSize && psModelsSize > 0 && projectModelsSize > 0
    },

    headersRevTable() {
      return [
        { text: this.$t("statistics.revTableHeader.name"), value: 'title', width: "12%" },
        { text: this.$t("statistics.revTableHeader.ifc"), value: 'ifc', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.xktFile"), value: 'xktFile', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.xktCHFile"), value: 'xktCHFile', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.attribute"), value: 'attribute', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.elements"), value: 'elements', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.triangles"), value: 'triangles', width: "12%", sort: this.sortBy },
        { text: this.$t("statistics.revTableHeader.trianglesCH"), value: 'trianglesCH', width: "12%", sort: this.sortBy },
      ]
    },

    itemsRevTable() {
      let projectModel = flatlist(this.project.model)
      let items = []

      let modelNames = {}
      projectModel.forEach(model => {
        modelNames[model.uuid] = model.title
      })

      projectModel.forEach(model => {
        let rev = model.revision
        
        if (rev) {
          let passport = rev.modelRevisionPassport

          let stat = {
            title: "",
            ifc: 0.0,
            xktFile: 0.0,
            xktCHFile: 0.0,
            elements: 0,
            attribute: 0,
            triangles: 0,
            trianglesCH: 0,
          }

          let modelStack = []
          if (model.stack) {
            model.stack.forEach(uuid => {
              let name = modelNames[uuid]
              if (name) {
                if (name.includes('model.name'))
                  name = this.$t(NotificationService.NotificationReplace(name))

                modelStack.push(name)
              }
            })
          }

          stat.title = modelStack.length > 0 ? modelStack.slice(1).join('/') : model.title
          if (stat.title.includes('model.name')) {
            stat.title = this.$t(NotificationService.NotificationReplace(stat.title))
          }

          if (passport?.ifcFileSize)
            // stat.ifc = (Math.round((parseFloat(passport.ifcFileSize) + Number.EPSILON) * 100) / 100)
            stat.ifc = this.outputFractionalNum(passport.ifcFileSize)

          if (passport?.xktFileSize)
            // stat.xktFile = (Math.round((parseFloat(passport.xktFileSize.toFixed(2).padStart(5, '0')) + Number.EPSILON) * 100) / 100)
            stat.xktFile = this.outputFractionalNum(passport.xktFileSize)

          if (passport?.convexHullFileSize)
            stat.xktCHFile = this.outputFractionalNum(passport.convexHullFileSize)

          if (passport?.countElements)
            stat.elements = this.addingSpaces(passport.countElements)

          if (passport?.countAttributes)
            stat.attribute = this.addingSpaces(passport.countAttributes)

          if (passport?.countTrianglesOriginal)
            stat.triangles = this.addingSpaces(passport.countTrianglesOriginal)

          if (passport?.countTrianglesConvexHull)
            stat.trianglesCH = this.addingSpaces(passport.countTrianglesConvexHull)
          items.push(stat)
        }
      })

      return items
    },

    generateModelsStatistics(){
      let countModel = 0
      let countModelActive = 0
      let ifcSize = 0.0
      let ifcSizeActive = 0.0
      let xktSize = 0.0
      let xktSizeActive = 0.0
      let convexSize = 0.0
      let convexSizeActive = 0.0
      let countElements = 0
      let countElementsActive = 0
      let countAttributes = 0
      let countAttributesActive = 0
      let countTrianglesOrig = 0
      let countTrianglesOrigActive = 0
      let countTrianglesConvex = 0
      let countTrianglesConvexActive = 0

      let activeModels = []
      Object.keys(this.projectSettings.workModel).forEach(modelUuid => {
        let model = this.projectSettings.workModel[modelUuid]
        if (model.revisionUuid && model.switchon)
          activeModels.push({uuid: modelUuid, ...model})
      })

      let allModels = flatlist(this.project.model)

      countModel = allModels.length
      countModelActive = activeModels.length

      allModels.forEach(m => {
        let passport = m?.revision?.modelRevisionPassport

        if (passport?.ifcFileSize)
          ifcSize += parseFloat(passport?.ifcFileSize)

        if (passport?.xktFileSize)
          xktSize += parseFloat(passport?.xktFileSize)

        if (passport?.convexHullFileSize)
          convexSize += parseFloat(passport?.convexHullFileSize)

        if (passport?.countAttributes)
          countAttributes += passport?.countAttributes

        if (passport?.countElements)
          countElements += passport?.countElements

        if (passport?.countTrianglesOriginal)
          countTrianglesOrig += passport?.countTrianglesOriginal

        if (passport?.countTrianglesConvexHull) 
          countTrianglesConvex += passport?.countTrianglesConvexHull
      })

      activeModels.forEach(m => {
        let model = allModels.find(p => p.uuid == m.uuid)
        let passport = model?.revision?.modelRevisionPassport

        if (passport?.ifcFileSize)
          ifcSizeActive += parseFloat(passport?.ifcFileSize)

        if (passport?.xktFileSize)
          xktSizeActive += parseFloat(passport?.xktFileSize)

        if (passport?.convexHullFileSize)
          convexSizeActive += parseFloat(passport?.convexHullFileSize)

        if (passport?.countAttributes)
          countAttributesActive += passport?.countAttributes

        if (passport?.countElements)
          countElementsActive += passport?.countElements

        if (passport?.countTrianglesOriginal)
          countTrianglesOrigActive += passport?.countTrianglesOriginal

        if (passport?.countTrianglesConvexHull) 
          countTrianglesConvexActive += passport?.countTrianglesConvexHull
      })

      let modelsStatistics = []

      if (countModel > 0 || countModelActive > 0)
        modelsStatistics.push(this.$t("statistics.models", { models: countModel, modelsA: countModelActive}))

      if (ifcSize > 0 || ifcSizeActive > 0)
        modelsStatistics.push(this.$t("statistics.ifc", { ifc: Math.round((ifcSize + Number.EPSILON) * 100) / 100, ifcA: Math.round((ifcSizeActive + Number.EPSILON) * 100) / 100 }))

      if (xktSize > 0 || xktSizeActive > 0)
        modelsStatistics.push(this.$t("statistics.xkt", { xkt: Math.round((xktSize + Number.EPSILON) * 100) / 100, xktA: Math.round((xktSizeActive + Number.EPSILON) * 100) / 100 }))

      if (convexSize > 0 || convexSizeActive > 0)
        modelsStatistics.push(this.$t("statistics.convex", { convex: Math.round((convexSize + Number.EPSILON) * 100) / 100, convexA: Math.round((convexSizeActive + Number.EPSILON) * 100) / 100 }))

      if (countElements > 0 || countElementsActive > 0)
        modelsStatistics.push(this.$t("statistics.countElements", { countElements: countElements, countElementsA: countElementsActive }))

      if (countAttributes > 0 || countAttributesActive > 0)
        modelsStatistics.push(this.$t("statistics.countAtr", { countAtr: countAttributes, countAtrA: countAttributesActive }))

      if (countTrianglesOrig > 0 || countTrianglesOrigActive > 0)
        modelsStatistics.push(this.$t("statistics.countTrianglesOrig", { countTrianglesOrig: countTrianglesOrig, countTrianglesOrigA: countTrianglesOrigActive }))

      if (countTrianglesConvex > 0 || countTrianglesConvexActive > 0)
        modelsStatistics.push(this.$t("statistics.countTrianglesConvex", { countTrianglesConvex: countTrianglesConvex, countTrianglesConvexA: countTrianglesConvexActive }))

      // if(this.project.createDate)
        modelsStatistics.push(this.$t("statistics.createDate", { createDate: this.project.createDate || "-" }))

      return modelsStatistics
    },
  },

  methods: {
    ...mapMutations('project', ['setActiveGlobalTab']),
    ...mapActions('project', ['postPanelsStatus']),
    
    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    },

    sortBy(a, b){
      return +a - +b
    },

    addingSpaces(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
    },

    outputFractionalNum(num) {
      let formatted = (Math.round(num * 100) / 100).toFixed(2)

      if (Number(formatted.split('.')[0]) < 0) {
        formatted = '0' + formatted;
      }

      return formatted
    }
  }
}
</script>

<style lang="scss" scoped>
.modelStatistics {
  font: normal 13px/24px $roboto;
  color: #505254;
}
.t-scroll-body {
  overflow-y: auto;
  height: calc(100% - 35px);
  overflow-x: hidden;
  scrollbar-width: thin;
}
.statisticsSection {
  background-color: white;
  padding: 8px;
}
::v-deep #statistic-table th {
  text-align: center !important;
}
.offset{
  display: flex;
  justify-content: flex-end !important;
}
.text {
  font-size: 12px;
}

::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--v-scroll-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: var(--v-accent-base);
}
::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: var(--v-scroll-base);
  -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07) !important;
}
</style>
