<template lang="pug">
  div
    .py-2.px-4.interactive( v-if="pickCoordinates.length > 0" )
      .text-caption.redText X: {{ pickCoordinates[0].toFixed(3) }}
      .text-caption.greenText Y: {{ pickCoordinates[1].toFixed(3) }}
      .text-caption.blueText Z: {{ pickCoordinates[2].toFixed(3) }}
</template>

<script>
import ProjectLayerBottom from '@@/project/layer/ProjectLayerBottom.vue'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

export default {
  components: {
    ProjectLayerBottom
  },

  computed: {
    pickCoordinates() {
      return XeokitMediator.PickCoordinateViewer.pickCoordinates
    }
  },

  mounted() {
    XeokitMediator.PickCoordinateViewer.activate()
  },

  beforeDestroy() {
    XeokitMediator.PickCoordinateViewer.deactivate()
  }
}
</script>

<style scoped>
.interactive {
  pointer-events: all;
}

.redText {
  font-size: 0.93rem !important;
  margin-bottom: 1px;
  color: #FF0000;
}

.greenText {
  font-size: 0.93rem !important;
  margin-bottom: 1px;
  color: #00FF00;
}

.blueText {
  font-size: 0.93rem !important;
  color: #0000FF;
}
</style>