var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-panel',{staticClass:"px-1 py-2",attrs:{"ident":"statistics"}},[_c('task-header',{staticClass:"mb-2",attrs:{"title":_vm.$t('statistics.header'),"headerItemsCenter":"","mainTitle":""},scopedSlots:_vm._u([{key:"headerAfter",fn:function(){return [_c('app-icon-button',{attrs:{"icon":"mdi-close","test-id":"close-app-panel"},on:{"click":_vm.handleClose}})]},proxy:true}])}),_c('div',{staticClass:"base-scroll-body"},[(_vm.checkExistModels)?_c('div',{staticClass:"statisticsSection mb-2"},[_c('h2',{staticStyle:{"color":"#505254"}},[_vm._v(_vm._s(_vm.$t("statistics.modelHeader")))]),_vm._l((_vm.generateModelsStatistics),function(statistic){return _c('div',{staticClass:"modelStatistics"},[_c('span',{domProps:{"innerHTML":_vm._s(statistic)}})])})],2):_vm._e(),(_vm.checkExistModels)?_c('div',{staticClass:"statisticsSection mb-2"},[_c('h2',{staticStyle:{"color":"#505254"}},[_vm._v(_vm._s(_vm.$t("statistics.revTable")))]),_c('v-data-table',{attrs:{"id":"statistic-table","light":"","headers":_vm.headersRevTable,"items":_vm.itemsRevTable,"items-per-page":_vm.paginationItems.itemsPerPage},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text"},[_vm._v(_vm._s(item.title))])]}},{key:"item.ifc",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.ifc))])]}},{key:"item.xktFile",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.xktFile))])]}},{key:"item.xktCHFile",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.xktCHFile))])]}},{key:"item.attribute",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.attribute))])]}},{key:"item.elements",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.elements))])]}},{key:"item.triangles",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.triangles))])]}},{key:"item.trianglesCH",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"offset"},[_vm._v(_vm._s(item.trianglesCH))])]}}],null,false,3691933369)})],1):_vm._e(),(_vm.checkExistModels)?_c('div',{staticClass:"statisticsSection mb-4"},[_c('members-table')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }