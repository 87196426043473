<template lang="pug">
//- app-panel(width = "450")
app-panel.pt-1.px-1.py-2( ident='members' padding="4px" )
  task-header.mb-2( :title="$t('rights.header.name')" headerItemsCenter mainTitle )
    template( v-slot:headerAfter )
      app-icon-button( icon="mdi-close" @click="handleClose" )
      
  app-section.m-2.base-scroll-body
    .edit-project__members-container.px-3.py-4
      .d-flex(v-for="(member, index) in membership" :key="index")
        app-avatar.mt-2.pa-0( 
          :text="memberName(member)" 
          :url="getAvatarUrl(member.profile)" 
          :colorObj="getBgColor(member.profile.uuid)"
          :class="`edit-project__members-avatar${member.owner ? '--owner' : ''}`"
        )
        .d-flex.flex-column
          .edit-project__members-name.primary--text.text--darken-4.mt-2.mx-3( v-line-clamp="2" ) {{ memberName(member) }} 
            div.grey--text.text--darken-1.text-caption.font-weight-bold(v-if="member.owner") {{ $t('rights.shortCut.owner') }}
          .black--text.text--darken-1.text-caption.font-weight-light.mx-3(v-for="ref in member.memberReference")
            v-tooltip(:right="true" open-delay=300 )
              template( v-slot:activator="{ on, attrs }" )
                div(v-for="orgRole in ref.orgRoles" v-on="on") 
                  v-icon(light small) mdi-account-group-outline
                  span {{ orgRole.title }} (из {{ ref.model.title }})
              span {{ $t('section.structure.model.organizationRoles') }}

            v-tooltip(:right="true" open-delay=300 )
              template( v-slot:activator="{ on, attrs }" )
                div(v-for="role in ref.roles" v-on="on") {{ role.title }} (из {{ ref.model.title }})
              span {{ $t('section.structure.model.commonRoles') }}
          
          .black--text.text-caption.font-weight-light.mx-3(v-if="member.lastVizited" ) {{ $t("rights.lastVizited.was") }} {{ $d(new Date(member.lastVizited), 'long', 'ru-RU') }}

</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { SourcePath } from '@app/SourcePath'
import TaskHeader from '@/components/task/TaskHeader'
import uniqolor from 'uniqolor'

export default {
  name: "Members",
  components: {
    SourcePath,
    TaskHeader
  },
  data() {
    return {

    }
  },
  // model: {
  //   prop: 'project',
  //   event: 'change'
  // },
  computed: {
    ...mapGetters("project", ["projectShortcut"]),

    //  membership () {
    //   return this.projectShortcut.membership?.map(item => {
    //     return item
    //   }).reverse() || []
    // },

    membership () {
      return (this.projectShortcut.membership?.map(item => {
        return {
          ...item,
          lastVizited: item.lastVizited ? item.lastVizited.replace(/-/g, '/') : null // fix для Safari
        };
      }).reverse() || []);
    },

    owner () {
      return this.projectShortcut.membership?.find(m => m.owner) || null
    },

    image () {
      if (this.projectShortcut.preview) 
        return SourcePath.preview(this.projectShortcut.preview)
      return this.placeholder
    },
  },

   methods: {
    ...mapActions('project', ['setProjectPreview', 'postPanelsStatus']),
    ...mapMutations('project', ['setActiveGlobalTab']),

    memberName(member) {
      return member.profile.name ? member.profile.name : member.orgGroup.title
    },

    getBgColor(uuid) {
      return uniqolor(uuid, { format: 'rgb' })
    },

    getAvatarUrl (profile) {
      return profile.avatar && SourcePath.avatar(profile.avatar)
    },

    async preparePreview() {
      var imagefile = document.getElementById('upload-preview')
      if (imagefile.files[0] == null) return
      await this.setProjectPreview({ uuid: this.setProjectPreview.uuid, file: imagefile.files[0] }).then(preview => {
        this.preview = preview
        this.setProjectPreview.preview = this.preview 
      })
    },

    handleClose() {
      this.setActiveGlobalTab('')
      this.postPanelsStatus()
    }
  },
}
</script>

<style scoped>
    
</style>
