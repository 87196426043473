<template lang="pug">
app-panel.pl-2( padding="0px" )
  app-floating-panels-section( style="height: calc(100% - 26px) !important; overflow: auto; overflow-x: hidden" )
    app-floating-panel( ref="floatingPanel" :title="(selectedAttribute && selectedAttribute.name) ? $t('section.classification.attribute.settingAttribute', {name: selectedAttribute.name}) : $t('section.classification.attribute.settingAttribut')"
                        bodyMinHeight="150" :startHeight="80" overflowBody="auto" :bodyColor="'#fff'" :settings="floatingPanels('ATTRIBUTE_SETTINGS')" )
      attribute-form.base-scroll-body( v-if="selectedAttribute" ref="attributeForm" :attribute="selectedAttribute" @toggle="toggleVisible" :units="unitTypes" :powers="powerTypes" )
      div.ma-auto( v-else style="color: rgba(0, 0, 0, 0.38)") {{ $t('section.classification.attribute.selectAttribute') }} 

    app-floating-panel( :title="$t('section.classification.attribute.linkedClassifier')" bodyMinHeight="150" :startHeight="50" overflowBody="auto" :bodyColor="'#fff'" :settings="floatingPanels('LINKED_ELEMENTS')" )
      template( v-if="selectedAttribute" )
        div.px-4.py-2.base-scroll-body
          div.elements( v-for="(item, index) in selectedAttributeClassificatorNodeWithProjectClassificatorName" )
            div( @contextmenu.prevent="showClassificatorMenu($event, item, index)" ) {{ item.projectClassificatorName }} - {{ item.title }}
            div.mb-auto.ml-auto
              v-icon( color='var(--v-primary-base)' :disabled='!hasClassificatorControlUniversalAttr' small @click.stop='showClassificatorMenu($event, item, index)' ) more_vert

          v-btn.mt-4.px-0( text color="accent" :disabled='!hasClassificatorControlUniversalAttr' x-small @click.stop="showBindingElementsDialog" )
            v-icon.mr-1 mdi-plus-circle-outline
            span {{ $t('section.classification.attribute.addBinding') }}
      div.d-flex.text( v-else ) {{ $t('section.classification.attribute.selectAttribute') }} 

      //- app-dialog( v-model="dialog.bindingElements" :header="$t('section.classification.attribute.addBinding')" :confirm-text="$t('button.add')" 
      //-             :confirmDisabled="!classificator.uuid" @confirm="createElement" )
        app-section
          app-select( 
            :items="filteredClassificatorNode"
            v-model='classificator.uuid'
            item-text="title"
            item-value="uuid"
            flat
            solo
            clearable
            open-on-clear
            dense
            outlined
            :menu-props="{light: true, maxWidth:'346'}"
            label="" )

      classificator-node-form-dialog(v-model="dialog.bindingElements" @addClassificatorNode="addClassificatorNode($event)")

      app-menu( ref="classificatorMenu" :menu="classificatorMenu" )
    
    app-floating-panel( v-if='showPanel' ref="hightLightGroups" :title="$t('section.collision.rulesFounder.viewElements')"  bodyMinHeight="150" :startHeight="80" overflowBody="auto" 
                        :bodyColor="'#fff'" :settings="floatingPanels('VIEWING_ITEMS')")
      hight-light-groups( ref="hightLightGroupsRefs" )
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import AttributeForm from './AttributeForm'
import HightLightGroups from '../../../components/element/HightLightGroups'
import ClassificatorNodeFormDialog from './ClassificatorNodeFormDialog.vue'
import { useHighlightGroupsStore } from '@/pinia/smeta/highlightGroups.store'

const projectClassificatorHash = {}
const highlighGroupsStore = useHighlightGroupsStore()

export default {
  name: 'attribute-setting-form',

  components: {
    AttributeForm,
    HightLightGroups,
    ClassificatorNodeFormDialog,
  },

  data() {
    return {
      sellectedAttribute: null,
      classificator: {
        uuid: null
      },
      
      dialog: {
        bindingElements: false,
      },
      shownMenuClassificatorItem: null,
      shownMenuClassificatorIdx: null,
    }
  },

  mounted() {
    this.setShowOnlyRules(true)
  },

  updated() {
    this.openPanel()
  },
  
  computed: {
    ...mapGetters('floatingPanels', ['floatingPanels']),
    ...mapGetters("smeta", ["treeAllVisibleNodes"]),
    ...mapState('projectDict', ['selectedAttribute']),
    ...mapState('elements', ['unitTypes', 'powerTypes']),
    ...mapGetters('smeta', ['getNode']),
    ...mapGetters('project', ['projectUuid']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),
    ...mapState('project', ['projectSettings']),
    ...mapState('projectDict', ['showPanel']),
    ...mapState('smeta', ['projectClassificators']),
    ...mapGetters("projectPermissions", ['hasClassificatorControlUniversalAttr']),

    // itemWithRules() {
    //   const withRules = this.findNodeWithRules(this.treeAllVisibleNodes)

    //   return withRules
    // },

    // filteredClassificatorNode() {
    //   return this.itemWithRules.filter(item1 => !this.selectedAttribute?.classificatorNode?.some(item2 => item2.uuid === item1.uuid));
    // },

    classificatorMenu() {
      return [
        { title: this.$t('section.classification.attribute.goClassifier'), action: () => this.switchToSmetaRuleEdit(this.shownMenuClassificatorItem) },
        { title: this.$t('section.classification.attribute.delete'), action: () => this.deleteClassificator(this.shownMenuClassificatorIdx) }
      ]
    },

    selectedAttributeClassificatorNodeWithProjectClassificatorName() {
      return this.selectedAttribute.classificatorNode.map(classificatorNode => {
        const projectClassificator = projectClassificatorHash[classificatorNode.projectClassificatorUuid] || this.projectClassificators.find(classificator => classificator.uuid === classificatorNode.projectClassificatorUuid)
        projectClassificatorHash[projectClassificator.uuid] = projectClassificator

        let classificatorNodeWithProjectClassificatorName = { ...classificatorNode }
        classificatorNodeWithProjectClassificatorName.projectClassificatorName = projectClassificator.title

        return classificatorNodeWithProjectClassificatorName
      })
    },

  },

  methods:{
    ...mapMutations("smeta", ['setShowOnlyRules', 'setExpandAfterLoadUuid']),
    ...mapActions("projectDict", ["editAttribute"]),
    ...mapMutations('projectDict', ['setShowPanel']),

    addClassificatorNode(event){
      this.selectedAttribute.classificatorNode.push(...event)
      this.saveElement()
      this.dialog.bindingElements = false
    },

    deleteClassificator(classificatorIdx) {
      this.selectedAttribute.classificatorNode.splice(classificatorIdx, 1)
      this.saveElement()
    },

    // findNodeWithRules(nodes) {
    //   let res = []

    //   nodes.forEach(node => {
    //     const children = node.children

    //     if (node.children.find(s => s.isRule)) {
    //       res.push(node)
    //     }

    //     res = res.concat(this.findNodeWithRules(children))
    //   })
    //   return res
    // },

    async toggleVisible() {
      this.setShowPanel(!this.showPanel)
      await this.$nextTick()
      if (this.showPanel) {
        this.loadElementsForHighLigths()
      }
      else highlighGroupsStore.resetState()
    },

    openPanel() {
      const attributeListPanel = this.$refs['floatingPanel']
      if (this.selectedAttribute) {
        !attributeListPanel?.openedPanel && attributeListPanel?.openBody()
        this.$refs['attributeForm']?.resetValidationForm()
        if (this.showPanel) {
          this.loadElementsForHighLigths()
        }
      }
      return this.$nextTick()
    },

    openPanelHightLightGroups() {
      const hightLightGroups = this.$refs['hightLightGroups']
      if (this.showPanel) {
        !hightLightGroups?.openedPanel && hightLightGroups?.openBody()
      }
      return this.$nextTick()
    },

    showBindingElementsDialog() {
      this.dialog.bindingElements = true
      this.classificator.uuid = ''
    },

    createElement() {
      const element= {
        uuid: this.classificator.uuid
      }
      this.selectedAttribute.classificatorNode.push(element)
      this.saveElement()
    },

    saveElement() {
      const attribute={
        ...this.selectedAttribute
      }
      this.editAttribute(attribute)
    },

    showClassificatorMenu(event, item, index) {
      if(this.hasClassificatorControlUniversalAttr) this.$refs.classificatorMenu.show(event)
      this.shownMenuClassificatorItem = item
      this.shownMenuClassificatorIdx = index
    },

    switchToSmetaRuleEdit(classificationItem) {
      let ps = JSON.parse(JSON.stringify(this.projectSettings))
      ps.projectClassificator = classificationItem.projectClassificatorUuid
      this.$store.dispatch('project/updateSettingsProjectClassificator', ps)
      this.setExpandAfterLoadUuid(classificationItem.uuid)
      this.$emit('scrollTo', classificationItem)
    },

    loadElementsForHighLigths() {
      highlighGroupsStore.loadDictHighLigthsGroup()
      this.$refs?.hightLightGroupsRefs?.init()
      this.openPanelHightLightGroups()
    },
     
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}

.elements {
  color: rgba(0,0,0,.87);
  font-size: 12px;
  display: flex;
  align-items: center;
}
</style>