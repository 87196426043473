<template lang="pug">
app-toolbar( gap="12px" )
  app-toolbar-button( :title="gridsTitle" :active="store.isGridsEnabled" :disabled="!store.isGridsAvailable" icon="$grid-icon" @click="showIFCGrids" ) 
  app-toolbar-button( :title="$t('headerToolbar.orthogonalProjection')" :active="store.isModeOrthogonal" icon="$orthogonal-projection-icon" @click="store.setOrthogonalMode" ) 
  app-toolbar-button( :title="$t('headerToolbar.perspectiveProjection')" :active="store.isModePrespective" icon="$perspective-projection-icon" @click="store.setPerspectiveMode" ) 
  app-toolbar-button( :title="$t('navigation.professional')" :active="store.isMouseNavigationPro" icon="$bird-icon" @click="store.setProfessionalMouseNavigation" ) 
  app-toolbar-button( :title="$t('navigation.user')" :active="store.isMouseNavigationCustom" icon="$3-d-view-icon" @click="store.setUserMouseNavigation" ) 
</template>

<script>
import { useModeStore } from '@/pinia/viewer/mode.store.js'

export default {
  computed: {
    store: () => useModeStore(),
    gridsTitle () {
      let title = this.$t('headerToolbar.showIfcGrids')
      let availableText = this.store.isGridsAvailable ? 'Доступны' : 'Недоступны'
      return `${title} (${availableText})`
    },
  },

  methods: {
    showIFCGrids () {
      this.store.showIFCGrids(this)
    }
  }
}
</script>