import { render, staticRenderFns } from "./RulesFounder.vue?vue&type=template&id=48c70d7d&scoped=true&lang=pug&"
import script from "./RulesFounder.vue?vue&type=script&lang=js&"
export * from "./RulesFounder.vue?vue&type=script&lang=js&"
import style0 from "./RulesFounder.vue?vue&type=style&index=0&id=48c70d7d&prod&scoped=true&lang=css&"
import style1 from "./RulesFounder.vue?vue&type=style&index=1&id=48c70d7d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c70d7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCol,VIcon,VProgressLinear,VRow,VSpacer,VVirtualScroll})
