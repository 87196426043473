<template lang="pug">
div
  div(v-if="teammate || inviteByRole")
    app-dialog( v-model="$_show" :header="headerTextDialog" )
      app-section
        template(v-if="rolesWithLocked.length > 0")
          .temmate-roles--text.pb-2 {{ $t('section.structure.model.commonRoles') }}
        v-treeview( v-model="checked" selected-color="#3B93AF" :items="rolesWithLocked" item-disabled ="locked" item-text="title" item-key="uuid" selectable hoverable dense return-object light )
        
        template(v-if="isCorpRolesLoaded && corpRoles.length > 0 && corpRolesWithLocked.length > 0")
          v-divider.mt-6
          .temmate-roles--text.py-2 {{ $t('section.structure.model.organizationRoles') }}
          v-treeview( v-model="corpChecked" selected-color="#3B93AF" :items="corpRolesWithLocked" item-disabled ="locked" item-text="title" item-key="uuid" selectable hoverable dense return-object light )
      
        v-divider.mt-6
      .d-flex.justify-end.mt-2
        app-button.mr-2( cancel @click="$_show = false" ) {{ $t('button.cancel') }}
        app-button( v-if="inviteByRole"  :disabled="!roleChecked" @click="copyLink") {{ $t('button.copyLink') }}
        app-button(v-else action :disabled="!roleChecked" @click="prepareTeammate") {{ $t('button.save') }}
</template>

<script>
import DialogToggable from '@/components/app/DialogToggable'
import { mapGetters, mapState, mapActions } from 'vuex'
import { copyTextToBuffer } from '@/utils'

export default {
  name: 'ProjectTeammateRoles',
  mixins: [ DialogToggable ],

  props: {
    teammate: Object,
    isGroup: {
      type: Boolean,
      default: false,
    },
    inviteByRole: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      checked: [],
      corpChecked: [],
      addingNewMember: false,
    }
  },

  computed: {
    ...mapGetters('roles', ['roles']),
    ...mapGetters('corp', {corpRoles:'roles', isCorpRolesLoaded: 'isRolesLoaded'}),
    ...mapState('project', ['selectedModel', 'project']),
    ...mapGetters("theme", ['themeName']),
    ...mapGetters("project", ["projectShortcut", "flatlist", 'iCanManageRolesOnModelEdit', 'iCanManageRolesOnModelAdd']),
    ...mapGetters("projectPermissions", ["meOwner"]),

    iCanManageRoles () {
      if (this.addingNewMember) return this.iCanManageRolesOnModelAdd()
      return this.iCanManageRolesOnModelEdit()
    },

    headerTextDialog () {
      if (this.teammate) {
        let { group, orgGroup, profile } = this.teammate
        
        if ( this.inviteByRole ) return this.$t('section.structure.model.teamLink') 
        if ( this.isGroup && group?.title )  return group.title 
        if ( orgGroup?.title ) return orgGroup.title 
        if ( profile?.name ) return profile.name 
      }

      return this.$t('section.classification.menuClassification.addRole') 
    },

    checkedUuids () {
      let checked = this.checked.filter(r => !r.locked)
      let corpChecked = this.corpChecked.filter(r => !r.locked)
      return {"roles": checked.map(r => r.uuid ) || [], "orgRoles": corpChecked.map(r => r.uuid ) || []}
    },

    member(){
      return this.isGroup ? this.projectShortcut.membership.find(mem => mem.profile.uuid === this.teammate?.group.uuid) : this.projectShortcut.membership.find(mem => mem.profile.uuid === this.teammate?.profile.uuid)
    },

    teammateRoles() {
      return this.member?.memberReference.map(m => m.roles.map(r => ({
        ...r,
        model: m.model
      }))).flat()
    },

    roleChecked() {
      return (this.checked.length > 0) || (this.corpChecked.length > 0)
    },

    rolesWithLocked () {
      let list = this.roles

      if (!this.meOwner) {
        if (this.iCanManageRoles.length == 0) return []
        list = list.filter(role => this.iCanManageRoles.includes(role.uuid))
      }

      return list.map(role => {
        const teammateRole = this.teammateRoles?.filter(teammateRole => teammateRole.uuid === role.uuid )

        let roleModel = teammateRole?.map(r => { return this.flatlist.find(model => model.uuid == r?.model.uuid) }).sort((a, b) => a.level > b.level ? 1 : -1);
        let modelArray = []
        if (roleModel && roleModel.length > 0) {
          modelArray = this.allParentAndChildrenModel(roleModel[0], modelArray)
        }

        if (this.addingNewMember && roleModel && roleModel.length > 0) {
          modelArray.push(roleModel[0])
        }
        const roleIsDisabled = modelArray.length > 0 ? modelArray.some(model => model.uuid == this.selectedModel.uuid) : false

        return {
          title: role.title,
          uuid: role.uuid,
          locked: roleIsDisabled,
        }
      })
    },

    corpRolesWithLocked () {
      let list = this.corpRoles 

      if (!this.meOwner) {
        if (this.iCanManageRoles.length == 0) return []
        list = list.filter(role => this.iCanManageRoles.includes(role.uuid))
      }

      return list.map(r => {
        const teammateRole = this.teammate?.orgRoles?.filter(role => role.uuid === r.uuid)
        let roleModel = teammateRole?.map(r => { return this.flatlist.find(model => model.uuid == r?.model.uuid) }).sort((a, b) => a.level > b.level ? 1 : -1);
        let modelArray = []
        if (roleModel && roleModel.length > 0) {
          modelArray = this.allParentAndChildrenModel(roleModel[0], modelArray)
        }
        if (this.addingNewMember && roleModel && roleModel.length > 0){
          modelArray.push(roleModel[0])
        }

        const roleIsDisabled = modelArray.length > 0 ? modelArray.some(model => model.uuid == this.selectedModel.uuid) : false

        return {
          title: r.title,
          uuid: r.uuid,
          locked: roleIsDisabled,
        }
      })
    },

  },

  methods: {
    ...mapActions('project', ['changeTeammateRoles', 'addMember', 'inviteMember', 'generateLinkInvite']),

    $_beforeShow(t) {
      this.addingNewMember = t?.actionAdd ? true : false
      this.checked = t?.roles || []
      this.corpChecked = t?.orgRoles || []
    },

    allParentAndChildrenModel(model, modelArray) {
      model?.model?.forEach(m => {
        modelArray.push(m)
        this.allParentAndChildrenModel(m, modelArray)
      })
      return modelArray
    },

    prepareTeammate () {
      if ( this.teammate.invite ) {
        this.inviteMember([{
          model: this.selectedModel.uuid, 
          email: this.teammate.profile.name, 
          roles: this.checkedUuids.roles,
          orgRoles: this.checkedUuids.orgRoles
        }]).then(() => {
          this.$emit('invite', event, this.teammate.profile.name)
        })
      } 
      else {
        // if (this.teammate.uuid && (this.roleNotFoundOnModel(this.teammate.roles) || this.roleNotFoundOnModel(this.teammate.orgRoles)) ) {
        if (!this.addingNewMember && this.teammate.uuid ) {
          this.changeTeammateRoles({ teammateUuid: this.teammate.uuid, roles: this.checkedUuids })
        }
        else {
          this.addMember({
            modelUuid: this.selectedModel.uuid, 
            profileUuid: this.isGroup ? this.teammate.group.uuid : this.teammate.profile.uuid, 
            isGroup: this.isGroup,
            roles: this.checkedUuids
          }).then(() => {
            this.$emit('invite', event, this.isGroup ? this.teammate.group.uuid : this.teammate.profile.uuid)
          })
        }
      }
      this.hide()
    },

    roleNotFoundOnModel (list) {
      return !list.find( role => role.model.uuid === this.selectedModel.uuid) 
    },

    async copyLink () {
        let inviteUuid = await this.generateLinkInvite(this.checkedUuids)
        let url = location.href + "?invite=" + inviteUuid
        copyTextToBuffer(url)
        this.hide()
    },
  }
}
</script>
<style scoped>
.temmate-roles--text {
  color: #B8B8B8; 
  font-size: 13px;
  line-height: 18px;
}

::v-deep .v-treeview-node__level {
  width: 0px;
}
</style>