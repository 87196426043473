<template lang="pug">
  v-form( ref="valueForm" @submit.prevent="submit" )
    div(v-if="attrKey() && attrKey()!=''") 
      app-text.mb-2 Расположение элементов 
        span( v-if="attrKey() == 'axis'" )
          b на оси 
        span( v-if="attrKey() == 'near'" )
          b рядом с осью
          | (допуск 1 м)
        
        span( v-if="attrKey() == 'intersection'" )
          b на пересечении осей
        
        span( v-if="attrKey() == 'area'" )
          b в области между осей
          | (допуск 1 м)

        v-row
          v-col(cols="auto")
            v-img.mt-8(:src="`/img/grid/${attrKey()}.svg`" width="96" height="96" )
          v-col
            app-dialog(v-model="dialog.axis" header="Выбор осей" confirmText="Выбрать" :width="attrKey() == 'area' ? '600px' : '400px'") 
              app-section
                v-row( v-if="attrKey() == 'axis'" )
                  v-col
                    v-checkbox.my-2.ml-2(v-for="item in grid.x" :key="item.uuid" v-model="gridForm.x" dense hide-details light :value="item.uuid") 
                      template(v-slot:label)
                        app-text.mx-n1 {{item.label}}
            
                  v-col
                    v-checkbox.my-2.ml-2(v-for="item in grid.y" :key="item.uuid" v-model="gridForm.y" dense hide-details light :value="item.uuid") 
                      template(v-slot:label)
                        app-text.mx-n1 {{item.label}}

                v-row( v-else-if="attrKey() != 'area'" )
                  v-col
                    v-radio-group(v-model="gridForm.x[0]" dense hide-details light)
                      v-radio(v-for="item in grid.x" :key="item.uuid" :label="item.label" :value="item.uuid")
            
                  v-col
                    v-radio-group(v-model="gridForm.y[0]" dense hide-details light)
                      v-radio(v-for="item in grid.y" :key="item.uuid" :label="item.label" :value="item.uuid")

                v-row( v-else)
                  v-col
                    app-text.ml-2 X1
                    v-radio-group(v-model="gridForm.x[0]" dense hide-details light)
                      v-radio(v-for="item in grid.x" :key="item.uuid" :label="item.label" :value="item.uuid")

                  v-col
                    app-text.ml-2 X2
                    v-radio-group(v-model="gridForm.x[1]" dense hide-details light)
                      v-radio(v-for="item in grid.x" :key="item.uuid" :label="item.label" :value="item.uuid")
            
                  v-col
                    app-text.ml-2 Y1
                    v-radio-group(v-model="gridForm.y[0]" dense hide-details light)
                      v-radio(v-for="item in grid.y" :key="item.uuid" :label="item.label" :value="item.uuid")

                  v-col
                    app-text.ml-2 Y2
                    v-radio-group(v-model="gridForm.y[1]" dense hide-details light)
                      v-radio(v-for="item in grid.y" :key="item.uuid" :label="item.label" :value="item.uuid")

              
            v-row.mt-2( v-if="attrKey() != 'area'" )
                v-col
                  app-text.text--lighten-3 Ось
                  app-text-field.with-border( :value="gridTitle()" solo hide-details readonly @click.native="showDialog('axis')" @click:append="showDialog('axis')" append-icon="arrow_drop_down")
                v-col
            v-row.mt-2( v-if="attrKey() == 'area'" )
                v-col
                  app-text.text--lighten-3 Область в осях
                  app-text-field.with-border( :value="gridTitle()" solo hide-details readonly @click.native="showDialog('axis')" @click:append="showDialog('axis')" append-icon="arrow_drop_down")


            
            div( v-if="attrKey() != 'area'" )
              v-row.mt-2
                v-col
                  app-text.text--lighten-3.mark-bottom Отметка низ
                  app-combobox.with-border(v-model="gridForm.bottom" :items="gridZ" label="Отметка низ" item-value="elevation" item-text="label" solo)
                v-col
                  app-text.text--lighten-3.mark-top Отметка верх
                  app-combobox.with-border(v-model="gridForm.top" :items="gridZ" label="Отметка верх" item-value="elevation" item-text="label" solo)
              
            div( v-if="attrKey() != 'axis'" )
              v-checkbox.my-2(v-model="gridForm.whole" dense hide-details light) 
                 template(v-slot:label)
                   app-text.text--lighten-3.mx-n1 Элементы целиком

</template>

<script>
import { $_validate_rules } from '@/common/ValidateRules'
import { mapGetters, mapState } from 'vuex';

 
export default {
  name: "ConditionGridValue",

  mixins: [$_validate_rules],

  props:{
    condValues: null,
    isCollisionRule: null
  },

  data () {
    return {
      dialog: {
        axis: false 
      },
    }
  },

  computed:{
    ...mapState('condition', ['form', 'grid', 'gridForm']),
    ...mapGetters('condition', ['activeClass', 'isGridProp', 'gridZ']),
    ...mapGetters('rule/details', ['rule']),

  },

  methods:{
    showDialog(item) {
      this.dialog[item] = true
    },

    setData() {
      if(this.attrKey() == 'near' || this.attrKey() == 'intersection') {
        if(this.gridForm.x.length > 1) this.gridForm.x.splice(1, this.gridForm.x.length - 1)
        if(this.gridForm.y.length > 1) this.gridForm.y.splice(1, this.gridForm.y.length - 1)
      }
      if(this.attrKey() == 'area') {
        if(this.gridForm.x.length > 2) this.gridForm.x.splice(2, this.gridForm.x.length - 2)
        if(this.gridForm.y.length > 2) this.gridForm.y.splice(2, this.gridForm.y.length - 2)
      }
      this.form.title = this.form.operand.title + "/" + this.attrName() + " '" + this.gridTitle() + "'"
      this.form.gridCondition = this.gridForm
    },

    gridTitle() {
      let title = ""
      if(this.attrKey() == 'axis') {
        this.gridForm.x.forEach( x => {
          title += this.grid.x.find(g => g.uuid==x)?.label + " "
        })
        title += " x "
        this.gridForm.y.forEach( y => {
          title += this.grid.y.find(g => g.uuid==y)?.label + " "
        })
      } 
      if(this.attrKey() == 'near' || this.attrKey() == 'intersection') {
          title += this.gridForm.x[0] ? this.grid.x.find(g => g.uuid==this.gridForm.x[0])?.label + " " : ""
          title += " x "
          title += this.gridForm.y[0] ? this.grid.y.find(g => g.uuid==this.gridForm.y[0])?.label : ""
      }
      if(this.attrKey() == 'area') {
          title += this.gridForm.x[0] ? this.grid.x.find(g => g.uuid==this.gridForm.x[0])?.label + " " : ""
          title += this.gridForm.x[1] ? this.grid.x.find(g => g.uuid==this.gridForm.x[1])?.label + " " : ""
          title += " x "
          title += this.gridForm.y[0] ? this.grid.y.find(g => g.uuid==this.gridForm.y[0])?.label + " " : ""
          title += this.gridForm.y[1] ? this.grid.y.find(g => g.uuid==this.gridForm.y[1])?.label : ""
      }
      return title
    },

    submit(){
      return this.$refs.valueForm.validate()
    },

    validate(){
      if(this.attrKey() != 'area') {
        return this.gridForm.top != null && this.gridForm.bottom != null && (this.gridForm.x.length > 0 || this.gridForm.y.length > 0)
      } else {
        return this.gridForm.x.length > 0 || this.gridForm.y.length > 0
      }
    },

    attrKey() {
      return this.form.operandUuid ? this.condValues.find(v => v.uuid == this.form.operandUuid)?.attr : ""
    },

    attrName() {
      return this.form.operandUuid ? this.condValues.find(v => v.uuid == this.form.operandUuid)?.name : ""
    },

  }
}
</script>

<style lang='scss'>
  .mark-bottom {
    background-image: url('/img/grid/bottom.svg');
    background-position: 0 50%;
    padding-left: 12px;
  }
  .mark-top {
    background-image: url('/img/grid/top.svg');
    background-position: 0 50%;
    padding-left: 12px;
  }
  .with-border {
    border-radius: 0;
    border: 1px solid;
    border-color: var(--v-surface-lighten2);
    * {
     cursor: pointer;
    }
  }
</style>
