import { render, staticRenderFns } from "./ClassificatorNodeFormDialog.vue?vue&type=template&id=2a5521ee&scoped=true&lang=pug&"
import script from "./ClassificatorNodeFormDialog.vue?vue&type=script&lang=js&"
export * from "./ClassificatorNodeFormDialog.vue?vue&type=script&lang=js&"
import style0 from "./ClassificatorNodeFormDialog.vue?vue&type=style&index=0&id=2a5521ee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a5521ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VDivider,VForm,VIcon,VProgressLinear,VSkeletonLoader})
