import Stomp from 'webstomp-client'
import SockJS from 'sockjs-client'
import { App } from '@/assets/app/App'
import authUser from '@/store/authUser.module'
import project from '@/store/project.module'
import notification from '@/store/notification.module'
import process from '@/store/process.module'
import { config } from '@/_helpers'
import socketActions from './notificationSocketActions'
import { useAttributeCheckingTableStore } from '@/pinia' 
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

import store from '@/store'

let notificationConfig = config.notificationUrl

let stompClient
let subscribedTopic
let subscribedTopicProjectProcesses

let socketConnectPromise = null
let onSocketConnect = null
let socketConnected = false

let sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

let awaitDecorator = (f) => {
  return async function () {
    if (stompClient.connected) return f.apply(this, arguments)
    await sleep(300)
    return f.apply(this, arguments)
  }
}

export function webSocketConnect() {
  let sockjs = new SockJS(`${notificationConfig}ws?access_token=${App.auth.accessToken}`)
  stompClient = Stomp.over(sockjs)

  stompClient.send = awaitDecorator(stompClient.send)
  stompClient.subscribe = awaitDecorator(stompClient.subscribe)

  stompClient.debug = () => {}
  stompClient.connect({}, onConnected)
  sockjs.onclose = () => {
    setTimeout(() => {
      webSocketConnect()
    }, 30000)
  }
}

export function webSocketDisConnect() {
  if (socketConnected) stompClient.disconnect()
    
  socketConnected = false
}

function onConnected() {
  socketConnected = true
  onSocketConnect?.(socketConnected)

  if (project.getters.projectUuid(project.state)) {
    stompClient.subscribe(`/user/${authUser.state.user.uuid}/topic/${project.getters.projectUuid(project.state)}/notifications`, onNotification)
    stompClient.subscribe(`/user/${authUser.state.user.uuid}/topic/active/process`, onNewProcess)
    stompClient.subscribe(`/topic/project/${project.getters.projectUuid(project.state)}/active/process`, onNewProjectProcess)
    sendProject()
    sendStatusProcess()
  } else {
    stompClient.subscribe(`/user/${authUser.state.user.uuid}/topic/notifications`, onNotification)
    send()
  }
}

export function waitForConnect() {
  if (!socketConnectPromise) {
    socketConnectPromise = new Promise(resolve => {
      onSocketConnect = resolve
    })
  }
  if (socketConnected) {
    onSocketConnect?.(socketConnected)
  }
  return socketConnectPromise
}

export async function processSubscribeTopic() {
  await waitForConnect()
  stompClient.subscribe(`/user/${authUser.state.user.uuid}/topic/process`, onProcess).then(data => subscribedTopic = data)
  stompClient.subscribe(`/topic/project/${project.getters.projectUuid(project.state)}/process`, onProjectProcess).then(data => subscribedTopicProjectProcesses = data)
}

export async function processUnsubscribeTopic() {
  await waitForConnect()
  subscribedTopic.unsubscribe()
  subscribedTopicProjectProcesses.unsubscribe()
}

function onNotification(message) {
  let body = JSON.parse(message.body)
    notification.mutations.setSocketFlag(notification.state, body)
  notification.mutations.setNewNotificationStatus(notification.state, body)
  
  let bodyRevision

  switch (body?.notificationType?.name) {
    case 'COLLISIONS_READY':
      store.dispatch('rule/reloadTree').then(() => 
        useAttributeCheckingTableStore().getCollisionAttrRules())
      store.dispatch('axis/tree/fetchTree')
      break
      
    case 'REVISION_READY':
      if (body.author.uuid === store.getters["authUser/myUUID"]) {

        XeokitMediator.Models.modelsMap[store.state.project.projectSettings.workModel[body.source.modelUuid].revisionUuid]?.destroy()

        store.dispatch('project/loadProject', store.getters["project/projectUuid"]).then(() => {
          store.getters["project/flatlist"].map(q => {
            if(q.uuid == body.source.modelUuid) {
              bodyRevision = q.revision
            }
          })
          
          store.dispatch('project/makeRevisionActive', { modelUuid: body.source.modelUuid, rev: bodyRevision })
        })            
      }
    break

    case 'POINT_CLOUD_READY':
      if (body.author.uuid === store.getters["authUser/myUUID"]) {
        store.dispatch('project/loadProject', store.getters["project/projectUuid"]).then(() => {
          let pointCloudUUID = body.source.uuid
          let model = store.getters["project/flatlist"].find(m => {
            return m.pointCloud && m.pointCloud.uuid === pointCloudUUID
          })
          
          store.getters["project/projectSettings"].workModel[model.uuid].switchon  = true
          store.dispatch('project/updateModelSettings', store.getters["project/projectSettings"])
        })            
      }
    break

    case 'PATCH_DELETED':
    case 'PATCH_DISABLED':
    case 'PATCH_READY':
      if (body.author.uuid === store.getters["authUser/myUUID"]) {
        store.dispatch('patches/loadPatchList')
        store.dispatch('project/loadProject', store.getters["project/projectUuid"])
      }
      break

    case 'SMETA_RESULT_SUCCESS':
    case 'REPORT_RESULT_SUCCESS':
    case 'WORM_RESULT_SUCCESS':
      socketActions.getExportResult(body.source.processUuid)
      break
    case 'GENERATE_REPORT_READY':
      socketActions.getGenerateReport(body.source)
      break

    case 'WORKSPACE_RESTORE':
    case 'WORKSPACE_DELETED':
      socketActions.updateWorkSpaces(body.source)
      break

    case 'ATTACH_TASK_COLLISIONS_SUCCESS':
      if (body.author.uuid === store.getters["authUser/myUUID"]) {
        store.dispatch('rule/reloadTree').then(() => 
          useAttributeCheckingTableStore().getCollisionAttrRules())
        store.dispatch('axis/tree/fetchTree')
      }
      break
      

    default: //временное решение 
      if (store.state.axis.tree.element) {
        store.dispatch('axis/tree/updateFetchElementByGlobalId', store.state.axis.tree.element.uuid)
      }
  }
}

function onNewProcess(processList) {
  process.mutations.setProcessSocketFlag(process.state, JSON.parse(processList.body))
}

function onNewProjectProcess(hasActiveProjectProcess) {
  process.mutations.setOnlyProjectProcessSocketFlag(process.state, JSON.parse(hasActiveProjectProcess.body))
}

function onProcess(processList) {
  let body = JSON.parse(processList.body)

  process.mutations.setProcessList(process.state, body)
}

function onProjectProcess(processList) {
  let body = JSON.parse(processList.body)

  process.mutations.setOnlyProjectProcessList(process.state, body)
}

export function send() {
  stompClient.send('/app/notifications', {}, {})
}

export function sendProject() {
  stompClient.send('/app/project/notifications', JSON.stringify(project.getters.projectUuid(project.state)), {})
}

export async function sendProcess() {
  await waitForConnect()
  stompClient.send('/app/process', JSON.stringify(project.getters.projectUuid(project.state)), {})
}

export function sendStatusProcess() {
  stompClient.send('/app/process/status', JSON.stringify(project.getters.projectUuid(project.state)), {})
}
