<template lang="pug">
.rightbar-wrapper
  app-toolbar.surface.paddings( gap="12px" )
    template( v-for="props in buttons" )
      app-toolbar-button( 
        v-if="!props.hasMenu"
        :key="props.tool"
        :active="activeTool == props.tool" 
        :title="$t(`project.control.viewerTool.${props.tool}`)"
        v-bind="props" 
        @click="toggleTool(props.tool)" 
      )
      v-menu( v-else background-color="white" :key="props.tool" )
        template( v-slot:activator='activator' )
          app-toolbar-button( 
            :active="activeTool == props.tool"
            :icon="activePluginIcon" 
            :title="activePluginTitle" 
            :disabled="isPluginsDisabled"
            corner="left"
            @click="toggleTool(props.tool)" 
            @mousedown='onTouchStart(activator)'
            @mouseup="onTouchEnd" 
          )
        PluginMenu
  SelectionToolbar.surface.paddings
  ModesToolbar.surface.paddings

  //- Заглушка снизу тулбара
  .surface.flex-shrink-1.flex-grow-1
</template>

<script>
import PluginMenu from '@/components/viewerTool/plugins/PluginMenu.vue'
import { toolbarIcons, titles } from '@/components/viewerTool/plugins/ruler/RulerModeService'
import ModesToolbar from './ModesToolbar.vue'
import SelectionToolbar from './SelectionToolbar.vue'
import { ViewerTool, useViewerToolsStore } from "@/pinia"
import { useViewerPluginsStore, ViewerPlugin } from "@/pinia"
import { useRulerStore } from "@/pinia/viewer/ruler.store"

const store = useViewerToolsStore()
const pluginStore = useViewerPluginsStore()
const rulerStore = useRulerStore()

let timer

export default {
  components: {
    ModesToolbar,
    SelectionToolbar,
    PluginMenu,
  },
  
  computed: {
    activeTool: () => store.activeTool,
    isPluginsDisabled: () => pluginStore.isPluginsDisabled,

    groupsBtn: () => ({ tool: ViewerTool.GROUPS, icon: '$viewer-toolbar-groups-icon', size: '32', iconSize: '32' }),
    fragmentsBtn: () => ({ tool: ViewerTool.SCENE, icon: '$viewer-toolbar-fragments-icon', size: '32', iconSize: '28' }),
    colorsBtn: () => ({ tool: ViewerTool.COLORS, icon: '$viewer-toolbar-colors-icon', size: '32', iconSize: '32' }),
    rulerBtn () { return ({ tool: ViewerTool.PLUGINS, icon: this.activePluginIcon, size: '32', iconSize: '32', hasMenu: true }) },

    activePluginIcon() {
      if (pluginStore.activeTab == ViewerPlugin.COORDINATE_PICKER) return '$viewer-gps-icon'
      else if (pluginStore.activeTab == ViewerPlugin.SECTION_CUBE) return '$viewer-cube-icon'
      else if (pluginStore.activeTab == ViewerPlugin.SECTION_PLANES) return '$viewer-planes-icon'
      else if (pluginStore.activeTab == ViewerPlugin.MEASUREMENTS) {
        return  toolbarIcons[rulerStore.mode]
      }
      return '$viewer-toolbar-ruler-icon'
    },
      
    activePluginTitle() {
      if (pluginStore.activeTab == ViewerPlugin.COORDINATE_PICKER) return 'Координаты'
      else if (pluginStore.activeTab == ViewerPlugin.SECTION_CUBE) return 'Куб сечений'
      else if (pluginStore.activeTab == ViewerPlugin.SECTION_PLANES) return 'Сечение модели'
      else if (pluginStore.activeTab == ViewerPlugin.MEASUREMENTS) {
        return titles[rulerStore.mode]
      }
      return 'Измерения и сечения'
    },

    buttons () {
      return [this.groupsBtn, this.colorsBtn, this.fragmentsBtn, this.rulerBtn]
    },
  },

  watch: {
    isPluginsDisabled() {
      store.activeTool = ViewerTool.NONE
      // pluginStore.setActiveTab(ViewerPlugin.NONE)
      pluginStore.deactivateAll()
    },
  },

  methods: {
    toggleTool: (tool) => store.activeTool = store.activeTool != tool ? tool : ViewerTool.NONE,

    onTouchStart(activator) {
      timer = setTimeout(() => { activator.value = true }, 500)
    },

    onTouchEnd() {
      if (timer) clearTimeout(timer)
    }
  }
}
</script>

<style scoped>
.rightbar-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: stretch;
  gap: 1px;
  height: 100%;
}
.paddings {
  padding: 8px 2px;
}
</style>