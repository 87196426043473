<template lang="pug">
.d-flex.align-center
  app-toolbar-button.mx-4( icon="$search-icon" :title="$t('headerToolbar.search')" @click="searchElements" )
  search-elements-field( v-if="isSearchActive" )

  ProjectControlTopBarToolbar.ml-5.mr-2( v-if="rework_isUpdatedTopToolbarVisible && !$app.version.isCloud" )

  .d-flex.align-center( v-if="!rework_isUpdatedTopToolbarVisible" )
    app-toolbar-button.ml-5( icon="$grid-icon" :active="gridsActivity" @click="showIFCGrids" :title="$t('headerToolbar.showIfcGrids')" )

    .vertival-separator

    app-toolbar( horizontal )
      app-toolbar-button( icon="$orthogonal-projection-icon" :active="activeOrthogonalMode"  @click="orthogonalMode" :title="$t('headerToolbar.orthogonalProjection')" )
      app-toolbar-button( icon="$perspective-projection-icon" :active="!activeOrthogonalMode" @click="perspectiveMode" :title="$t('headerToolbar.perspectiveProjection')" )

    .vertival-separator

    app-toolbar( horizontal )
      app-toolbar-button( icon="$bird-icon" :active="mouseNav==='professional'" @click="setModeMouseNav('professional')" :title="$t('navigation.professional')" )
      app-toolbar-button( icon="$3-d-view-icon" :active="mouseNav==='user'" @click="setModeMouseNav('user')" :title="$t('navigation.user')" )

    .vertival-separator

    app-toolbar( v-if="!$app.version.isCloud" horizontal )
      app-toolbar-button( icon="$notification-icon" :badge="hasNotification" :title="$t('projectToolbarMenu.notifications')" :active="topUnit=='notification'" @click="setUnit('notification')" test-id="layer-top-notif-btn" )
      app-toolbar-button( icon="$format-list-icon"  :title="$t('projectToolbarMenu.tasks')" :active="topUnit=='task'" @click="setUnit('task')"  test-id="layer-top-task-btn" )
      app-toolbar-button( icon="$forward-icon" :badge="hasActiveProcess" :title="$t('projectToolbarMenu.activeProcess')" :active="topUnit=='process'" @click="setUnit('process')" test-id="layer-top-process-btn" )
      app-toolbar-button( icon="$admin-icon" :title="$t('projectToolbarMenu.user')" :active="topUnit=='profileSettings'" @click="setUnit('profileSettings')" )
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SearchElementsField from "../common/searchElements/SearchElementsField.vue"
import ProjectControlTopBarToolbar from '@/components/project/control/topBar/ProjectControlTopBarToolbar.vue'
import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { IFCGridsService } from "@/plugins/IFCGrids/IFCGridsService"

export default {
  components: {
    ProjectControlTopBarToolbar,
    SearchElementsField
  },

  data() {
    return {
      isSearchActive: false,
    }
  },

  mounted() {
    this.setTopUnit(this.topUnit)
    this.setFilterPanel(false)
    this.setSelectedTask(null)
  },

  computed: {
    ...mapState('project', ['topUnit', 'project']), 
    ...mapGetters('notification', ['hasNotification']),
    ...mapGetters('process', ['hasActiveProcess']),
    ...mapState('authUser', ['mouseNav']),
    ...mapState('IFCGrids', ['gridsActivity']),

    rework_isUpdatedTopToolbarVisible: () => window.settings.rework?.projectTools?.topToolbar ?? false,

    activeOrthogonalMode() {
      return XeokitMediator.ProjectionMode.projectionMode === XeokitMediator.ProjectionMode.Modes.ORTHO
    },

    mouseNav() {
      return XeokitMediator.MouseNavigation.mouseNav
    },
  },

  methods: {
    ...mapMutations('project', ['setTopUnit']), 
    ...mapMutations('task', ['setFilterPanel', 'setSelectedTask']),
    ...mapActions("authUser", ["setProfileSettingModeMouseNav"]),
    ...mapActions('project', ['postPanelsStatus']),

    perspectiveMode() {
      XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.PERSPECTIVE)
    },

    orthogonalMode() {
      XeokitMediator.ProjectionMode.setProjectionMode(XeokitMediator.ProjectionMode.Modes.ORTHO)
    },

    setModeMouseNav (mouseMode) {
      this.setProfileSettingModeMouseNav(mouseMode)
      XeokitMediator.MouseNavigation.setMouseNavigation(mouseMode)
    },

    setUnit (unit = '') {
      if (this.topUnit !== unit) { 
        this.setTopUnit(unit)
        this.setSelectedTask(null)
      } 
      else { 
        this.setSelectedTask(null)
        this.setTopUnit('DEFAULT')
      }
      this.postPanelsStatus()
    },

    showIFCGrids () {
      IFCGridsService.handleGridsDisplay(this)
    },

    searchElements() {
      this.isSearchActive = !this.isSearchActive
    }
  }
}
</script>

<style scoped>
.vertival-separator {
  height: 40px;
  width: 2px;
  margin: 0 8px;
  background-color: var(--v-surface-lighten2);
}
</style>