<template lang="pug">
  .inline.ml-2
    app-toolbar
      app-toolbar-button( icon="$viewer-fit-icon" @click="setModelsFitToVisibleAndResize()" iconSize="32px" :title="$t('viewer.tools.defaultView')" titleOrientation='right' test-id="default-view-btn")
      app-toolbar-button( v-if="rework_isVisibleOldPlugins" icon="$viewer-gps-icon" :active="isPickCoordinateViewerActive" :title="$t('viewer.tools.originCoordinates')" :disabled='atModelPositioning' titleOrientation='right' @click="togglePickCoordinateViewer()" )
      .mt-4
      //- РЕЖИМЫ ЛИНЕЙКИ ПОТОМ ВКЛЮЧИТЬ
      v-menu( v-if="rework_isVisibleOldPlugins" offset-x background-color="white" )
        template( v-slot:activator='activator' )
          app-toolbar-button( :icon="regimeMeasurementsModes.find(mode => mode.active == true).menuIcon" iconSize="32px" 
                              :title="regimeMeasurementsModes.find(mode => mode.active == true).title" titleOrientation='right'
                              :active="isRegimeMeasurementActive"
                              :disabled='atModelPositioning'
                              @contextmenu.prevent.native="() => !atModelPositioning && (openContextMenu(), activator.value = true )" 
                              @click="toggleRegimeMeasurements()"
                              @mousedownleft="handleMouseDown(activator)"
                              @mouseupleft="activator.value = false")

        div.d-flex( v-if="showPanel")
          div.d-flex.flex-column.align-start.justify-start.viewer-ruler-menu
            div.button.full-width( v-for="(regimeMeasurement, index) in regimeMeasurementsModes" 
                                                          :class="{ 'mb-2': index < regimeMeasurementsModes.length - 1, 'active-viewer-ruler-menu': regimeMeasurement.active }"
                                                          @click="regimeMeasurement.action" @mouseup="handleMouseUp(regimeMeasurement.action)")
              v-hover( #default="{ hover }" )
                div.full-width( :style="hover ? 'background-color: #c0c0c0;' : ''" )
                  div.pl-4.d-flex.align-center.justify-start
                    v-icon.style( :size="32" 
                                  color="white" 
                                  test-id="left-panel-btn" 
                                  @click.native="$emit('click')" ) {{ regimeMeasurement.icon }}
                    div.d-flex
                      span.ml-2( v-html="regimeMeasurement.title" )

      app-toolbar-button( v-if="rework_isVisibleOldPlugins" icon="$viewer-planes-icon" iconSize="32px" :disabled='atModelPositioning' :active='pickSectionPlanesActive' @click='togglePickSectionPlanesActive()' :title="$t('viewer.tools.sectionPlane')" titleOrientation='right')
      app-toolbar-button( v-if="rework_isVisibleOldPlugins" icon="$viewer-cube-icon" iconSize="32px" :disabled='atModelPositioning' :active="isSectionCubeActive" @click='toggleSectionCubePlugin()' :title="$t('project.sectionCube.tooltipTitle')" titleOrientation='right')
    
    app-toolbar.mt-4( v-if="rework_isVisibleSelectionToolbar" )
      app-toolbar-button( icon="$viewer-element-selector-icon" iconSize="32px" :disabled='atModelPositioning' :active="isSelectionFrameActive" @click="toggleHighlightFrame()" :title="$t('viewer.tools.highlightFrame')" titleOrientation='right')
      app-toolbar-button( icon="$viewer-show-icon" iconSize="32px" :active="isShowOnlySelectedElements" @click="toggleShowOnlySelectedObjects()" :title="$t('viewer.tools.showSelObjs')" titleOrientation='right')
      app-toolbar-button( icon="$viewer-hide-clear-icon" iconSize="32px" :active="isHideSelectedElements" @click="toggleHideSelectedObjects()" :title="$t('viewer.tools.hideSelObjs')" titleOrientation='right')
      app-toolbar-button( icon="$viewer-reset-clear-icon" iconSize="32px" @click="showAllObjects()" :title="$t('viewer.tools.clearSelObjs')" titleOrientation='right')

      //- Добавил для различных тестов, удаляйте если мешает
      //- app-toolbar-button( icon="$viewer-reset-clear-icon" iconSize="32" @click="testButton()" :title="'Тестовая кнопка'" titleOrientation='right')
</template>

<script>
import { PanelController } from "@/pages/panelController";
import { XeokitMediator } from "@/plugins/xeokit/XeokitMediator";
import ViewerTooltipButton from "@/components/project/common/ViewerTooltipButton.vue"
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { mapStores } from 'pinia';
import { useSearchElementsStore, useAttributeCheckingTableStore } from '@/pinia';
// import { ThreeMediator } from '@/plugins/threeJs/ThreeMediator'


export default {
  name: "ProjectInstruments",

  components: {
    ViewerTooltipButton
  },

  data() {
    return {
      isCtrlKeyPressed: false,
      IFCGridActive: false,
      lastPickedElement: null,
      lastSelectedCollisionsElementAB: null,
      lastCollisionSelectedElements: null,
      showPanel: false,
      menuActivator: {
        value: false
      },
      mouseDownTime: 0,
      openMenuTimeout: null
    }
  },

  async mounted() {
    await XeokitMediator.waitForViewer()
    this.setCtrlKeyPressedListeners()
  },

  beforeDestroy() {
    this.removeCtrlKeyPressedListeners()
  },

  computed: {
    ...mapStores(useSearchElementsStore, useAttributeCheckingTableStore), 
    ...mapGetters('collision/table', ['selectedCollisionsElementAB']),
    ...mapState('collision/search', ['collisionSelectedElements']),
    ...mapState('project', ['activeGlobalTab', 'project']),
    ...mapGetters('project', ['atModelPositioning']),

    rework_isVisibleSelectionToolbar () {
      return window.settings?.rework?.projectTools?.oldSelections ?? false
    },

    rework_isVisibleOldPlugins: () => window.settings?.rework?.projectTools?.oldPlugins ?? false,

    RegimeMeasurement() {
      return XeokitMediator.RegimeMeasurement
    },

    regimeMeasurementsModes() {
      return [
        { menuIcon:"$viewer-ruler-edge-menu-icon", icon:"$viewer-ruler-edge-icon", size:"32", title: "Ребро", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.EdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.EdgeMeasurement) },
        { menuIcon:"$viewer-ruler-edge-to-edge-menu-icon", icon:"$viewer-ruler-edge-to-edge-icon", size:"32", title: "Ребро к ребру", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.EdgeToEdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.EdgeToEdgeMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-edge-menu-icon", icon:"$viewer-ruler-point-to-edge-icon", size:"32", title: "Точка к ребру", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToEdgeMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToEdgeMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-point-menu-icon", icon:"$viewer-ruler-point-to-point-icon", size:"32", title: "Точка к точке", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToPointMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToPointMeasurement) },
        { menuIcon:"$viewer-ruler-point-to-face-menu-icon", icon:"$viewer-ruler-point-to-face-icon", size:"32", title: "Точка к плоскости", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.PointToFaceMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.PointToFaceMeasurement) },
        { menuIcon:"$viewer-ruler-face-to-face-menu-icon", icon:"$viewer-ruler-face-to-face-icon", size:"32", title: "Плоскость к плоскости", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.FaceToFaceMeasurement, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.FaceToFaceMeasurement) },
        { menuIcon:"$viewer-ruler-menu-icon", icon:"$viewer-ruler-icon", size:"32", title: "Рулетка", active: this.RegimeMeasurement.RegimeMeasurementModeClass == this.RegimeMeasurement.modeClasses.Ruler, action: () => this.toggleRegimeMeasurements(this.RegimeMeasurement.modeClasses.Ruler) },
      ]
    },

    isRegimeMeasurementActive() {
      return XeokitMediator.RegimeMeasurement.isRegimeMeasurementActive
    },

    isPickCoordinateViewerActive() {
      return XeokitMediator.PickCoordinateViewer.isPickCoordinateViewerActive
    },

    isDistanceMeasurementActive() {
      return XeokitMediator.DistanceMeasurement.isDistanceMeasurementActive
    },

    pickSectionPlanesActive() {
      return XeokitMediator.SectionPlanes.active
    },

    isSectionCubeActive() {
      return XeokitMediator.SectionCube.active
    },

    isSelectionFrameActive() {
      return XeokitMediator.SelectionFrame.isSelectionFrameActive
    },

    isShowOnlySelectedElements() {
      return XeokitMediator.ElementsSelection.isShowOnlySelectedElements
    },

    isHideSelectedElements() {
      return XeokitMediator.ElementsSelection.isHideSelectedElements
    },

    selectedElements() {
      return XeokitMediator.ElementsSelection.selectedElements
    },

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },
  },

  methods: {
    ...mapActions('axis/tree', ['fetchElementByGlobalId', 'deselectAllTreeEndpoints', 'deselectAll']),
    ...mapActions('collision/table', ['selectCollisions']),
    ...mapActions('collision/search', ['deselectCollision']),
    ...mapMutations('projectDict', ['setShowPanel']),
    ...mapMutations('smeta', ['setShowPanelHightLight']),
    ...mapMutations('worm', ['setShowPanelHightLightGroups']),

    toggleRegimeMeasurements(mode) {
      if (this.isRegimeMeasurementActive) {
        if (mode) XeokitMediator.RegimeMeasurement.setRegimeMeasurementMode(mode)
        else XeokitMediator.RegimeMeasurement.deactivate()
      }
      else {
        XeokitMediator.RegimeMeasurement.activate({ mode: mode })
      }
    },

    handleMouseDown(activator) {
      const currentTime = Date.now();
      const timeDiff = currentTime - this.mouseDownTime;
      this.mouseDownTime = currentTime;

      clearTimeout(this.openMenuTimeout);
      if (timeDiff < 300) {
        return;
      }

      activator.value = true;
      this.menuActivator = activator;
      this.openMenuTimeout = setTimeout(() => {
        if (activator.value) {
          this.openContextMenu();
        }
      }, 300); 
    },

    handleMouseUp(action) {
      clearTimeout(this.openMenuTimeout);
      const mouseUpTime = Date.now();
      const timeDiff = mouseUpTime - this.mouseDownTime;
      if (timeDiff >= 300) {
        action();
      }
      this.menuActivator.value = false;
      this.closeContextMenu();
    },

    toggleMeasurements () {
      if (!this.isDistanceMeasurementActive) {
        XeokitMediator.DistanceMeasurement.activate()
      }
      else {
        XeokitMediator.DistanceMeasurement.deactivate()
      }
    },

    openContextMenu () {
      this.showPanel = !this.showPanel
      if (this.showPanel) {
        document.addEventListener('click', this.closeContextMenu);
      } else {
        document.removeEventListener('click', this.closeContextMenu);
      }
    },
    closeContextMenu() {
      this.showPanel = false;
      document.removeEventListener('click', this.closeContextMenu);
    },
    
    setModelsFitToVisibleAndResize() {      
      if(!this.isCtrlKeyPressed) {
        PanelController.setPanelPadding(true)
        
        if(XeokitMediator.SectionCube.active) {
          let aabb = XeokitMediator.SectionCube.aabb
          XeokitMediator.CameraFlight.cameraFlyTo({aabb})
        } else {
          XeokitMediator.CameraFlight.cameraFlyToDefaultView()
          XeokitMediator.GlMemoryInfo.showGlInfo()
        }
      }
      // Перелет только к выделенным элементам (не тестилось с плоскостями сечений и кубом сечений)
      else {
        XeokitMediator.CameraFlight.cameraFlyToElements(this.selectedElements)
      }
    },

    togglePickCoordinateViewer() {
      XeokitMediator.PickCoordinateViewer.pickCoordinateViewerActivity(!this.isPickCoordinateViewerActive)
    },

    togglePickSectionPlanesActive() {
      XeokitMediator.SectionPlanes.active = !XeokitMediator.SectionPlanes.active
    },

    toggleSectionCubePlugin() {
      XeokitMediator.SectionCube.setActive(!XeokitMediator.SectionCube.active)
      
      if (XeokitMediator.SectionCube.active) {
        const selectedElements = XeokitMediator.viewer.scene.selectedObjectIds.concat(XeokitMediator.viewer.scene.highlightedObjectIds)
        if (!selectedElements.length) return
  
        let aabb = XeokitMediator.viewer.scene.getAABB(selectedElements).map((num, idx) => idx < 3 ? num - 0.1 : num + 0.1)
        XeokitMediator.CameraFlight.cameraFlyTo({ aabb })
  
        this.setSectionCubeAabb(aabb)
      }
    },

    setSectionCubeAabb(aabb) {
      XeokitMediator.SectionCube.setAabb(aabb)
    },

    toggleHighlightFrame(){
      if (this.isSelectionFrameActive) XeokitMediator.SelectionFrame.deactivate()
      else XeokitMediator.SelectionFrame.activate()
    },

    toggleShowOnlySelectedObjects () {
      if(!this.isShowOnlySelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements,
        ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if(!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        for(let element of this.lastSelectedCollisionsElementAB) {
          elementsSet.delete(element)
        }  
        elementsSet.delete(this.lastCollisionSelectedElements[0])
        elementsSet.delete(this.lastCollisionSelectedElements[1])
        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setShowOnlySelectedElements(!this.isShowOnlySelectedElements)
    },

    toggleHideSelectedObjects () {
      if(!this.isHideSelectedElements) {
        let elementsSet = new Set([this.pickedElement, ...this.selectedElements,
        ...this.collisionSelectedElements, ...this.selectedCollisionsElementAB])
        let selectElementsUuids = Array.from(elementsSet)

        if(!new Set(this.selectedElements).has(this.pickedElement)) { // Правка работы выделения с подсвеченными элементами
          this.lastPickedElement = this.pickedElement
        }

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastSelectedCollisionsElementAB = this.selectedCollisionsElementAB
        this.lastCollisionSelectedElements = this.collisionSelectedElements
      }
      else {
        let elementsSet = new Set([...this.selectedElements])
        elementsSet.delete(this.lastPickedElement)
        this.lastSelectedCollisionsElementAB?.forEach(element => {
          elementsSet.delete(element)
        })
        if(this.lastCollisionSelectedElements) {
          elementsSet.delete(this.lastCollisionSelectedElements[0])
          elementsSet.delete(this.lastCollisionSelectedElements[1])
        }
      
        let selectElementsUuids = Array.from(elementsSet)

        XeokitMediator.ElementsSelection.selectElements(selectElementsUuids)

        this.lastPickedElement = null
        this.lastSelectedCollisionsElementAB = null
        this.lastCollisionSelectedElements = null
      }
      XeokitMediator.ElementsSelection.setHideSelectedElements(!this.isHideSelectedElements)
    },

    testButton() {
      console.log(document.getElementById("drawing-img"))
      console.log(this.$refs['selected-page-body'])
      // ThreeMediator.SceneObjects.createFontText()
    },

    showAllObjects () {
      this.searchElementsStore.hideElementsSelection() // Убирает галочки в таблице поиска элементов
      XeokitMediator.ElementsSelection.dropAll()

      this.deselectAll()

      this.fetchElementByGlobalId(null)

      this.selectCollisions([])
      this.deselectCollision()

      this.attributeTableStore.clearSelection()

      XeokitMediator.clearColorizeModel()
      XeokitMediator.destroyUnnecessaryModels()

      if(this.activeGlobalTab === 'show') this.deselectAllTreeEndpoints()
      this.setShowPanel(false)
      this.setShowPanelHightLightGroups(false)
      this.setShowPanelHightLight(false)
    },

    setCtrlKeyPressedListeners(){
      document.addEventListener("keydown", this._ctrlKeyDown = (event) => {
        if (event.ctrlKey) this.isCtrlKeyPressed = true
      })

      document.addEventListener("keyup", this._ctrlKeyUp = (event) => {
        if (!event.ctrlKey) this.isCtrlKeyPressed = false
      })
    },

    removeCtrlKeyPressedListeners(){
      document.removeEventListener("keydown", this._ctrlKeyDown)
      document.removeEventListener("keyup", this._ctrlKeyUp)
    }
  },
}
</script>

<style lang="scss" scoped>
  .v-menu__content {
    background-color: transparent;
    box-shadow: none;
  }

  .viewer-ruler-menu {
    margin-top: 32px;
    // border-left: 1px solid #535353;
    background: white;
    background-color: white;
    width: 210px;
    // height: 297px;

    border: 1px solid rgba(219, 219, 219, 1);

    color: #303030;

    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .active-viewer-ruler-menu {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    background-color: #e6e6e6;
  }

  .full-width {
    width: 100%; 
    width: stretch;
    width: -moz-available; 
    width: -webkit-fill-available;
  }

</style>
