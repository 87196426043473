<template lang="pug">
  app-panel.pl-2( padding="0px" )
    app-floating-panels-section( style="height: calc(100% - 26px) !important" )
      app-floating-panel( :title="formRule ? formRule.title : $t('section.classification.menuClassification.ruleSelected' )" ref="ruleEditForm" bodyMinHeight="150" overflowBody="hidden" 
        :settings="floatingPanels('CLASSIFICATION_RULE_EDIT')")
        app-section( id="appSection" ).rule-section.base-scroll-body
          app-text(v-if="!formRule") {{$t('section.classification.menuClassification.ruleSelected')}}
          v-form(v-if="formRule" ref="ruleForm" style="height: auto;" @submit.prevent="editRule")
            v-container
              v-row
                v-col(cols=6).pl-0
                  app-text-field(v-model="formRule.title" :title="formRule.title" :label="$t('section.classification.menuClassification.title')" outlined :rules="[rules.required]" @change="ruleChanged" test-id="classification-rule-edit-title" :disabled="!hasClassificatorCreateRule")
                v-col(cols=6).pr-0
                  app-select(v-model="formRule.ruleType" :items="ruleTypes" item-text="title"  :label="$t('section.classification.menuClassification.ruleType')" dense outlined test-id="classification-rule-type" @change="ruleChanged" :disabled="!hasClassificatorCreateRule")

            template(v-if="formRule.ruleType.name == 'DEFAULT'")
              div.mb-5
                .d-flex.align-center.mb-2
                  app-text {{$t('section.classification.menuClassification.elementSelectionCondition')}}
                  v-btn.ml-1(v-if='haveFindRule && hasClassificatorCreateRule' color='#505254' height='16' width='16' x-small icon test-id="copy-selection-condition" @click='copyFindRule()')
                    app-tooltip( :title="$t('section.classification.clipboard.copy')" bottom )
                      v-icon(x-small) mdi-content-copy
                  v-btn(v-if="copiedFindRule && hasClassificatorCreateRule" height='16' width='16' :title='$t("section.classification.clipboard.paste")' color='#505254' x-small icon test-id="paste-condition" @click='pasteFindRule()')
                    v-icon(x-small) mdi-content-paste
                RuleElementSelectRule(v-model="formRule.findRule" :rule-uuid="formRule.uuid" :disabled="!hasClassificatorCreateRule" @change="ruleChanged()" @copy='copyFindRuleCondition' ref='rule-element-select-rule')

              div.mb-5
                .d-flex.mb-1
                  app-text {{$t('section.classification.menuClassification.unit')}} - {{ formRule.classificatorNode.unitValue}} {{formRule.classificatorNode.unitType ? formRule.classificatorNode.unitType.title : ''}}
                  v-btn(icon height="16" width="16" light @click="showClassificatorControlCurrent" test-id="edit-smeta-btn")
                    v-icon(x-small) mdi-cog-outline
                  v-btn(v-if='haveUnitExpression && hasClassificatorCreateRule' height="16" width="16" icon light test-id="copy-expression" @click="copyUnitExpression()")
                    v-icon(x-small) mdi-content-copy
                  v-btn(v-if='copiedUnitExpression && hasClassificatorCreateRule' height="16" width="16" icon light test-id="paste-expression" @click="pasteUnitExpression()")
                    v-icon(x-small) mdi-content-paste

                RuleUnitExpressionRule(ref="ruleUnitExpressionRuleRef" :rule-uuid="formRule.uuid" :disabled="!hasClassificatorCreateRule" v-model="formRule.unitExpression" @change="ruleChanged()" lockable  @copy='copyUnitExpressionCondition')

              div.mb-5
                .d-flex.mb-1
                  app-text {{$t("section.classification.menuClassification.titleAttribute")}}
                  v-btn.ml-1(v-if='haveTitleAttribute && hasClassificatorCreateRule' height="16" width="16" icon light test-id="copy-title-attrs" @click="copyTitleAttribute()")
                    v-icon(x-small) mdi-content-copy
                  v-btn(v-if='copiedTitleAttribute && hasClassificatorCreateRule' height="16" width="16" icon light test-id="paste-title-attrs" @click="pasteTitleAttribute()")
                    v-icon(x-small) mdi-content-paste

                RuleTitleAttributeRule(ref="ruleTitleAttributeRuleRef" :rule-uuid="formRule.uuid" :disabled="!hasClassificatorCreateRule" v-model="formRule.titleAttribute" @change="ruleChanged()" lockable @copy='copyTitleAttributeCondition')

              div.mb-3
                app-select(v-model="formRule.dataExportType" :items="dataExportTypes" item-text="title"  :label="$t('section.classification.menuClassification.unloadingType')" dense outlined @change="ruleChanged" :disabled="!hasClassificatorCreateRule")

              div.mb-3
                v-checkbox.mx-n1(v-model="formRule.showVolume" dense hide-details light @change="ruleChanged" :disabled="!hasClassificatorCreateRule")
                app-text.mx-n1 
                  div.upload-text-style {{$t("section.classification.menuClassification.unloadTheVolumeOfMaterials")}}
            template(v-else-if="formRule.ruleType.name == 'NONMODELINGELEMENT'")
              div
                app-text-field(v-model="formRule.elementName" :label="$t('section.classification.menuClassification.nameNonmodeledElement')" test-id="classification-nonmodel-el" outlined :rules="[rules.required]" @change="ruleChanged" :disabled="!hasClassificatorCreateRule")

              div.mb-5
                .d-flex.mb-1
                  app-text {{$t('section.classification.menuClassification.unit')}} - {{ formRule.classificatorNode.unitValue}} {{formRule.classificatorNode.unitType ? formRule.classificatorNode.unitType.title : ''}}
                  v-btn(icon height="16" width="16" light @click="showClassificatorControlCurrent" test-id="edit-smeta-btn")
                    v-icon(x-small) mdi-cog-outline
                
                app-text-field(v-model="formRule.elementValue" :label="$t('section.classification.menuClassification.numberNonmodeledElement')" test-id="classification-nonmodel-number" outlined :rules="[rules.required, rules.double]" @change="ruleChanged" :disabled="!hasClassificatorCreateRule")
            
            div.mb-3(v-if="grandSmetaItem")
              div
                app-text {{$t('section.classification.menuClassification.positionSmeta')}}
              div
                app-text code: {{ grandSmetaItem.code }}
              div
                app-text(v-if="grandSmetaItem.priceBase") priceBase: em - {{ grandSmetaItem.priceBase.em }}; mt - {{ grandSmetaItem.priceBase.mt }}; oz - {{ grandSmetaItem.priceBase.oz }}; pz - {{ grandSmetaItem.priceBase.pz }}; zm - {{ grandSmetaItem.priceBase.zm }}; value - {{ grandSmetaItem.priceBase.value }}; comment - {{ grandSmetaItem.priceBase.comment }}
              div
                app-text quantity: fx - {{ grandSmetaItem.quantity.fx }}; kUnit - {{ grandSmetaItem.quantity.kUnit }}; result - {{ grandSmetaItem.quantity.result }}

            
            div
              .d-flex.pb-2(v-if="formRule.ruleType.name == 'DEFAULT'")
                v-btn(@click="setCheckLogicMode(0)" color='accent' :disabled="!haveAnyConditions" text x-small light test-id="check-rule") <b>•</b> {{$t("section.worm.checkTheRule")}}
                v-btn(@click="setCheckLogicMode(1)" color='accent' :disabled="!haveAnyConditions || !pickedElement" text x-small light) 
                  span <b>•</b> {{$t("section.worm.checkTheElement")}}
                v-progress-circular(v-if="checkLogicValidLoading" size="25" indeterminate color="accent")
                v-icon.ml-auto( color='#757575' @click="showPanel" :disabled="!haveAnyConditions") {{ showPanelHightLight ? "mdi-eye" : "mdi-eye-off" }}
                
        app-dialog(v-model="dialog.editClass" :header="$t('section.classification.menuClassification.editClass')" width="700")
          app-section.editdclassialog
            node-edit-form.base-scroll-body(v-if="editClass" :projectClassificator="projectClassificatorSelected" window-mode @change="upodateNode")

      app-floating-panel( v-if="showPanelHightLight" style="margin-right: -8px" ref="hightLightGroups" :title="$t('section.classification.menuClassification.elementsInRule') + totalElements"
        bodyMinHeight="150" overflowBody="hidden" :settings="floatingPanels('CLASSIFICATION_RULE_ELEMENTS')")

        hight-light-groups( v-if="showPanelHightLight" ref="highlightGroupsRef" )

      .snack-bars
        div(v-for='snackbar in snackbars' :class="close ? 'close-snack-bar' : 'snack-bar'").copied-find-rule-size
          .d-flex.align-center(v-if='snackbar === "CopiedFindRule"')
            div
              div {{ $t('section.classification.clipboard.selectionConditions') }}
              div.pl-3(style="word-break: break-all; white-space: break-spaces;") {{ copiedFindRuleTitle }}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyFindRule" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close

          .d-flex.align-center(v-if='snackbar === "CopiedUnitExpression"')
            div
              div {{ $t('section.classification.clipboard.selectionConditions') }}
              div.pl-3(style="word-break: break-all; white-space: break-spaces;") {{ copiedUnitExpressionTitle }}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyUnitExpression" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close

          .d-flex.align-center(v-if='snackbar === "CopiedTitleAttribute"')
            div
              div {{ $t('section.classification.clipboard.selectionConditions') }}
              div.pl-3(style="word-break: break-all; white-space: break-spaces;") {{ copiedTitleAttributeTitle }}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyTitleAttributeTitle" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close

          .d-flex.align-center(v-if='snackbar === "FindRuleCondition"')
            div
              div {{ $t('section.classification.clipboard.selectionConditions') }}
              div.pl-3(v-for='condition in copiedFindRuleConditions' style="word-break: break-all; white-space: break-spaces;") {{ condition.title}}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyFindRuleCondition" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close

          .d-flex.align-center(v-if='snackbar === "UnitExpressionCondition"')
            div
              div {{ $t('section.classification.clipboard.expression') }}
              div.pl-3(v-for='expression in copiedUnitExpressionConditions') {{ expression.title }}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyUnitExpressionCondition" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close

          .d-flex.align-center(v-if='snackbar === "TitleAttributeCondition"')
            div
              div {{ $t('section.classification.clipboard.titleAttribute') }}
              div.pl-3(v-for='attribute in copiedTitleAttributeConditions' style="word-break: break-all; white-space: break-spaces;") {{ attribute.title }}
            v-btn.ml-auto.pa-5(color="red" icon @click="cancelCopyTitleAttributeCondition" :title="$t('section.classification.clipboard.cancel')")
              v-icon mdi-close
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { api } from "@/api"

// import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'

import SimpleCondition from '../../../components/logicGroup/Condition'

import RuleElementSelectRule from './RuleElementSelectRule'
import RuleUnitExpressionRule from './RuleUnitExpressionRule'
import RuleTitleAttributeRule from './RuleTitleAttributeRule'

import NodeEditForm from '../ClassificatorControll/NodeEditForm'
import Pagination from '@/components/project/panel/left/smeta/components/rule/Pagination'

import RuleConditionValid from '@/assets/model/condition/RuleConditionValid'
import ElementRuleConditionValid from '@/assets/model/condition/ElementRuleConditionValid'

import HightLightGroups from '../../../components/element/HightLightGroups'

import { XeokitMediator } from '@/plugins/xeokit/XeokitMediator'
import { useHighlightGroupsStore } from '@/pinia/smeta/highlightGroups.store'

const highlightGroupsStore = useHighlightGroupsStore()

export default {
  name:'rule-edit-form',

  components: {
    SimpleCondition,
    RuleElementSelectRule,
    RuleUnitExpressionRule,
    RuleTitleAttributeRule,
    NodeEditForm,
    Pagination,

    HightLightGroups
  },

  props: {
    
  },

  data() {
    return {
      formRule:null,
      close:false,      

      savingRule:false,

      dialog: {
        editClass: false
      },
      projectClassificatorSelected:null,

      rules: {
        required: (value) => !!value || this.$t("error.require"),
        double: (value) => (!!value && /^\d+(\.\d+)?$/.test(value)) || this.$t('section.worm.wholeOrFractionalNumber'),
      },

      snackbars: [],

      checkLogicValidLoading: false,
      checkElementValidLoading: false,
    }
  },

  mounted() {
    if (this.editRuleItem) this.makeForm(this.editRuleItem)

  },

  computed: {
    ...mapState('project', ['project', 'projectSettings']),
    ...mapState('smeta', ['projectClassificatorUuid', 'editRuleItem','editClass', 'grandSmeta', 'nodeClipboard','dataExportTypes', 'ruleTypes', 'showPanelHightLight']),
    ...mapGetters('smeta', ['getProjectClassificator','grandSmetaPositionByClassificator']),

    ...mapState('floatingPanels', ['floatingPanelsKeys']),
    ...mapGetters('floatingPanels', ['floatingPanels']),

    ...mapState('copyutil', [
      'copiedFindRule','copiedFindRuleTitle',
      'copiedUnitExpression','copiedUnitExpressionTitle',
      'copiedTitleAttribute','copiedTitleAttributeTitle',
      'copiedFindRuleConditions',
      'copiedUnitExpressionConditions',
      'copiedTitleAttributeConditions']),

    ...mapGetters("projectPermissions", ['hasClassificatorCreateRule', 'hasClassificatorDeleteRule']),

    pickedElement() {
      return XeokitMediator.ElementsSelection.pickedElement
    },

    projectClassificatorObject(){
      return this.getProjectClassificator(this.projectSettings.projectClassificator)
    },

    grandSmetaItem(){
      // return this.grandSmetaPositionByClassificator(this.formRule.classificatorNode.uuid)
      return this.editRuleItem.classificatorNode.grandSmetaPosition
    },

    haveFindRule() {
      let conds = (rule) => {
        let conditions = rule.condition
        rule.children?.forEach(child => {
          conditions = conditions.concat(conds(child))
        })
        return conditions
      }

      let c = []
      if (this.formRule.findRule) c = c.concat(conds(this.formRule.findRule))
      return c.length > 0
    },

    haveUnitExpression() {
      let conds = (rule) => {
        let conditions = rule.condition
        rule.children?.forEach(child => {
          conditions = conditions.concat(conds(child))
        })
        return conditions
      }

      let c = []
      if (this.formRule.unitExpression) c = c.concat(conds(this.formRule.unitExpression))
      return c.length > 0
    },

    haveTitleAttribute() {
      let conds = (rule) => {
        let conditions = rule.condition
        rule.children?.forEach(child => {
          conditions = conditions.concat(conds(child))
        })
        return conditions
      }

      let c = []
      if (this.formRule.titleAttribute) c = c.concat(conds(this.formRule.titleAttribute))
      return c.length > 0
    },

    haveAnyConditions() {
      let conds = (rule) => {
        let conditions = rule.condition
        rule.children?.forEach(child => {
          conditions = conditions.concat(conds(child))
        })
        return conditions
      }

      let c = []
      if (this.formRule.findRule) c = c.concat(conds(this.formRule.findRule))
      if (this.formRule.unitExpression) c = c.concat(conds(this.formRule.unitExpression))
      if (this.formRule.titleAttribute) c = c.concat(conds(this.formRule.titleAttribute))
      return c.length > 0
    },

    checkLogicMode: {
      get () {
        return this.$store.state.smeta.checkLogicMode
      },
      set (value) {
        this.$store.commit('smeta/setCheckLogicMode', value)
      }
    },

    totalElements: () => highlightGroupsStore.totalElements
  },

  watch: {
    editRuleItem(v) {
      this.checkLogicMode = null
      this.makeForm(v)
    },

    'dialog.editClass'(v) {
      if (!v) {
        this.projectClassificatorSelected = null
        this.setEditClass(null)
      }
    },

    pickedElement(v, ov){
      if (!v || (v && ov && v != ov)) this.checkLogicMode = null
    },

    checkLogicMode(){
      this.checkLogicValid()
    }
  },

  methods: {
    ...mapActions('smeta',['updateEditingRule']),
    ...mapMutations('smeta', ['setEditClass', 'setShowPanelHightLight']),
    ...mapActions('axis/tree', ['fetchElementByGlobalId']),

    ...mapMutations('copyutil', [
      'setCopiedFindRule','setCopiedUnitExpression','setCopiedTitleAttribute',
      'setCopiedFindRuleConditions','addFindRuleConditionToCopy',
      'setCopiedUnitExpressionConditions','addUnitExpressionConditionToCopy',
      'setCopiedTitleAttributeConditions','addTitleAttributeConditionToCopy']),

    sectionStyle(width) {
      if(width > 320) return "overflow-x: hidden"
      return "overflox-x: auto"
    },

    makeForm(v) {
      if (v) {
        if (this.$refs.ruleEditForm.openedPanel != true) {
          this.$refs.ruleEditForm.openBody()
        }
        this.formRule = Object.assign({},v)
        this.formRule.classificatorNode = {
              uuid:this.formRule.classificatorNode.uuid,
              unitValue: this.formRule.classificatorNode.unitValue,
              unitType: this.formRule.classificatorNode.unitType
            }
        delete this.formRule.parent
        
        if(this.showPanelHightLight){
          this.$nextTick().then(() => {
            this.loadElementsForHighLigths()
          })
        }
        else{
          highlightGroupsStore.resetState()
        }
        XeokitMediator.ElementsSelection.selectElements([])

      } else {

        // this.fetchElementByGlobalId(null);
        this.formRule = null

        highlightGroupsStore.resetState()

        XeokitMediator.ElementsSelection.selectElements([])
      }
    },

    ruleChanged() {
      this.editRule()
    },

    editRule() {
      if (this.formRule.dataExportType == null) this.formRule.dataExportType = this.dataExportTypes[0]
      let r = JSON.stringify(this.formRule)
      this.savingRule = true
      api.smeta.editNodeRule(this.formRule.classificatorNode.uuid, r, this.projectClassificatorUuid).then(async data => {

        this.checkLogicMode = null
        this.dropConditionValid()
        this.dropElementRuleConditionValid()
        
        await this.updateEditingRule(data)
        
        this.savingRule = false

        highlightGroupsStore.resetState()
        data
        // this.loadElementsForHighLigths()
      }).catch(error => {
        console.log(error)
      })
      this.setShowPanelHightLight(false)
    },


    showClassificatorControlCurrent() {
      this.projectClassificatorSelected = this.getProjectClassificator(this.projectClassificatorUuid)
      this.setEditClass(this.editRuleItem.classificatorNode)
      this.dialog.editClass = true
      //this.$refs.classificatorControll.showCurrent(this.formRule.classificatorNode.uuid)
    },

    upodateNode(el) {
      this.formRule.classificatorNode.unitValue = el.unitValue
      this.formRule.classificatorNode.unitType = el.unitType
    },

    loadElementsForHighLigths() {
      if(!this.showPanelHightLight) {
        this.setShowPanelHightLight(true)
      }
      this.$nextTick().then(() => {
        highlightGroupsStore.loadHighLigthsGroup(this.formRule.uuid).then(() => {
          this.$refs.highlightGroupsRef?.init()
          this.$refs.highlightGroupsRef?.changeKeepElements()
        })
        this.openPanelHightLightGroups()
      })
    },

    showPanel() {
      if(this.showPanelHightLight){
        this.setShowPanelHightLight(false)
      }
      else {
        this.setShowPanelHightLight(true)
        this.$nextTick().then(() => {
          this.loadElementsForHighLigths()
        })
      }
    },

    openPanelHightLightGroups() {
      const hightLightGroups = this.$refs['hightLightGroups']
      if(this.showPanelHightLight) {
        !hightLightGroups?.openedPanel && hightLightGroups?.openBody()
      }
      return this.$nextTick()
    },

    setCheckLogicMode(value) {
      this.$store.commit('smeta/setCheckLogicMode', value)
    },

    selectElements(arr) {
      XeokitMediator.ElementsSelection.selectElements([])
      if (arr && arr.length>0) XeokitMediator.ElementsSelection.selectElements(arr)
    },


    // копирование условий

    copyFindRule() {
      this.setCopiedFindRule(this.formRule.findRule)

      if(!this.snackbars.includes('CopiedFindRule')) {
        this.snackbars.push('CopiedFindRule')
      }

      setTimeout(()=> {this.close = true}, 3000);
      this.close = false
    },

    cancelCopyFindRule(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedFindRule'), 1)
      this.close = false
      this.setCopiedFindRule(null)
    },

    pasteFindRule(){
      this.formRule.findRule = JSON.parse(JSON.stringify(this.copiedFindRule))
      this.ruleChanged()
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedFindRule'), 1)
      this.close = false
    },

    copyUnitExpression(){
      this.setCopiedUnitExpression(this.formRule.unitExpression)
      if(!this.snackbars.includes('CopiedUnitExpression')) {
        this.snackbars.push('CopiedUnitExpression')
      }

      setTimeout(()=> {this.close = true}, 3000);
      this.close = false
    },

    cancelCopyUnitExpression(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedUnitExpression'), 1)
      this.close = false
      this.setCopiedUnitExpression(null)
    },

    pasteUnitExpression(){
      this.formRule.unitExpression = JSON.parse(JSON.stringify(this.copiedUnitExpression))
      this.ruleChanged()
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedUnitExpression'), 1)
      this.close = false
    },

    copyTitleAttribute(){
      this.setCopiedTitleAttribute(this.formRule.titleAttribute)
      if(!this.snackbars.includes('CopiedTitleAttribute')) {
        this.snackbars.push('CopiedTitleAttribute')
      }

      setTimeout(()=> {this.close = true}, 3000);
      this.close = false
    },

    cancelCopyTitleAttributeTitle(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedTitleAttribute'), 1)
      this.close = false
      this.setCopiedTitleAttribute(null)
    },

    pasteTitleAttribute(){
      this.formRule.titleAttribute = JSON.parse(JSON.stringify(this.copiedTitleAttribute))
      this.ruleChanged()
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'CopiedTitleAttribute'), 1)
      this.close = false
    },


    copyFindRuleCondition(findRuleCondition) {
      this.addFindRuleConditionToCopy(findRuleCondition)
      
      if (!this.snackbars.includes('FindRuleCondition')) {
        this.snackbars.push('FindRuleCondition')
      }
    },

    cancelCopyFindRuleCondition(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'FindRuleCondition'), 1)
      this.close = false
      this.setCopiedFindRuleConditions([])
    },

    copyUnitExpressionCondition(unitExpressionCondition) {
      this.addUnitExpressionConditionToCopy(unitExpressionCondition)
      
      if (!this.snackbars.includes('UnitExpressionCondition')) {
        this.snackbars.push('UnitExpressionCondition')
      }
    },

    cancelCopyUnitExpressionCondition(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'UnitExpressionCondition'), 1)
      this.close = false
      this.setCopiedUnitExpressionConditions([])
    },

    copyTitleAttributeCondition(titleAttributeConditionCondition) {
      this.addTitleAttributeConditionToCopy(titleAttributeConditionCondition)
      
      if (!this.snackbars.includes('TitleAttributeCondition')) {
        this.snackbars.push('TitleAttributeCondition')
      }
    },

    cancelCopyTitleAttributeCondition(){
      this.snackbars.splice(this.snackbars.findIndex(bar => bar === 'TitleAttributeCondition'), 1)
      this.close = false
      this.setCopiedTitleAttributeConditions([])
    },

    // проверка правил
    checkLogicValid(){
      if (this.checkLogicMode == 0) {
        this.dropConditionValid()
        this.checkLogicValidLoading = true
        RuleConditionValid.api().checkByClassificatorRule(this.project.uuid,this.formRule.uuid, this.editRuleItem.classificatorNode.uuid, this.projectClassificatorUuid).then(() => {
          this.checkLogicValidLoading = false
        })
      } else if (this.checkLogicMode == 1) {
        this.dropElementRuleConditionValid()
        this.checkLogicValidLoading = true
        ElementRuleConditionValid.api().check(this.pickedElement,this.formRule.uuid, this.editRuleItem.classificatorNode.uuid, this.projectClassificatorUuid).then(() => {
          this.checkLogicValidLoading = false
        })
      } else {
        this.dropConditionValid()
        this.dropElementRuleConditionValid()
      }
    },

    dropConditionValid(){
      if (this.formRule) RuleConditionValid.delete(this.formRule.uuid)
    },

    dropElementRuleConditionValid(){
      if (this.formRule) {
        if (this.pickedElement) ElementRuleConditionValid.delete([this.formRule.uuid, this.pickedElement])
        else {
          ElementRuleConditionValid.delete((elem) => elem.ruleUuid == this.formRule.uuid)
        }
      }
    },

    getCondReq(rule){
      if (!rule) return []
      let cond = []
      if (rule.condition && rule.condition.length > 0) cond = cond.concat(rule.condition)
      if (rule.children && rule.children.length > 0) {
        for (let c of rule.children) cond = cond.concat(this.getCondReq(c))
      }
      return cond
    }
  }
}
</script>

<style>
  .v-pagination {
    min-width: 100%;
  }

  .v-pagination > li > button {
    width: 35px;
    height: 30px;
    margin: 3px;
  }

  .v-pagination > li > .v-pagination__item {
    min-width: 25px;
  }

  .v-pagination > li > .v-pagination__more {
    width: 5px;
    margin: 2px;
  }
</style>

<style scoped>
.upload-text-style {
  margin-left: 30px;
  margin-top: -21px;  
}
.copied-find-rule-size {
  max-width: 80%;
}
.editdclassialog {
  height: calc(75vh - 235px);
  overflow-y: auto;
  overflow-x: hidden;
}
.smeta-btn-group {
  background: #c0c0c0;
  position: relative;
  border-radius: 10px;
  width:35px;
  height: 14px;
}
.smeta-btn-group .smeta-btn {
  position: absolute;
  font-style: italic;
  font-size: 14px;
  border-radius: 50%;
  cursor:pointer;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 15px;
}
.smeta-btn-group .smeta-btn.smeta-btn-a {
  left:-2px;
  top:-1px;
  padding-right: 2px;
}
.smeta-btn-group .smeta-btn.smeta-btn-f {
  right: 0;
  top:-1px
}
.smeta-btn-group .smeta-btn.smeta-btn-active {
  background: #262626;
}
.expansion-panels{
  z-index: 0;
}
 
.selected-rule{
  /* max-height:55%; */
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.rule-section{
  height: 100%;
  /* overflow-x: hidden; */
  overflow-y: auto;
}

.v-pagination ul {
  flex-wrap: wrap;
}

.abs-sec {
  position: relative;
  height: 100%;
  overflow: auto;
  scrollbar-width: thin;
}

nav > ul {
  flex-wrap: wrap !important;
}

.panel-classification{
  display: flex;
  flex-direction: column;
}

.panel-rule-edit{
  display: flex;
  flex-direction: column;
  height: inherit;
}

.snack-bars {
  position: fixed;
  height: 100vh;
  z-index: 1000;
  bottom: 0;
  display: flex;
  font-size: 0.875rem;
  justify-content: flex-end;
  left: 0;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  padding-bottom: 8px;
}

.snack-bar {
  /* min-width: 344px; */
  /* max-width: 400px; */
  min-width: 355px;
  padding: 16px;
  background: #4CAF50;
  pointer-events: auto;
  position: relative;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  white-space: normal;
}
</style>

<style lang="scss" scoped>
.abs-sec::-webkit-scrollbar {
  width: 8px;
}
.abs-sec::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
.selected-rule::-webkit-scrollbar {
  width: 8px;
}
.selected-rule::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}
.close-snack-bar{
  opacity: 0;
  min-width: 355px;
  padding: 16px;
  background: #4CAF50;
  pointer-events: auto;
  position: relative;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  white-space: normal;
  transition: opacity 0.6s;
}
.v-input--checkbox::v-deep {
  .v-input--selection-controls__ripple{
    top: calc(50% - 21px) !important;
    left: -7px !important
  }
}
</style>